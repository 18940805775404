<div id="main" *ngIf="isSupportedUserAgent">
  <div id="container-1" class="app-container" [attr.data-testid]="'primary-main-container'">
    <medis-sidebar
      id="sidenavLeft"
      name="navbar"
      class="navbar-medis-sidebar"
      [folded]="false"
      lockedOpen="true"
    >
      <div class="navbar_layout">
        <app-navbar></app-navbar>
      </div>
    </medis-sidebar>
    <div id="container-2" class="app-container" [attr.data-testid]="'secondary-main-container'">
      <app-toolbar></app-toolbar>
      <div
        id="container-3"
        class="app-container"
        [attr.data-testid]="'tertiary-main-container'"
        cdkScrollable
      >
        <div class="app-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <!-- check index.html, this is needed to have the footer behave like a footer-->
    <footer id="medis-footer" class="app-footer">
      <div class="copyright">
        <span class="copyright-icon">&copy;</span>{{ 'COPYRIGHT_PART_ONE' | translate }} {{ year }}
        {{ 'COPYRIGHT_PART_TWO' | translate }}
      </div>
    </footer>
  </div>
</div>

<medis-sidebar
  *ngIf="isSupportedUserAgent"
  name="rightPanel"
  position="right"
  class="right-panel"
  [folded]="true"
  [foldedWidth]="0"
  lockedOpen="true"
  [foldedAutoTriggerOnHover]="false"
>
  <right-panel></right-panel>
</medis-sidebar>

<div
  class="notification-popup medis-frame-4-azure"
  *ngIf="notifications.length && isSupportedUserAgent"
>
  <div class="medis-flex-container-between medis-pb-5">
    <span class="text-14-800-dark">
      <i class="far fa-comments"></i> {{ 'INTERNAL_COMM' | translate }} -
      {{ 'INQUIRY' | translate }} {{ currentNotification.refId }}
    </span>
    <span>
      <i
        [ngClass]="currentNotificationIndex > 0 ? '' : 'invisible'"
        class="fas fa-chevron-left medis-icon"
        (click)="currentNotification = notifications[currentNotificationIndex - 1]"
      ></i>
      <span class="text-14-800-dark medis-ml-10">
        {{ currentNotificationIndex + 1 }} / {{ notifications.length }}
      </span>
      <i
        [ngClass]="currentNotificationIndex < notifications.length - 1 ? '' : 'invisible'"
        class="fas fa-chevron-right medis-icon"
        (click)="currentNotification = notifications[currentNotificationIndex + 1]"
      ></i>
    </span>
  </div>
  <div class="medis-flex-grow content" [innerHtml]="currentNotification.text | sanitizeHtml"></div>
  <div *ngIf="currentNotification.attachments.length" class="attachments">
    <app-link-visualization
      *ngFor="let link of currentNotification.attachments"
      [link]="link"
      [allowRemove]="false"
      [style]="'compact'"
      [canOpenFile]="loggedInUserIsSysAdmin || !link.isDeleted"
    ></app-link-visualization>
  </div>
  <div class="medis-flex-container-between medis-pt-5">
    <span class="text-14-800-azure medis-clickable" (click)="dismiss(currentNotification)">{{
      'DISMISS' | translate
    }}</span>
    <span class="text-14-800-azure medis-clickable" (click)="navigateTo(currentNotification)">{{
      'GO_TO_ENQUIRY' | translate
    }}</span>
  </div>
</div>

<div class="browser-block" *ngIf="!isSupportedUserAgent">
  <div class="content">
    <p class="text-16-900-coral">
      Your browser is recognized as MicroSoft Edge version 44 or below, or Internet Explorer 11 or
      below, which is outdated and the system doesn't work well under it.
    </p>
    <p class="text-14-800-dark">
      For reference the latest MicroSoft Edge version is 86 and the older version is kept on your
      computer for support of legacy web sites, which doesn't work on up-to-date browsers.
    </p>
    <p class="text-16-900-coral">Please, switch to Google Chrome, in order to use the system.</p>
    <p class="text-13-500-dark">
      If you think this is mistake, please
      <a href="mailto:{{ supportEmail }}">contact support</a> with screenshot of this page.
    </p>
    <p class="text-13-500-dark">Browser User Agent: {{ userAgent }}</p>
  </div>
</div>
