export function removeHtml(htmlText: string): string {
  const element = document.createElement('div');
  element.innerHTML = htmlText;
  const text = element.innerText || element.textContent || '';
  return text.trim();
}

export function resetFocus(): void {
  const activeElement: HTMLElement = document.activeElement as HTMLElement;
  if (activeElement && activeElement.blur) {
    activeElement.blur();
  }
}
