<div class="d-flex flex-column align-items-center justify-content-center h-100 p-5">
  <i class="fas fa-lock text-100-azure m-4"></i>
  <p class="text-24-800-dark mb-3">{{ 'ACCESS_DENIED' | translate }}</p>
  <p class="text-16-400-dark mb-4">
    {{ 'YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE' | translate }}
  </p>
  <button
    *ngIf="!loggedInUser?.isSalesRep()"
    mat-button
    class="medis-button-main"
    routerLink="/inq/my-teams-open-inq"
  >
    {{ 'GO_TO_INBOX' | translate }}
  </button>
  <button
    *ngIf="loggedInUser?.isSalesRep()"
    mat-button
    class="medis-button-main"
    routerLink="/profile/profile-settings"
  >
    {{ 'MY_PROFILE' | translate }}
  </button>
</div>
