import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { parseAttr } from '../../data-model/data-model';
import { BInquiry } from '../interaction/interaction';
import { inquiryCustomFields, newInquiryCustomFields, updateInquiry } from './queries';
import { createGuid } from 'app/common/uuid-generator';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';

@Injectable({
  providedIn: 'root',
})
export class InquiryService {
  responseCustomFields: Subject<BInquiry>;
  querySubscription = undefined;

  constructor(private apollo: Apollo, private messageService: MessageHandlerService) {
    this.responseCustomFields = new Subject<BInquiry>();
  }

  performCustomFieldsQuery(pk: string) {
    this.querySubscription = this.apollo.query({
      query: inquiryCustomFields,
      variables: {
        id: pk,
        'X-NO-LOADING': true,
      },
    });
    this.setupSubscription('responseCustomFields');
  }

  performNewCustomFieldsQuery(pk: string) {
    this.querySubscription = this.apollo.query({
      query: newInquiryCustomFields,
      variables: {
        id: pk,
        'X-NO-LOADING': true,
      },
    });
    this.setupSubscription('responseCustomFields');
  }

  private setupSubscription(responseField: string): void {
    this.querySubscription.subscribe(
      (response) => {
        if (response) {
          var field = 'inquiry';
          if (!response.data['inquiry']) {
            // update of a new interaction object
            field = 'newInquiry';
          }
          let changedInquiry = parseAttr<BInquiry>(response.data, BInquiry, field);
          this[responseField].next(changedInquiry);
        } else {
          this[responseField].next(undefined);
          this.messageService.error('Empty response');
        }
      },
      (error) => {
        this.messageService.error('(InquiryService) ' + error);
      }
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class InquiryEditService {
  response: Subject<BInquiry> = new Subject<BInquiry>();

  constructor(private apollo: Apollo, private messageService: MessageHandlerService) {}

  performQuery(toMutate: any): void {
    const mutationData = Object.assign({}, toMutate);
    mutationData['clientMutationId'] = createGuid();
    this.apollo
      .mutate({
        mutation: updateInquiry,
        variables: { params: mutationData },
      })
      .subscribe(
        (response) => {
          if (response) {
            this.messageService.info('Inquiry updated correctly.');
            let inquiryResponseData = response.data['updateInquiry'];
            // update of an existing interaction
            var field = 'inquiry';
            if (!inquiryResponseData['inquiry']) {
              // update of a new interaction object
              field = 'newInquiry';
            }
            let changedInquiry = parseAttr<BInquiry>(inquiryResponseData, BInquiry, field);
            this.response.next(changedInquiry);
          } else {
            this.response.next(undefined);
            this.messageService.error('Inquiry Edit: empty response');
          }
        },
        (error) => {
          this.messageService.error('(Inquiry Edit) ' + error);
        }
      );
  }
}
