import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'app/auth/auth.service';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  BAttachment,
  BInteraction,
  HistoryEditService,
  SingleInteractionService,
} from 'app/modules/data-model/data-model.module';
import { BHistory, BInquiry } from 'app/modules/data-model/interaction/interaction';
import { AbstractUploadComponent } from '../upload-attachment/comon-upload-interface';
import { Action, InquiriesService } from 'app/modules/inquiries/services/inquiries.service';
import { Channels } from '@mi-tool/consts/const';
import { TinyMCE } from 'app/common/tiny-mce-options';

@Component({
  selector: 'app-detail-history-edit',
  templateUrl: './detail-history-edit.component.html',
  styleUrls: ['./detail-history-edit.component.scss'],
})
export class DetailHistoryEditComponent
  extends AbstractUploadComponent
  implements OnInit, OnDestroy
{
  interaction: BInteraction;
  inquiry: BInquiry;
  historyObj: BHistory;
  isReject = false;
  isNote = false;
  isFollowupResponse = false;
  isFollowupSentAvailable = false;
  isFollowupSent = false;
  channels = Channels.resolveIncomming();
  isSaving: boolean = false;
  attachments: BAttachment[];

  historyChanged: Subject<any>;
  editorId: string = 'detail-history-edit-editor';

  private subs = new Subscription();
  private isCallCenter = false;
  private currentLandingAction: { action: Action; selection: number[]; actionParams: object };

  constructor(
    private editHistoryService: HistoryEditService,
    private singleInteractionService: SingleInteractionService,
    private dialogRef: MatDialogRef<DetailHistoryEditComponent>,
    private authService: AuthService,
    private inqService: InquiriesService
  ) {
    super();
    this.attachments = [];
    this.historyObj = new BHistory({ text: '', category: 'other', channel: 'email' });
    this.historyChanged = new Subject<any>();
  }

  ngOnInit(): void {
    this.subs.add(
      this.editHistoryService.response.subscribe(() => {
        if (this.isSaving) {
          this.isSaving = false;
          if (['fu_response', 'fu_request'].includes(this.historyObj.category)) {
            // followup -> refresh interaction to retrieve new status
            this.singleInteractionService.performQuery(this.interaction.id);
          }
          this.historyChanged.next(true);
          this.dialogRef.close();
        }
      })
    );
    this.subs.add(
      this.authService.user.subscribe((user) => {
        this.isCallCenter = user && user.isCallCenter();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  save(): void {
    this.isSaving = true;
    if (this.currentLandingAction) {
      this.subs.add(
        this.setLandingAction().subscribe(() => {
          if (this.historyObj.id) {
            const data = {
              id: this.historyObj.id,
              text: this.historyObj.text,
              channel: this.historyObj.channel,
              category: this.historyObj.category,
            };
            data['linkAttachmentsId'] = JSON.stringify(this.attachments.map((a) => a.id));
            this.editHistoryService.saveHistoryWithData(data);
          } else {
            // New history
            if (this.isFollowupResponse) {
              this.historyObj.category = 'fu_response';
            } else if (this.isFollowupSent) {
              this.historyObj.category = 'fu_request';
            }
            const data = {
              interactionId: this.interaction.id,
              text: this.historyObj.text,
              channel: this.historyObj.channel,
              category: this.historyObj.category,
            };
            data['inquiryId'] = this.inquiry ? this.inquiry.id : undefined;
            data['linkAttachmentsId'] = JSON.stringify(this.attachments.map((a) => a.id));
            this.editHistoryService.successMessage = 'Note has been properly inserted in History.';
            this.editHistoryService.saveHistoryWithData(data);
          }
        })
      );
    } else {
      if (this.historyObj.id) {
        const data = {
          id: this.historyObj.id,
          text: this.historyObj.text,
          channel: this.historyObj.channel,
          category: this.historyObj.category,
        };
        data['linkAttachmentsId'] = JSON.stringify(this.attachments.map((a) => a.id));
        this.editHistoryService.saveHistoryWithData(data);
      } else {
        // New history
        if (this.isFollowupResponse) {
          this.historyObj.category = 'fu_response';
        } else if (this.isFollowupSent) {
          this.historyObj.category = 'fu_request';
        }
        if (this.isReject && !this.hasRequiredDataForRejectNote()) {
          this.isSaving = false;
          this.historyChanged.next(false);
          this.dialogRef.close();
          return;
        }
        const data = {
          interactionId: this.interaction.id,
          text: this.historyObj.text,
          channel: this.historyObj.channel,
          category: this.historyObj.category,
        };
        data['inquiryId'] = this.inquiry ? this.inquiry.id : undefined;
        data['linkAttachmentsId'] = JSON.stringify(this.attachments.map((a) => a.id));
        this.editHistoryService.successMessage = 'Note has been properly inserted in History.';
        this.editHistoryService.saveHistoryWithData(data);
      }
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  checkFuSelection(checkType: string): void {
    if (checkType == 'request' && !this.isFollowupSent) {
      this.isFollowupResponse = false;
    }
    if (checkType == 'response' && !this.isFollowupResponse) {
      this.isFollowupSent = false;
    }
  }

  isNotCallCenter(): boolean {
    return !this.isCallCenter;
  }

  onLandingActionPerformed(action: { action: Action; selection: number[]; actionParams: object }) {
    this.currentLandingAction = { ...action };
  }

  setLandingAction(): Observable<any> {
    return this.inqService.setLandingAction(
      this.currentLandingAction.action,
      this.currentLandingAction.selection,
      this.currentLandingAction.actionParams
    );
  }

  handleAttachmentDeletion(attachmentId: string): void {
    this.attachments = this.attachments.filter((attachment) => attachment.id !== attachmentId);
  }

  private hasRequiredDataForRejectNote(): boolean {
    const hasPlainText = TinyMCE.validateEditorPlainTextLength(this.editorId, 1);
    const hasImage = this.historyObj.text && this.historyObj.text.includes('<img');
    const hasAttachment = this.attachments.length > 0;
    return hasPlainText || hasImage || hasAttachment;
  }
}
