<ng-container *ngIf="historyObj">
  <h2 mat-dialog-title>
    {{ (!isReject && !isNote ? 'FOLLOW_UP_WITH_ENQUIRER' : 'INSERT_A_NOTE') | translate }}
    <app-reassign
      *ngIf="isNote"
      [label]="'ASSIGNED_TO'"
      [disabled]="interaction.isClosed() || (interaction.isInApproval() && isNotCallCenter())"
      [mode]="'users'"
      [selectedItems]="[interaction]"
      [selectedUser]="interaction.assignedTo"
      [isDraft]="interaction.isNew()"
      [emitExternallyLandingAction]="true"
      (landingActionPerform)="onLandingActionPerformed($event)"
    ></app-reassign>
    <i class="far fa-times-circle medis-icon-large" (click)="cancel()"></i>
  </h2>
  <mat-dialog-content>
    <div class="medis-flex-container-between medis-pb-10" *ngIf="!isReject && !isNote">
      <!-- <mat-checkbox *ngIf="isFollowupSentAvailable"
                          matTooltip="{{'CANT_CREATE_A_FOLLOW_UP_REQUEST' | translate}}"
                          class="medis-mr-10"
                          [matTooltipDisabled]="interaction.inquirerIsValid()"
                          [disabled]= "!interaction.inquirerIsValid()"
                          [(ngModel)]="isFollowupSent"
                          (click)="checkFuSelection('request')">
                {{'THIS_IS_A_FOLLOW_UP_' | translate}}
            </mat-checkbox> -->
      <!-- <mat-checkbox matTooltip="{{'CANT_CREATE_A_FOLLOW_UP_' | translate}}"
                          [matTooltipDisabled]="interaction.inquirerIsValid()"
                          class="medis-mr-10"
                          [disabled]= "!interaction.inquirerIsValid()"
                          [(ngModel)]="isFollowupResponse"
                          (click)="checkFuSelection('response')">
                {{'THIS_IS_FOLLOW_A_UP_RESPONSE' | translate}}
            </mat-checkbox> -->
      <button
        mat-button
        matTooltip="{{ 'CANT_CREATE_A_FOLLOW_UP_' | translate }}"
        [matTooltipDisabled]="interaction.inquirerIsValid()"
        [ngClass]="isFollowupResponse ? 'medis-button-main' : 'medis-button-basic'"
        [disabled]="!interaction.inquirerIsValid()"
      >
        <span class="assembled-inbox-in-icon">
          <i class="fas fa-arrow-down"></i>
          <i class="fas fa-inbox"></i>
        </span>
        {{ 'FOLLOW_UP_RECEIVED' | translate }}
      </button>
      <mat-form-field class="form-field-wrapper">
        <mat-label>{{ 'INC_CHANNEL' | translate }}</mat-label>
        <mat-select
          [(ngModel)]="historyObj.channel"
          [disabled]="!isFollowupSent && !isFollowupResponse"
        >
          <mat-option *ngFor="let channel of channels" [value]="channel.key">{{
            channel.translationKey | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="editor-container">
      <editor
        [(ngModel)]="historyObj.text"
        [inline]="false"
        [init]="tinymceOptions"
        [id]="editorId"
      ></editor>
      <app-upload-attachment
        #uploadComponent
        [activeEditor]="activeEditor"
        (attachmentUploaded)="attachments.push($event)"
      ></app-upload-attachment>
    </div>
    <div *ngFor="let link of attachments" class="attachment-container">
      <app-link-visualization
        [link]="link"
        [allowRemove]="true"
        (deleteAttachment)="isReject && handleAttachmentDeletion($event)"
      ></app-link-visualization>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-button
      class="medis-button-main"
      (click)="save()"
      [disabled]="isReject && !historyObj.text"
    >
      {{ (isReject ? 'REJECT' : 'SAVE') | translate }}
    </button>
    <button mat-button class="medis-button-reject" (click)="cancel()">
      {{ 'CANCEL' | translate }}
    </button>
  </mat-dialog-actions>
</ng-container>
