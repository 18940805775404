import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { BUser } from 'app/modules/data-model/data-model.module';
import {
  Config,
  newFilterConfig,
  newInquirerIdConfig,
  newTerritoryConfig,
} from 'app/common/common/search-select/search-select.config';
import {
  AdvancedSearch,
  allExports,
  FConfigs,
  filterConfigDefault,
  filterConfigLabels,
  filterConfigMapping,
  filterConfigMultiples,
  filterConfigTranslate,
  filterConfigTypes,
  FilterModalConfig,
  FilterSelection,
  filterSelection,
  multipleFilterSelectionCount,
  Query,
  selectAll,
  Type,
} from './configs/filter-configs';
import { Subscription } from 'rxjs';
import { InquiriesService } from '../../services/inquiries.service';
import { InquirersService } from '../../../data-model/inquirer/inquirers.service';
import { InquiriesService as InquiriesDataService } from '../../../data-model/inquiry/inquiries.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import {
  FilterSaveContent,
  FilterShareContent,
} from './inquiries-filter-modal/inquiries-filter-modal';
import { InquirySearch } from '../../../data-model/inquiry/inquiry';
import { Helpers } from '../../../../../@mi-tool/utils/helpers';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { DialogModesEnum } from '../../../../common/common/enums/dialog.enum';
import {
  EXPORT_FILTERS_TO_IGNORE,
  FIELDS_TO_IGNORE,
} from '../../../../common/common/constants/search.constants';
import { SearchOrigin } from '../../../../common/common/enums/search.enum';
import { SearchService } from '../../services/search.service';
import { ErrorCode } from '../../../../../@mi-tool/enums/error.enum';
import { GenericDialogService } from 'app/common/common/generic-dialog/generic-dialog.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InquiriesStateService } from '../../services/inquiries-state.service';
import { DateRange } from 'app/common/types';

@Component({
  selector: 'inquiries-filter',
  templateUrl: './inquiries-filter.component.html',
  styleUrls: ['./inquiries-filter.component.scss'],
})
export class InquiriesFilterComponent implements OnInit, OnDestroy {
  edit: boolean = false;
  view: boolean = false;
  viewSharedSearch: boolean = false;
  saveAndShareSearch: boolean = false;
  filterConfig: Config = newFilterConfig(
    _.cloneDeep(
      filterSelection.sort((filterA, filterB) =>
        this.translateService.instant(filterA.name) < this.translateService.instant(filterB.name)
          ? -1
          : 1
      )
    )
  );
  exportSelections: Array<FilterSelection>;
  defaultSort: Array<FilterSelection>;
  filterConfigMapping = _.cloneDeep(filterConfigMapping);
  filterConfigDefault = _.cloneDeep(filterConfigDefault);
  filterConfigLabels = filterConfigLabels;
  filterConfigTypes = filterConfigTypes;
  filterConfigTranslate = filterConfigTranslate;
  filterConfigMultiples = filterConfigMultiples;
  types = Type;
  fConfigs = FConfigs;
  private subs: Subscription = new Subscription();
  advancedSearch: AdvancedSearch;
  joinList = ['OPTION_AND', 'OPTION_OR', 'OPTION_NOT'];
  exportedSelection: { [name: string]: boolean } = {};
  inquiryId;
  inquiryFavorite;
  inquiryName;
  selectedJoinError;
  users: BUser[];

  constructor(
    private _inqService: InquiriesService,
    private inquirersService: InquirersService,
    private inquiriesDataService: InquiriesDataService,
    private translateService: TranslateService,
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<InquiriesFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InquirySearch,
    private helpers: Helpers,
    private router: Router,
    private searchService: SearchService,
    private messageService: MessageHandlerService,
    private genericDialogService: GenericDialogService,
    private inquiriesStateService: InquiriesStateService
  ) {
    this.advancedSearch = new AdvancedSearch();
    this.exportSelections = [
      selectAll,
      ..._.cloneDeep(filterSelection).sort((a, b) =>
        this.translateService.instant(a.exportName || a.name) <
        this.translateService.instant(b.exportName || b.name)
          ? -1
          : 1
      ),
    ];
    this.exportSelections.forEach((exportS) => {
      exportS.checked = false;
    });
    this.defaultSort = _.cloneDeep(this.exportSelections);
  }

  ngOnInit(): void {
    this.assignValues();
    this.loadData();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this._inqService.resetAdvancedFilters$$.next(false);
  }

  private assignValues(): void {
    this.subs.add(
      this.inquiriesStateService.allMultipleDropdownsDataForInboxAndAdvancedSearch$.subscribe(
        (data) => {
          if (Object.keys(data).length === multipleFilterSelectionCount) {
            Object.keys(data).forEach((dropdownSelector) => {
              this.filterConfigMapping[dropdownSelector] = data[dropdownSelector];
            });
            this.users = data[FConfigs.INSERTED_BY].rawItems;
            this.updateCombinedQuery();
          }
        }
      )
    );
  }

  filterInquirers(name: string): void {
    this.subs.add(
      this.inquirersService.get(name).subscribe((inquirers) => {
        this.filterConfigMapping[FConfigs.INQUIRER] = newInquirerIdConfig(
          _.cloneDeep(inquirers),
          ''
        );
        this.updateCombinedQuery();
      })
    );
  }

  filterTerritory(territory: string): void {
    this.subs.add(
      this.inquirersService.getTerritory(territory).subscribe((inquirers) => {
        this.filterConfigMapping[FConfigs.TERRITORY] = newTerritoryConfig(inquirers);
        this.updateCombinedQuery();
      })
    );
  }

  private setData(data): void {
    this.exportedSelection = data.exportedColumns || {};
    this.advancedSearch.query = (_.cloneDeep(data.advancedSearch) || []).filter((query) => {
      // We do not need to show everything in the advanced search
      return FIELDS_TO_IGNORE.indexOf(query.field) === -1 && query.displayTerm;
    });
    this.inquiryId = data.id;
    this.inquiryName = data.name;
    this.inquiryFavorite = data.favourite || false;

    this.exportSelections.forEach((filter) => {
      if (filter.selector in this.exportedSelection) {
        filter.checked = true;
      }
    });
    this.updateCombinedQuery();
  }

  private loadData(): void {
    const local = localStorage.getItem('advancedSearch');
    const localData = local && JSON.parse(local);
    if (this.data && this.data.id) {
      this.view = this.data['view'];
      this.edit = !this.data['view'];
      this.viewSharedSearch = this.data['viewSharedSearch'] || false;
      this.setData(this.data);
    } else if (localData) {
      this.setData(localData);
    }
  }

  updateCombinedQuery(): void {
    const queries = this.advancedSearch.query || [];
    let combinedQuery = '';

    queries.forEach((query, index) => {
      let selector = query.field;
      const value = query.displayTerm ? query.displayTerm : '';
      let label = filterConfigLabels[selector];
      if (label) {
        if (index === 0) {
          combinedQuery += `<b>${this.translateService.instant(label)}:</b> ${value} `;
        } else {
          combinedQuery += ` ${
            query.join || this.translateService.instant('OPTION_AND')
          }  <b>${this.translateService.instant(label)}:</b> ${value}`;
        }
      }
    });
    this.advancedSearch.combinedQuery = combinedQuery;
    this.advancedSearch.selectedJoin = null;
  }

  updateQuery(value: FilterSelection, searchTerm: string | any[], displayTerm: string): void {
    const newQ: Query = { field: value.selector };
    if (this.advancedSearch.value && searchTerm) {
      newQ['value'] = searchTerm;
      newQ['displayTerm'] = displayTerm;
    } else if (searchTerm == 'yes') {
      const yesTrans = this.translateService.instant('YES');
      newQ['value'] = yesTrans;
      newQ['displayTerm'] = yesTrans;
    }
    newQ.origin = SearchOrigin.AdvancedSearch;

    if (this.advancedSearch.selectedJoin)
      newQ['join'] = this.translateService.instant(this.advancedSearch.selectedJoin).toUpperCase();
    this.advancedSearch.query.push(newQ);
  }

  deleteQuery(index: number): void {
    this.advancedSearch.query.splice(index, 1);
    const firstElement = this.advancedSearch.query.length ? this.advancedSearch.query[0] : null;
    if (firstElement) {
      firstElement['join'] = this.translateService
        .instant(this.advancedSearch.selectedJoin ? this.advancedSearch.selectedJoin : 'OPTION_AND')
        .toUpperCase();
      this.advancedSearch.query[0] = firstElement;
    }
    this.updateCombinedQuery();
    this.advancedSearch.selectedValue = null;
    this.advancedSearch.value = null;
    this.advancedSearch.selectedJoin = null;
  }

  selectedJoin(): void {
    let queryHasDisplayTerm = true;
    this.advancedSearch.query.forEach((query, index) => {
      if (query.displayTerm == undefined || query.displayTerm == '') {
        this.deleteQuery(index);
        this.advancedSearch.selectedJoin = '';
        queryHasDisplayTerm = false;
      }
    });
    if (queryHasDisplayTerm) {
      const query = this.advancedSearch.query;
      const queryLength = query.length;
      const joinVal = this.translateService.instant(this.advancedSearch.selectedJoin).toUpperCase();
      if (queryLength > 0) {
        const lastQuery = query[queryLength - 1];
        if (lastQuery.field && lastQuery.value) {
          this.advancedSearch.value = '';
          this.advancedSearch.selectedValue = null;
        }
        if (queryLength > 1) {
          if (lastQuery.field && lastQuery.value) {
            this.advancedSearch.query.push({ join: joinVal });
          } else {
            this.advancedSearch.query[queryLength - 1].join = joinVal;
          }
        } else {
          this.advancedSearch.query.push({ join: joinVal });
        }
      }
    }
  }

  setAdvancedSelector(): void {
    const value = this.advancedSearch.selectedValue;
    if (value && value.selector) {
      const query = this.advancedSearch.query;
      const queryLength = query.length;
      if (queryLength > 0) {
        const lastQuery = query[queryLength - 1];
        if (lastQuery['value'] && lastQuery['field']) {
          const joinVal = this.translateService.instant('OPTION_AND').toUpperCase();
          this.advancedSearch.query.push({ join: joinVal });
          if (filterConfigDefault[value.selector] !== 'yes') {
            filterConfigDefault[value.selector] = '';
          }
          if (this.advancedSearch.value !== 'yes') {
            this.advancedSearch.value = '';
          }
        }
      }
    }
    this.selectedFilter();
  }

  selectedFilter(searchTerm?: any | string, displayTerm?: string): void {
    const value = this.advancedSearch.selectedValue;
    if (value && value.selector) {
      const query = this.advancedSearch.query;
      const queryLength = query.length;
      if (filterConfigDefault[value.selector]) {
        if (!searchTerm) {
          searchTerm = filterConfigDefault[value.selector];
        }
        if (!displayTerm) {
          displayTerm = filterConfigDefault[value.selector];
        }
      }
      if (queryLength > 0) {
        const lastQuery = query[queryLength - 1];

        lastQuery['field'] = value.selector;
        if (this.advancedSearch.value && searchTerm) {
          lastQuery['value'] = searchTerm;
          lastQuery['displayTerm'] = displayTerm;
        } else if (searchTerm === 'yes') {
          const yesTrans = this.translateService.instant('YES');
          lastQuery['value'] = yesTrans;
          lastQuery['displayTerm'] = yesTrans;
        } else if (!searchTerm) {
          lastQuery['value'] = '';
          lastQuery['displayTerm'] = '';
        }
        if (this.advancedSearch.selectedJoin)
          lastQuery['join'] = this.translateService
            .instant(this.advancedSearch.selectedJoin)
            .toUpperCase();
        this.advancedSearch.query[queryLength - 1] = lastQuery;
      } else {
        this.updateQuery(value, searchTerm, displayTerm);
      }

      this.updateCombinedQuery();
    }
  }

  searchTermText(value: string): void {
    this.selectedFilter(value, value);
  }

  searchTermDropdown(config: Config): void {
    const result = this.helpers.transformFilter(this.advancedSearch.value, Type.DROPDOWN, config);
    const val = result.value;
    const term = result.displayTerm;

    this.selectedFilter(val, term);
  }

  searchTermDate(event: DateRange): void {
    const fromDate = event.from ? event.from.toISOString() : undefined;
    const toDate = event.to ? event.to.toISOString() : fromDate;
    const dateRange = { from: fromDate, to: toDate };
    const { displayTerm } = this.helpers.transformFilter(dateRange, Type.DATE, null);
    this.selectedFilter(dateRange, displayTerm);
  }

  setExported(selection: FilterSelection): void {
    selection.checked = !selection.checked;

    if (selection.checked) {
      if (selection.selector === FConfigs.SELECT_ALL) {
        this.exportedSelection = allExports;
        this.exportedSelection[FConfigs.SELECT_ALL] = true;
        this.exportSelections.forEach((selection) => {
          selection.checked = true;
        });
      } else {
        this.exportedSelection[selection.selector] = selection.checked;
        const allSelected = this.exportSelections.every((selection) => {
          if (selection.selector === FConfigs.SELECT_ALL) {
            return true;
          }
          return !!this.exportedSelection[selection.selector];
        });

        this.exportSelections.forEach((selection) => {
          if (selection.selector === FConfigs.SELECT_ALL && allSelected) {
            selection.checked = true;
          }
        });
      }
    } else {
      if (selection.selector in this.exportedSelection) {
        if (selection.selector === FConfigs.SELECT_ALL) {
          this.exportedSelection = {};
          this.exportSelections.forEach((selection) => {
            selection.checked = false;
          });
        } else {
          this.exportSelections.forEach((selection) => {
            if (selection.selector === FConfigs.SELECT_ALL) {
              selection.checked = false;
            }
          });
          delete this.exportedSelection[selection.selector];
        }
      }
    }
  }

  getFilterParameter(name?: string, stringify = true, save = false): InquirySearch {
    return {
      exportedColumns: stringify ? JSON.stringify(this.exportedSelection) : this.exportedSelection,
      name: name || this.inquiryName || '',
      id: this.inquiryId,
      favourite: this.inquiryFavorite,
      advancedSearch: stringify
        ? JSON.stringify(this.advancedSearch.query)
        : this.advancedSearch.query,
    } as InquirySearch;
  }

  exportColumns(): void {
    const bodyData = _.cloneDeep(this.getFilterParam(false));
    bodyData.exportedColumns = this._inqService.adaptExportColumnsForExport(this.exportedSelection);
    bodyData.advancedSearch = _.cloneDeep(
      this.advancedSearch.query.filter(
        (filter) => EXPORT_FILTERS_TO_IGNORE.indexOf(filter.field) === -1
      )
    );

    bodyData.advancedSearch = this._inqService.getAdaptedDateFilters(bodyData.advancedSearch);

    this.inquiriesDataService.exportInquiry(bodyData).subscribe({
      next: (response: Blob) => {
        if (response) {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(response);
          link.download = 'inquiries-' + new Date().toISOString().slice(0, 10) + '.xlsx';
          link.click();
        } else {
          this.messageService.info('NOTHING_EXPORT');
        }
      },
      error: (error) => {
        if (error.statusText === ErrorCode.BAD_REQUEST) {
          this.genericDialogService.showMaxDownloadsError();
        } else {
          this.messageService.httpError('Excel Export', error);
        }
      },
    });
  }

  getFilterParam(stringify: boolean, name?: string): InquirySearch {
    return this.getFilterParameter(name || (this.data && this.data.name) || '', stringify);
  }

  processError(err: any) {
    let message = '';
    const detail = err.detail;
    const error = err.error;
    if (detail) {
      message += detail;
    } else if (error) {
      // @ts-ignore
      Object.values(error).forEach((error: any[]) => {
        error.forEach((e) => {
          message += e + '\n';
        });
      });
    } else {
      message += 'An error occured';
    }
    this.messageService.error(message, 'red-snackbar');
    return null;
  }

  onSave(name?: string): void {
    const parameters = this.getFilterParameter(name);
    const body = InquirySearch.toRest(parameters);
    if (this.edit) {
      parameters['id'] = this.inquiryId;
      this.inquiriesDataService
        .updateSearch(body)
        .pipe(catchError((err) => this.processError(err)))
        .subscribe((res) => {
          if (res && res['id']) {
            this.dialogRef.close(DialogModesEnum.Edit);
          }
        });
    } else {
      this.inquiriesDataService
        .postSearch(body)
        .pipe(catchError((err) => this.processError(err)))
        .subscribe((res) => {
          if (res && res['id'] && !this.saveAndShareSearch) {
            this.refreshSavedSearches();
            this.dialogRef.close(FilterModalConfig.CLOSE);
          } else if (res && res['id'] && this.saveAndShareSearch) {
            this.refreshSavedSearches();
            this.saveAndShareSearch = false;
            this.openShare(res['id']);
          }
        });
    }
  }

  isValidExport(): boolean {
    return Object.keys(this.exportedSelection).some((exp) => !!this.exportedSelection[exp]);
  }

  isValidSearch(): boolean {
    const query = this.advancedSearch.query || [];
    return (
      query.length > 0 &&
      query.every((query) =>
        typeof query.value == 'number' ? Number.isInteger(query.value) : !_.isEmpty(query.value)
      )
    );
  }

  isValidSave(): boolean {
    return this.isValidSearch() || this.isValidExport();
  }

  shouldReset(): boolean {
    const query = this.advancedSearch.query || [];
    return query.length > 0 || this.isValidExport();
  }

  onSubmitSearch(): void {
    if (this.isValidSearch()) {
      const filters = this.getFilterParameter(this.inquiryName, false, true);
      localStorage.setItem('advancedSearch', JSON.stringify(filters));

      const filtersToSearch = this.getFilterParam(false);

      filtersToSearch.advancedSearch = this.advancedSearch.query.filter(
        (filter) => FIELDS_TO_IGNORE.indexOf(filter.field) === -1
      );

      this.searchFilter(filtersToSearch);
    } else {
      this.messageService.info('NOTHING_SEARCH');
    }
  }

  searchFilter(filters): void {
    if (filters) {
      if (!this.viewSharedSearch) {
        this._inqService.inquirySearch$$.next(InquirySearch.toRest(filters));
      }

      if (this.matDialog) {
        this.matDialog.closeAll();
      }

      if (!this.router.url.includes('all-inq-for-all-team')) {
        this.router.navigateByUrl(InquiriesService.LEFT_MENU_URLS.ALL_INQ_ALL_TEAMS);
      }
    }
  }

  openSave(): void {
    if (this.isValidSave()) {
      const modalRef = this.matDialog.open(FilterSaveContent);
      modalRef.componentInstance.edit = this.edit;
      modalRef.componentInstance.users = this.users;
      modalRef.componentInstance.allSavedSearches =
        (this.data && this.data['allSavedSearches']) || [];
      if (!this.viewSharedSearch) {
        modalRef.componentInstance.saveValue = this.data && this.data.name;
      }
      modalRef.afterClosed().subscribe((result) => {
        if (result && typeof result !== 'string') {
          if ('type' in result) {
            if (result['type'] === FilterModalConfig.SAVE) {
              this.onSave(result['value']);
            } else if (result['type'] == FilterModalConfig.SAVEANDSHARE) {
              this.saveAndShareSearch = true;
              this.onSave(result['value']);
            }
          }
        }
      });
    } else {
      this.messageService.info('NOTHING_SAVE');
    }
  }

  openShare(searchId?: number): void {
    if (this.isValidSave()) {
      const modalRef = this.matDialog.open(FilterShareContent);
      modalRef.componentInstance.users = this.users.filter((user) => user.isInqueryProcessing());
      modalRef.componentInstance.searchId = this.inquiryId || searchId;
    } else {
      this.messageService.info('NOTHING_SHARE');
    }
  }

  clearSearch(): void {
    this.exportedSelection = {};
    this.exportSelections = _.cloneDeep(this.defaultSort);
    this.exportSelections.forEach((exportS) => {
      exportS.checked = false;
    });

    this.advancedSearch = new AdvancedSearch();
    this.data = null;
    this.inquiryName = null;
    this.inquiryId = null;
    localStorage.removeItem('advancedSearch');
    this._inqService.apiSearch = false;
    this._inqService.resetAdvancedFilters$$.next(true);
  }

  refreshSavedSearches(): void {
    this.searchService.allSavedSearches$.next(true);
  }

  closeModal(): void {
    if (this.viewSharedSearch) {
      this.dialogRef.close(FilterModalConfig.CLOSE);
    } else {
      this.dialogRef.close();
    }
  }
}
