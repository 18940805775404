import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { InquiriesService } from 'app/modules/inquiries/services/inquiries.service';
import { DetailCloseComponent } from 'app/modules/inquiry-detail/detail-navigation/detail-close/detail-close.component';
import { InquiriesService as InquiryService } from '../../../../../modules/data-model/inquiry/inquiries.service';
import { InquirySearch } from 'app/modules/data-model/inquiry/inquiry';
import { NavigationEnd, Router } from '@angular/router';
import {
  FConfigs,
  FilterSelection,
  filterSelection,
  Type,
} from 'app/modules/inquiries/inquiries-list/inquiries-filter/configs/filter-configs';
import { filter } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { EXPORT_FILTERS_TO_IGNORE } from 'app/common/common/constants/search.constants';
import { ErrorCode, ErrorTitles } from '@mi-tool/enums/error.enum';
import { GenericDialogService } from 'app/common/common/generic-dialog/generic-dialog.service';
import { EResetFilters } from '@mi-tool/enums/basic.enum';
import { InquiriesStateService } from 'app/modules/inquiries/services/inquiries-state.service';
import { MERGE_DIALOG_CONFIG_OPTIONS } from '@mi-tool/consts';

@Component({
  selector: 'app-inquiries-toolbar',
  templateUrl: './inquiries-toolbar.component.html',
  styleUrls: ['./inquiries-toolbar.component.scss'],
})
export class InquiriesToolbarComponent implements OnInit {
  @Input() exportEnabled? = true;

  inquiryStatuses: string[];
  filters: any = {};
  selectedItems: any[] = [];
  priorities: string[] = ['normal', 'high', 'critical'];
  loadingReport = false;

  translatedFilters = [];
  allFiltersForReset: FilterSelection[] = [
    ...filterSelection,
    { id: 50, name: 'ASSIGNED_TO', selector: FConfigs.ASSIGNED_TO, type: Type.NUMBER },
    { id: 51, name: 'PRIORITY', selector: FConfigs.PRIORITY, type: Type.TEXT },
    { id: 52, name: 'ID', selector: FConfigs.ID, type: Type.NUMBER },
    { id: 53, name: 'Search_inquiries', selector: FConfigs.TEXT, type: Type.TEXT },
  ];
  statusDropdownTooltipText: string = null;
  interactionEditingDropdownsTooltipText: string = null;

  private backendStatuses: string[] = [];

  constructor(
    private inqService: InquiriesService,
    private modalService: MatDialog,
    private messageService: MessageHandlerService,
    private inquiryService: InquiryService,
    private router: Router,
    private genericDialogService: GenericDialogService,
    private inquiriesStateService: InquiriesStateService
  ) {
    this.inqService.selectedRows$$.subscribe((s) => {
      this.selectedItems = s;
      this.resolveStatuses();
      const selectedInteractions = this.selectedItems?.length
        ? this.selectedItems.map((selectedInteraction) => selectedInteraction?.landingInteraction)
        : [];
      this.statusDropdownTooltipText =
        this.inqService.getStatusDropdownTooltipText(selectedInteractions);
      this.interactionEditingDropdownsTooltipText =
        this.inqService.getInlineEditingTooltipText(selectedInteractions);
    });

    this.inqService.updateResetFilters$.subscribe((shouldUpdate: boolean) => {
      if (shouldUpdate) {
        this.getAllFiltersFromStorage();
      }
    });

    this.inqService.inquirySearch$$.subscribe(() => {
      this.getAllFiltersFromStorage();
    });

    this.inqService.resetAdvancedFilters$$.subscribe((filter) => {
      if (filter) {
        this.resetFilters({ field: EResetFilters.All, translation: EResetFilters.All });
        this.getAllFiltersFromStorage();
      }
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      // @ts-ignore
      .subscribe((event: NavigationEnd) => {
        this.getAllFiltersFromStorage();
      });

    this.getAllFiltersFromStorage();
  }

  ngOnInit(): void {
    this.getLandingCommon();
  }

  interactionRequest(priority: any, status: any): void {
    this.filters.selectedPriority = priority;
    this.filters.selectedStatus = status;
    if (this.selectedItems && this.selectedItems.length) {
      const selectedIds = this.selectedItems.map((i) => i.landingInteraction.pk());
      if (this.filters.selectedPriority) {
        this.inqService
          .setLandingAction('set_priority', selectedIds, {
            priority: this.filters.selectedPriority,
          })
          .subscribe(() => {
            this.onActionComplete();
          });
      } else if (this.filters.selectedStatus && this.filters.selectedStatus === 'Merged') {
        const modalRef = this.modalService.open(DetailCloseComponent, MERGE_DIALOG_CONFIG_OPTIONS);
        modalRef.componentInstance.init(selectedIds[0]);
      } else if (this.filters.selectedStatus) {
        this.inqService
          .setLandingAction('set_status', selectedIds, {
            status_label: this.filters.selectedStatus,
          })
          .subscribe({
            next: () => {
              this.onActionComplete();
            },
            error: (errorObj) => {
              this.messageService.httpError(ErrorTitles.ErrorWhileSettingStatus, errorObj);
              this.onActionComplete();
            },
          });
      }
    }
  }

  onReassign(): void {
    this.inqService.reloadTheDataTable$$.next(true);
  }

  getLocalData() {
    return localStorage.getItem('advancedSearch')
      ? JSON.parse(localStorage.getItem('advancedSearch'))
      : { advancedSearch: [] };
  }

  setLocalData(data): void {
    localStorage.setItem('advancedSearch', JSON.stringify(data));
  }

  getAllFiltersFromStorage(): void {
    const advancedSearchData = this.getLocalData();
    const filters = advancedSearchData.advancedSearch.length
      ? advancedSearchData.advancedSearch.filter((filter) => this.isSpecifiedVal(filter.value))
      : [];
    for (let filter of filters) {
      for (let translatedFilter of this.allFiltersForReset) {
        if (filter.field === translatedFilter.selector) {
          filter['translation'] = translatedFilter.name;
          break;
        }
      }
    }
    this.translatedFilters = filters.filter((filters) => !!filters.translation);
    if (this.router.url != InquiriesService.LEFT_MENU_URLS.TEAM_OPEN) {
      this.translatedFilters.push({
        field: EResetFilters.Mode,
        translation: 'Filter_inquiries_by',
      });
    }
  }

  resetFilters(filterObj): void {
    const advancedSearchData = this.getLocalData();
    if (advancedSearchData.advancedSearch.length) {
      if (filterObj.field === EResetFilters.All) {
        advancedSearchData.advancedSearch = [];
      } else {
        advancedSearchData.advancedSearch = advancedSearchData.advancedSearch.filter(
          (filter) => !(filterObj.field == filter.field && isEqual(filter.value, filterObj.value))
        );
      }
      this.setLocalData(advancedSearchData);
    }
    if (
      filterObj.field === EResetFilters.Mode ||
      (filterObj.field === EResetFilters.All &&
        this.router.url != InquiriesService.LEFT_MENU_URLS.TEAM_OPEN)
    ) {
      this.inqService.onFiltersReset$.next('resetAndRedirect');
    } else {
      this.inqService.onFiltersReset$.next('reset');
    }
  }

  exportResponse(response: Blob): void {
    this.loadingReport = false;
    if (response) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(response);
      link.download = 'inquiries-' + new Date().toISOString().slice(0, 10) + '.xlsx';
      link.click();
    } else {
      this.messageService.info('NOTHING_EXPORT');
    }
  }

  exportToExcel(): void {
    if (!this.loadingReport) {
      this.loadingReport = true;

      const advanced = localStorage.getItem('advancedSearch');
      let exportData: any = {};

      if (this.selectedItems && this.selectedItems.length) {
        exportData.advanced_search = [
          {
            field: FConfigs.INTERACTION,
            value: this.selectedItems.map((i) => i.landingInteraction.pk()),
          },
        ];
      } else if (advanced) {
        exportData = InquirySearch.toRest(JSON.parse(advanced));
        exportData.advanced_search = exportData.advanced_search.filter(
          (filter) => EXPORT_FILTERS_TO_IGNORE.indexOf(filter.field) === -1
        );
        exportData.exported_columns = this.inqService.adaptExportColumnsForExport(
          exportData.exported_columns
        );
        exportData.advanced_search = this.inqService.getAdaptedDateFilters(
          exportData.advanced_search
        );
      }

      this.inquiryService.exportInquiry(exportData).subscribe(
        (response: Blob) => {
          this.exportResponse(response);
        },
        (error) => {
          this.loadingReport = false;
          if (error.statusText === ErrorCode.BAD_REQUEST) {
            this.genericDialogService.showMaxDownloadsError();
          } else {
            this.messageService.httpError('Excel Export', error);
          }
        }
      );
    }
  }

  private isSpecifiedVal(val: any): boolean {
    return (!Array.isArray(val) && val !== undefined) || (Array.isArray(val) && val.length > 0);
  }

  private onActionComplete(): void {
    this.filters.selectedPriority = null;
    this.filters.selectedStatus = null;
    this.inqService.reloadTheDataTable$$.next(true);
  }

  private getLandingCommon(): void {
    this.inqService.getLandingCommon().subscribe((response) => {
      this.backendStatuses = response.status_labels_to_set;
      this.resolveStatuses();

      this.inqService.landingCommonData$$.next(response);
      this.inquiriesStateService.setDropdownsState(response);
    });
  }

  private resolveStatuses(): void {
    const isSingleItem = this.selectedItems && this.selectedItems.length === 1;
    this.inquiryStatuses = isSingleItem
      ? this.backendStatuses.concat(['Merged'])
      : this.backendStatuses;
  }
}
