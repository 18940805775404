import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MedisNavigationItem } from '../../../types';
import { inquiriesNavigation } from '../../../navigation/inquiries-navigation';
import { Router } from '@angular/router';
import { InquiriesService as InquiryService } from 'app/modules/data-model/inquiry/inquiries.service';
import { InquirySearch } from 'app/modules/data-model/inquiry/inquiry';
import { FilterDeleteContent } from 'app/modules/inquiries/inquiries-list/inquiries-filter/inquiries-filter-modal/inquiries-filter-modal';
import {
  FConfigs,
  FilterModalConfig,
} from 'app/modules/inquiries/inquiries-list/inquiries-filter/configs/filter-configs';
import { Helpers } from '../../../utils/helpers';
import { cloneDeep } from 'lodash';
import { DialogModesEnum } from 'app/common/common/enums/dialog.enum';
import { EXPORT_FILTERS_TO_IGNORE } from 'app/common/common/constants/search.constants';
import { SearchService } from 'app/modules/inquiries/services/search.service';
import { Subscription } from 'rxjs';
import { ErrorCode } from '../../../enums/error.enum';
import { GenericDialogService } from 'app/common/common/generic-dialog/generic-dialog.service';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { InquiriesService } from 'app/modules/inquiries/services/inquiries.service';
import { DebounceSubject } from 'app/common/util/rx';
import { environment } from '../../../../environments/environment';
import { StorageService } from 'app/common/storage.service';
import { InquiriesFilterComponent } from 'app/modules/inquiries/inquiries-list/inquiries-filter/inquiries-filter.component';
import { Operator, SearchOrigin } from 'app/common/common/enums/search.enum';

@Component({
  selector: 'app-inquiries-left-bar-view',
  templateUrl: './inquiries-left-bar-view.component.html',
  styleUrls: ['./inquiries-left-bar-view.component.scss'],
})
export class InquiriesLeftBarViewComponent implements OnInit, OnDestroy {
  navigation: MedisNavigationItem[] = inquiriesNavigation();
  searchSubject = new DebounceSubject<string>();
  textSearch: string;
  hideWorkInProgress = environment.hideWorkInProgress;
  listFilters: InquirySearch[];
  filteredFilters: InquirySearch[];
  objectKeys = Object.keys;
  private subs: Subscription = new Subscription();

  constructor(
    private helpers: Helpers,
    private dialog: MatDialog,
    private inqService: InquiriesService,
    private searchService: SearchService,
    private genericDialogService: GenericDialogService,
    private messageService: MessageHandlerService,
    private storageService: StorageService,
    private router: Router,
    private inquiryService: InquiryService
  ) {
    this.resolvePendingApproval();

    this.subs.add(
      this.searchService.allSavedSearches$.subscribe((res) => {
        if (res) {
          this.getFiltersList();
        }
      })
    );
  }

  getFiltersList(): void {
    this.inquiryService.getSearches().subscribe((listFilters: InquirySearch[]) => {
      this.listFilters = this.helpers.getAllSavedFilters(listFilters);
      this.searchService.allSavedSearchesData$.next(this.listFilters);
    });
  }

  showAllSavedSearches(popover): void {
    if (this.listFilters.length) {
      this.filteredFilters = this.listFilters;
      this.searchSubject.val = '';
      popover.open();
    }
  }

  handlePopover(popover, open): void {
    if (popover.isOpen() && !open) {
      popover.close();
    } else if (!popover.isOpen()) {
      popover.open();
    }
  }

  filterChange(popover, query: string, listFilters: InquirySearch[]): void {
    if (query) {
      this.filteredFilters = listFilters.filter((filter) => {
        return filter.name.toLowerCase().includes(query.toLowerCase());
      });
      if (this.filteredFilters.length > 0) {
        this.handlePopover(popover, true);
      } else {
        this.handlePopover(popover, false);
      }
    } else {
      this.filteredFilters = [];
      this.handlePopover(popover, false);
    }
  }

  searchChange(popover, query: string): void {
    this.filterChange(popover, query, this.listFilters);
  }

  openFilterModal(popover): void {
    if (popover) popover.close();
    this.searchSubject.val = '';
    this.dialog.open(InquiriesFilterComponent, {
      width: '80%',
      autoFocus: 'dialog',
      maxHeight: '90vh',
      disableClose: true,
      data: { allSavedSearches: this.listFilters && this.listFilters.map((search) => search.name) },
    });
  }

  setFavourite(inquiryFilter: InquirySearch): void {
    inquiryFilter.favourite = !inquiryFilter.favourite;
    this.inquiryService.updateSearch(inquiryFilter).subscribe((res) => {});
  }

  updateFilteredList(popover): void {
    this.inquiryService.getSearches().subscribe((list) => {
      this.listFilters = this.helpers.getAllSavedFilters(list);
      this.filterChange(popover, this.searchSubject.val, list);
      this.searchService.allSavedSearchesData$.next(this.listFilters);
    });
  }

  clearSearch(popover): void {
    this.searchSubject.val = '';
    this.inquiryService.deleteAllSearches().subscribe((re) => {
      this.filteredFilters = [];
      this.handlePopover(popover, false);
      this.getFiltersList();
      this.messageService.info('ALL_DELETED_SUCCESS');
    });
  }

  deleteInquiry(popover, inquiryFilter: InquirySearch): void {
    const modalRef = this.dialog.open(FilterDeleteContent);
    modalRef.componentInstance.inquiryTitle = inquiryFilter.name;
    modalRef.afterClosed().subscribe((result) => {
      if (result && typeof result !== 'string') {
        if ('type' in result) {
          if (result['type'] === FilterModalConfig.DELETE) {
            this.inquiryService.deleteSearch(inquiryFilter).subscribe(() => {
              this.searchSubject.val = '';
              this.updateFilteredList(popover);
              this.messageService.info('DELETED_SUCCESS');
            });
          }
        }
      }
    });
  }

  exportInquiry(inquiryFilter: InquirySearch): void {
    if (Object.keys(inquiryFilter.exportedColumns).length > 0) {
      const exportData = cloneDeep(inquiryFilter);
      exportData.advancedSearch = exportData.advancedSearch.filter(
        (filter) => EXPORT_FILTERS_TO_IGNORE.indexOf(filter.field) === -1
      );
      exportData.exportedColumns = this.inqService.adaptExportColumnsForExport(
        exportData.exportedColumns
      );

      exportData.advancedSearch = this.inqService.getAdaptedDateFilters(exportData.advancedSearch);

      this.inquiryService.exportInquiryID(exportData).subscribe({
        next: (response: Blob) => {
          if (response) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(response);
            link.download = 'inquiries-' + new Date().toISOString().slice(0, 10) + '.xlsx';
            link.click();
          } else {
            this.messageService.info('NOTHING_EXPORT');
          }
        },
        error: (error) => {
          if (error.statusText === ErrorCode.BAD_REQUEST) {
            this.genericDialogService.showMaxDownloadsError();
          } else {
            this.messageService.httpError('Excel Export', error);
          }
        },
      });
    }
  }

  editViewInquiry(popover, inquiryFilter: InquirySearch, view): void {
    popover.close();
    this.searchSubject.val = '';
    const oldToNewinquiryFilter = { ...inquiryFilter };

    this.dialog
      .open(InquiriesFilterComponent, {
        width: '80%',
        autoFocus: 'dialog',
        maxHeight: '90vh',
        disableClose: true,
        data: {
          ...oldToNewinquiryFilter,
          view,
          allSavedSearches: this.listFilters && this.listFilters.map((search) => search.name),
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result == DialogModesEnum.Edit) {
          this.updateFilteredList(popover);
        }
      });
  }

  private resolvePendingApproval(): void {
    this.inqService.landingCommonData$$.subscribe((data) => {
      if (!data) {
        return;
      }
      const myTeam = this.navigation.find((g) => g.id === 'My_team');
      if (!myTeam) {
        return;
      }
      const pendingApproval = myTeam.children.find((c) => c.id === 'Pending_approval');
      if (pendingApproval) {
        pendingApproval.hidden = !data.has_team_with_approval_flow;
      }
    });
  }

  searchInquiryData(inquiry: InquirySearch): void {
    const newInquiry = cloneDeep(inquiry);
    this.inqService.inquirySearch$$.next(InquirySearch.toRest(newInquiry));
  }

  searchInquiry(popover, inquiry: InquirySearch): void {
    if (inquiry.advancedSearch.length > 0) {
      localStorage.setItem('advancedSearch', JSON.stringify(cloneDeep(inquiry)));
      this.searchInquiryData(inquiry);
      this.searchSubject.val = '';
      popover.close();

      if (!this.router.url.includes('all-inq-for-all-team')) {
        this.router.navigateByUrl(InquiriesService.LEFT_MENU_URLS.ALL_INQ_ALL_TEAMS);
      }
    } else {
      this.messageService.info('NOTHING_SEARCH');
    }
  }

  onTextSearchChange(): void {
    let { filtersData, filterIndex } = this.getInquirySearchData();

    if (this.textSearch.length === 0 && filterIndex > -1) {
      filtersData.advancedSearch.splice(filterIndex, 1);
      this.setInquirySearchData(filtersData);
      return;
    } else if (this.textSearch.length === 0 && filterIndex === -1) {
      return;
    }

    const textFilter = {
      join: Operator.And,
      field: FConfigs.TEXT,
      displayTerm: this.textSearch,
      value: [this.textSearch],
      origin: SearchOrigin.Inbox,
    };

    if (filterIndex > -1) {
      Object.keys(filtersData.advancedSearch[filterIndex]).forEach(
        (propertyName) =>
          (filtersData.advancedSearch[filterIndex][propertyName] = textFilter[propertyName])
      );
    } else {
      filtersData.advancedSearch.push(textFilter);
    }
    this.setInquirySearchData(filtersData);
  }

  setTextSearch(): void {
    const { filtersData, filterIndex } = this.getInquirySearchData();
    if (filterIndex > -1) {
      this.textSearch = filtersData.advancedSearch[filterIndex].value[0];
    } else {
      this.textSearch = '';
    }
  }

  getInquirySearchData(): { filtersData: InquirySearch; filterIndex: number } {
    const filtersData = this.storageService.getLocal<InquirySearch>(
      StorageService.Local.ADVANCED_SEARCH,
      Object.assign({}, new InquirySearch({ advanced_search: [], exported_columns: {} }))
    );
    const filterIndex = filtersData.advancedSearch.findIndex(
      (filter) => filter.field == FConfigs.TEXT
    );
    return { filtersData, filterIndex };
  }

  setInquirySearchData(data: InquirySearch): void {
    this.storageService.setLocal(StorageService.Local.ADVANCED_SEARCH, data);
    this.inqService.inquirySearch$$.next(InquirySearch.toRest(data));
  }

  ngOnInit(): void {
    this.getFiltersList();

    this.subs.add(
      this.inqService.onInteractionsChange$.subscribe(() => {
        this.setTextSearch();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
