import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ajax } from 'app/common/ajax';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { BTopic } from '../topic/topic';
import { BCategory } from '../category/category';
import { BProduct, BRegion } from '../region/region';
import { BTeam, BUser } from '../user/user';
import { BCountry } from '../country/country';
import { BRole } from '../role/role';
import { BLanguage } from '../language/language';
import { BEvent } from '../event/event';
import { BSpecialization } from '../specialization/specialization';
import { BTherapeuticArea } from '../medical-document/medical-document';
import { BMedicalDocumentAudience } from '../medical-document-audience/medical-document-audience';
import { BProductGroup } from '../product/product-group';
import { BStatus } from '../status/status';
import { BHcpTypes } from '../hcp-types/hcp-types.model';
import { BSpecializationTypes } from '../specialization-types/specialization-types.model';
import { BCompany } from '../company/company';
import { BDomain } from '../domain/domain';
import {
  BApprovalStepOutcomeType,
  BApprovalType,
  BChangeRequestedActionType,
} from '../medical-documents-approval/medical-documents-approval';
import { AUDIT_TRAIL_DATA_TYPES } from 'app/common/common/audit-trail/audit-trail-data';

@Injectable({
  providedIn: 'root',
})
export class NomenclaturesService {
  private readonly URL: string;

  constructor(private http: HttpClient, private urlResolver: UrlResolverService) {
    this.URL = this.urlResolver.apiUrl('nomenclatures');
  }

  get(scopes: Scopes, background = false, host?: string): Observable<Nomenclatures> {
    const options = background ? Ajax.X_NO_LOADING_OPTIONS : undefined;
    let baseUrl = host ? this.urlResolver.apiUrlForHost(host, 'nomenclatures') : this.URL;
    const url = baseUrl + (scopes ? scopes.get() : '');

    return this.http.get<Nomenclatures>(url, options).pipe(
      map((resp: Nomenclatures) => {
        resp.topics = BTopic.fromRestArray(resp.topics);
        resp.categories = BCategory.fromRestArray(resp.categories);
        resp.products = BProduct.fromRestArray(resp.products);
        resp.productGroups = BProductGroup.fromRestArray(resp.productGroups);
        resp.teams = BTeam.fromRestArray(resp.teams);
        resp.users = BUser.fromRestArray(resp.users);
        resp.countries = BCountry.fromRestArray(resp.countries);
        resp.roles = BRole.fromRestArray(resp.roles);
        resp.languages = BLanguage.fromRestArray(resp.languages);
        resp.events = BEvent.fromRestArray(resp.events);
        resp.specializations = BSpecialization.fromRestArray(resp.specializations);
        resp.closeStatuses = resp.closeStatuses;
        resp.therapeuticAreas = BTherapeuticArea.fromRestArray(resp.therapeuticAreas);
        resp.hcpTypes = BHcpTypes.fromRestArray(resp.hcpTypes);
        resp.specializationTypes = BSpecializationTypes.fromRestArray(resp.specializationTypes);
        resp.medicalDocumentAudiences = BMedicalDocumentAudience.fromRestArray(
          resp.medicalDocumentAudiences
        );
        resp.userPreferences = resp.userPreferences
          ? JSON.parse(resp.userPreferences)
          : resp.userPreferences;
        resp.auditActionsList = resp.auditActionsList;
        resp.auditColumnsList = resp.auditColumnsList;
        resp.companies = BCompany.fromRestArray(resp.companies);
        resp.domains = BDomain.fromRestArray(resp.domains);
        resp.regions = BRegion.fromRestArray(resp.regions);
        resp.medicalDocumentApprovalStepTypes = BApprovalType.fromRestArray(
          resp.medicalDocumentApprovalStepTypes
        );
        resp.medicalDocumentApprovalOutcomeTypes = BApprovalStepOutcomeType.fromRestArray(
          resp.medicalDocumentApprovalOutcomeTypes
        );
        resp.medicalDocumentChangeRequestedActionTypes = BChangeRequestedActionType.fromRestArray(
          resp.medicalDocumentChangeRequestedActionTypes
        );

        resp.authRequestsDropdownData = resp.authRequestsDropdownData && {
          requestor: BUser.fromRestArray(resp.authRequestsDropdownData.requestor),
          requestFor: BUser.fromRestArray(resp.authRequestsDropdownData.requestFor),
          assignedTo: BUser.fromRestArray(resp.authRequestsDropdownData.assignedTo),
          team: BTeam.fromRestArray(resp.authRequestsDropdownData.team),
          role: BRole.fromRestArray(resp.authRequestsDropdownData.role),
        };

        resp.authorizationRequestsDropdownData = resp.authorizationRequestsDropdownData && {
          requestor: BUser.fromRestArray(resp.authorizationRequestsDropdownData.requestor),
          assignedTo: BUser.fromRestArray(resp.authorizationRequestsDropdownData.assignedTo),
        };

        return resp;
      })
    );
  }
}

export class Scopes {
  private queryParams = '?';

  topics(): Scopes {
    return this.add('topics');
  }

  activeTopics(): Scopes {
    return this.add('topics', 'active');
  }

  categories(): Scopes {
    return this.add('categories');
  }

  activeCategories(): Scopes {
    return this.add('categories', 'active');
  }

  products(): Scopes {
    return this.add('products');
  }

  productGroups(): Scopes {
    return this.add('productGroups');
  }

  activeProducts(): Scopes {
    return this.add('products', 'active');
  }

  teams(): Scopes {
    return this.add('teams');
  }

  teamIdsWithProductGroups(): Scopes {
    return this.add('teamIdsWithProductGroups');
  }

  teamsExtendedData(): Scopes {
    return this.add('teams', 'extendedData');
  }

  users(): Scopes {
    return this.add('users');
  }

  sysAdminUsers(): Scopes {
    return this.add('users', 'sysAdmin');
  }

  activeUsers(): Scopes {
    return this.add('users', 'active');
  }

  activeUsersForLicenseCounter(): Scopes {
    return this.add('users', 'activeForLicenseCounter');
  }

  activeInqProcessingUsers(): Scopes {
    return this.add('users', 'activeInqProcessing');
  }

  activeDocProcessingUsers(): Scopes {
    return this.add('users', 'activeDocProcessing');
  }

  usersBasicInfo(): Scopes {
    return this.add('usersBasicInfo');
  }

  activeUsersBasicInfo(): Scopes {
    return this.add('usersBasicInfo', 'active');
  }

  activeInqProcessingUsersBasicInfo(): Scopes {
    return this.add('usersBasicInfo', 'activeInqProcessing');
  }

  userPreferences(key: string): Scopes {
    return this.add('userPreferences', key);
  }

  countries(): Scopes {
    return this.add('countries');
  }

  countryIdsWithExtDepartment(): Scopes {
    return this.add('countryIdsWithExtDepartment');
  }

  roles(): Scopes {
    return this.add('roles');
  }

  languages(): Scopes {
    return this.add('languages');
  }

  events(): Scopes {
    return this.add('events');
  }

  specializations(): Scopes {
    return this.add('specializations');
  }

  hcpTypes(): Scopes {
    return this.add('hcpTypes');
  }

  specializationTypes(): Scopes {
    return this.add('specializationTypes');
  }

  closeStatuses(): Scopes {
    return this.add('closeStatuses');
  }

  therapeuticAreas(): Scopes {
    // Therapeutic Areas without parent area
    return this.add('therapeuticAreas');
  }

  allTherapeuticAreas(): Scopes {
    return this.add('therapeuticAreas', 'all');
  }

  medicalDocumentAudiences(): Scopes {
    return this.add('medicalDocumentAudiences');
  }

  auditActionsList(auditTrailDataType: typeof AUDIT_TRAIL_DATA_TYPES[number]): Scopes {
    if (auditTrailDataType === 'user') {
      return this.add('auditActionsList');
    }
    return this.add('auditActionsList', auditTrailDataType);
  }

  auditColumnsList(): Scopes {
    return this.add('auditColumnsList');
  }

  companies(): Scopes {
    return this.add('companies');
  }

  regions(): Scopes {
    return this.add('regions');
  }

  domains(): Scopes {
    return this.add('domains');
  }

  medicalDocumentApprovalStepTypes(): Scopes {
    return this.add('approvalType');
  }

  medicalDocumentApprovalOutcomeTypes(): Scopes {
    return this.add('approvalStepOutcomeType');
  }

  medicalDocumentChangeRequestedActionTypes(): Scopes {
    return this.add('changeRequestedActionType');
  }

  authRequestsDropdownData(): Scopes {
    // User requests
    return this.add('authRequestsDropdownData');
  }

  authorizationRequestsDropdownData(): Scopes {
    // Category/topic requests
    return this.add('authorizationRequestsDropdownData');
  }

  get(): string {
    return this.queryParams;
  }

  private add(scope: string, filter?: string): Scopes {
    this.queryParams += this.queryParams.length > 1 ? '&' : '';
    this.queryParams += scope;
    this.queryParams += filter ? '=' + filter : '';
    return this;
  }
}

export type TAuthRequestsDropdownData = {
  requestor: BUser[];
  requestFor: BUser[];
  assignedTo: BUser[];
  team: BTeam[];
  role: BRole[];
};

export type TAuthorizationRequestsDropdownData = {
  requestor: BUser[];
  assignedTo: BUser[];
};

export type Nomenclatures = {
  topics?: BTopic[];
  categories?: BCategory[];
  productGroups?: BProductGroup[];
  products?: BProduct[];
  teams?: BTeam[];
  teamIdsWithProductGroups?: number[];
  users?: BUser[];
  statuses?: BStatus[];
  countries?: BCountry[];
  countryIdsWithExtDepartment?: number[];
  roles?: BRole[];
  languages?: BLanguage[];
  events?: BEvent[];
  specializations?: BSpecialization[];
  closeStatuses?: string[];
  therapeuticAreas?: BTherapeuticArea[];
  medicalDocumentAudiences?: BMedicalDocumentAudience[];
  userPreferences?: string;
  hcpTypes?: BHcpTypes[];
  specializationTypes?: BSpecializationTypes[];
  auditActionsList?: string[];
  auditColumnsList?: Array<{
    name: string;
    checked: boolean;
    displayable: boolean;
  }>;
  companies?: BCompany[];
  regions?: BRegion[];
  domains?: BDomain[];
  medicalDocumentApprovalStepTypes: BApprovalType[];
  medicalDocumentApprovalOutcomeTypes: BApprovalStepOutcomeType[];
  medicalDocumentChangeRequestedActionTypes: BChangeRequestedActionType[];
  authRequestsDropdownData: TAuthRequestsDropdownData;
  authorizationRequestsDropdownData: TAuthorizationRequestsDropdownData;
};
