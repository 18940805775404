import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { BAttachment } from './attachment';
import { ResponseWithOptionalMessage } from 'app/common/types';
import { GenericDialogComponent } from 'app/common/common/generic-dialog/generic-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';

export type ResponseWithOptionalInteractionIds = {
  interactionIds?: number[];
};
@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  private readonly URL_ATTACHMENTS: string;

  constructor(
    private http: HttpClient,
    urlResolver: UrlResolverService,
    private matDialog: MatDialog
  ) {
    this.URL_ATTACHMENTS = urlResolver.misApiUrlForPath('/attachments');
  }

  upload(fileBase64: string): Observable<BAttachment> {
    return this.http
      .post<BAttachment>(this.URL_ATTACHMENTS, { file: fileBase64 })
      .pipe(map((r) => BAttachment.fromRest(r)));
  }

  delete(...attachmentIds: number[]): Observable<void> {
    return this.http.delete<void>(this.URL_ATTACHMENTS, { body: { attachmentIds } });
  }

  uploadToHistory(historyId: number, isDraft: boolean, fileBase64: string): Observable<string> {
    return this.http.post<string>(this.buildURL(historyId), { isDraft: isDraft, file: fileBase64 });
  }

  copyToAnswer(attachmentId: number, answerDraftHistoryId: number): Observable<string> {
    return this.http.put<string>(this.buildURL(answerDraftHistoryId), {
      attachmentId: attachmentId,
    });
  }

  deleteFromHistory(
    attachmentId: number,
    historyId: number,
    isDraft: boolean,
    deleteFromAll: boolean = false
  ): Observable<void> {
    return this.http
      .delete<ResponseWithOptionalMessage>(this.buildURL(historyId), {
        params: { attachmentId, historyId, isDraft, deleteFromAll },
      })
      .pipe(
        map((response) => {
          if (response?.message) {
            GenericDialogComponent.showMessage(this.matDialog, response.message);
          }
        })
      );
  }

  getInteractionIdsWhereAttachmentIsPresent(
    attachmentId: number,
    historyId: number
  ): Observable<ResponseWithOptionalInteractionIds> {
    return this.http.get<ResponseWithOptionalInteractionIds>(
      `${this.buildURL(historyId)}?attachmentId=${attachmentId}`
    );
  }

  confirmDeleteAttachment(
    matDialogService: MatDialog,
    messageService: MessageHandlerService,
    attachment: BAttachment,
    callback: (attachmentObj: BAttachment, deleteConfirmed: boolean, deleteFromAll: boolean) => void
  ): void {
    this.getInteractionIdsWhereAttachmentIsPresent(
      attachment.pk(),
      attachment.historyObjectPk
    ).subscribe({
      next: (response) => {
        const dialogRef = GenericDialogComponent.confirmDeleteAttachment(
          matDialogService,
          response?.interactionIds
        );
        dialogRef.componentInstance.response.subscribe((deleteConfirmed) => {
          if (deleteConfirmed) {
            callback(attachment, true, dialogRef.componentInstance.checkboxValue);
          }
          dialogRef.close();
        });
      },
      error: (error) => {
        messageService.httpError(
          'An error occurred while getting the interaction ids for the attachment',
          error
        );
      },
    });
  }

  private buildURL(historyId: number): string {
    return `${this.URL_ATTACHMENTS}/${historyId}`;
  }
}
