export const japaneseLangData = {
  MEDICAL_INFO_SYSTEM: '医療情報システム',
  ACTIVE_EVENTS: 'アクティブなイベント',
  SELECT_AN_EVENT_TO_BROWSE_USERS: 'イベントを選択してユーザーを閲覧します。',
  BACK_TO_THE_EVENT_SELECTION: 'イベント選択に戻る',
  IF_YOU_FORGOT_YOUR_PASSWORD: 'パスワードを忘れた場合 ',
  LOGIN: 'ログインする',
  RESET_IT_HERE: 'ここでリセットしてください',
  CHANGE_LANGUAGE_TO: '言語の変更',
  TEST_SYSTEM: '試験システム',
  HELP: '助ける',
  THIS_IS_TEST_ENV: 'これはテスト環境であり、もしあなたが実際の問い合わせを挿入したい場合は',
  USER_OR_PASS_IS_INCORRECT: 'ユーザー名またはパスワードが正しくありません',
  OPS: '作戦',
  WE_DID_NOT_FIND: '探していたものが見つかりませんでした',
  VIDEO_TUTORIALS: 'ビデオチュートリアル',
  STILL_DIDNT_FIND: 'まだ探しているものが見つかりませんでした。,',
  ASK_THE_SUPPORT_TEAM: 'サポートチームに依頼する',
  LOGGED_IN_AS: 'としてログイン',
  MY_PROFILE: '私のプロフィール',
  LOGOUT: 'ログアウト',
  RETRIEVING_YOUR_ACCESS_INFO: 'アクセス情報の取得',
  LOGGED_IN_CAN_CLOSE: 'ログインすると、このタブを閉じることができます。',
  TOKEN_HAS_NOT_BEEN_SAVED: 'トークンが正しく保存されていません。',
  SHARE_POINT_LOGIN: 'SharePoint のログイン',
  LOGIN_TO_SHARE_POINT: '続きを読むには、SharePoint にログインしてください。',
  PASSWORD_RESET: 'パスワードリセット',
  RESET_PASSWORD: 'パスワードのリセット',
  INSERT_YOUR_EMAIL_TO_RESET_PASS: 'パスワードを再設定するには、あなたのメールを挿入してください。',
  CANCEL: 'キャンセル',
  CANCEL_UPPERCASE: 'キャンセル',
  RETURN_TO_LOGIN: 'ログインに戻る',
  USERNAME: 'ユーザー名',
  USERNAME_IS_REQUIRED: 'ユーザー名は必須です。',
  PASSWORD: 'パスワード',
  PASSWORD_IS_REQUIRED: 'パスワードが必要です。',
  FIRST_NAME: 'ファーストネーム',
  LAST_NAME: '苗字',
  REGISTER: '登録',
  LOADING: 'ローディング',
  INSERT_NEW_PASS: '新しいパスワードを入力してください',
  VERIFYING_TOKEN: 'トークンの検証',
  PASSWORD_DO_NOT_MATCH: 'パスワードが一致しない',
  PASS_RESET_SUCCESSFUL: 'パスワードリセット成功',
  SAVE_NEW_PASS: '新しいパスワードを保存',
  GO_TO_INBOX: '受信箱へ',
  SELECT_ALL: 'すべてを選択',
  DESELECT_ALL: 'すべて選択解除',
  DO_NOT_SHOW_AGAIN: ' 再度表示しないでください',
  INQUIRY: 'お問い合わせ',
  ATTACHMENT_WITH_THIS_INQUIRY: '>このお問い合わせに添付されている添付書類',
  USER_DETAILS: 'ユーザー詳細',
  INQUIRER_DETAILS: 'お問い合わせ者の詳細',
  CLOSE: 'クローズ',
  TEAMS: 'チーム紹介',
  TEAM: 'チーム',
  SPECIALIZATION_IS: '専門化は',
  REQUIRED: 'ひつよう',
  RESULTS_PER_PAGE: 'ページあたりの結果',
  RESULTS_FOUND: '見つかった結果',
  EXPORT: '輸出',
  PERSONAL_SETTINGS: '個人設定',
  TEAM_SETTINGS: 'チーム設定',
  USER_SETTINGS_OF: 'ユーザー設定',
  SET_TEMPLATE: 'テンプレートの設定',
  NOTIFICATIONS_WILL_BE_OVERWRITTEN: '通知は現在のもので上書きされます。',
  PREVIEW: 'プレビュー',
  NOTIFICATIONS: 'お知らせ',
  ROLE: '役割',
  TEMPLATE_NAME: 'テンプレート名',
  SAVE_THIS_USER_AS_A_TEMPLATE: 'このユーザーをテンプレートとして保存する',
  ADD_A_TEMPLATE_TO_YOUR_USER:
    'ユーザーにテンプレートを追加し、通知のためのロールと設定をコピーします。',
  AUTH_AND_NOTIFY_TEMPLATE: '承認と通知のテンプレート',
  MY_ROLES: '私の役割',
  STATUS: 'ステータス',
  THIS_ROLE_IS_TEMP:
    'この役割は一時的なもので、このユーザーが代理であるという事実のために与えられています。',
  CREATED: '作成',
  APPROVED_REJECTED: '可否',
  APPROVED_REJECTED_BY: '賛否',
  ARCHIVE: 'アーカイヴ',
  SAVE: '保存',
  IMMEDIATE_EFFECT: '即効性',
  OUT_OF_OFFICE_DEPUTY: '留守番代行',
  AUTO_ASSIGN_TO: 'オートアサイン',
  GEOGRAPHY: '地理学',
  ALIGNMENT: 'アライメント',
  Alignments: 'アライメント',
  Address: '住所',
  PHONE_FAX: '電話/ファックス',
  Email: '電子メール',
  Contacts: '連絡先',
  WORK_INFO: '仕事情報',
  LAST_NAME_IS: '名字は',
  PERSONAL_INFO: '個人情報について',
  USER: 'ユーザー',
  ID: '身分証明書',
  UPDATED_FROM: '書き換え',
  ON: 'に於いて',
  CRM: 'シーアールエム',
  IMPORTED_FROM: '舶来',
  CHANGE_PASS: 'パスワードの変更',
  NOTIFICATION_IN_TEMPLATE: 'テンプレート内の通知',
  CONTINUE: '続ける',
  DONT_SHOW_AGAIN: 'このメッセージを二度と表示しないでください',
  USING_THE_TABS_ON_THE: '画面上部のタブを使って',
  PERSONAL_PROFILE_SETTINGS: '個人プロフィールの設定',
  AND_YOUR: 'とあなたの',
  YOU_CAN_SWITCH_BETWEEN: '切り替えることができます。',
  WELCOME_TO_YOUR_USER_PROFILE: 'ユーザープロフィールへようこそ',
  SAVE_THIS_USER_AS: 'このユーザーをテンプレートとして保存',
  INCLUDE_NOTIFY_SETTINGS: '通知設定を含む',
  INCLUDE_ROLES_SETTINGS: 'ロール設定を含む',
  ACTIVE_NOTIFICATIONS: 'アクティブ通知',
  SET_THIS_USER_AS_A_: 'このユーザー認証と通知を新しいテンプレートに設定します。',
  REQUEST_AUTH: 'リクエスト承認',
  REQUEST: 'リクエスト',
  FEEDBACK_SUPPORT: 'フィードバックとサポート',
  IF_YOU_STILL_CANNOT_FIND_WHAT_:
    'それでもお探しのものが見つからない場合は、サポートチームにお尋ねください! また、将来的な改善のための提案がある場合は、ここが適切な場所です。.',
  TEMPLATE: 'テンプレート',
  SIGNATURE: '署名',
  Disclaimers: '免責事項',
  Greetings: '挨拶',
  CUSTOMIZE: 'カスタマイズ',
  SAVE_UPPERCASE: '保存',
  DELETE: '削除',
  RESET_TO_DEFAULT: 'デフォルトに戻す',
  AFTER_SIGNATURE: '署名後',
  BEFORE_SIGNATURE: '署名前',
  AFTER_ANSWER: '回答後',
  BEFORE_ANSWER: '回答前',
  AFTER_GREET: '挨拶の後',
  BEFORE_GREET: 'ご挨拶の前に',
  Any: '何れかの',
  HCP: 'エイチシーピー',
  NO_HCP: 'HCPなし',
  Position: 'ポジション',
  ON_LABEL: 'ラベルの上',
  OFF_LABEL: 'オフラベル',
  YOU_ARE_TRYING_TO_CUSTOMIZE: 'テンプレートをカスタマイズしようとしています。',
  YOU_DO_NOT_HAVE_THE_PERMISSON:
    '  あなたには許可が下りていません。進めたい場合は、テンプレートのコピーが作成されますので、そこに変更を加えてください。.',
  CREATE_COPY: 'コピーを作成する',
  RECEIVED_ON: '受け取り',
  SENDER_EMAIL: '送信者メール',
  SUBJECT: '対象者',
  UNSPAM: 'アンスパム',
  UPLOAD: 'アップロード',
  APPROVED: '承認済み',
  DRAFT: 'ドラフト',
  SELECT_STATUS: 'ステータスを選択',
  UPLOAD_AS: 'アップロード',
  TITLE: 'タイトル',
  REPOSITORY: 'リポジトリ',
  BROWSE: '閲覧',
  URL: 'ＵＲＬ',
  APPROVED_ON: '承認済み',
  AUTH_HOLDER: '認証保有者',
  AUTH_NUMBERS: '認可番号',
  APPROVED_FOR: '承認済み',
  SELECT_ONE_REGION: '地域を選択',
  OWNED_BY: '所有者',
  KEYWORDS: 'キーワード',
  TOPICS: 'トピックス',
  CATEGORIES: 'カテゴリー',
  PRODUCTS: '製品情報',
  PRODUCT: '製品',
  LANGUAGE_IS_REQUIRED: '言語は必須です。',
  LANGUAGE: '言語',
  TARGET_CUSTOMERS: '対象顧客',
  TYPE_IS_REQUIRED: 'タイプが必要です。',
  TYPE: 'タイプ',
  TITLE_IS_REQUIRED: ' タイトルは必須',
  DEFAULT_REFERENCE_IS_CREATED_:
    'デフォルトのリファレンスはバンクーバースタイルで作成されます。これを上書きしたい場合は、ここにリファレンス全体を入力してください。',
  REFERENCE: '参照',
  DOI: 'じどうよみとりひょうじゅんかん',
  PUBMED_ID: 'パブメドアイディー',
  PAGES: 'ページ数',
  ISSUE: '問題',
  VOLUME: 'ボリューム',
  JOURNAL: 'ジャーナル',
  AUTHORS: '著者',
  ABSTRACT: '抄録',
  REFERENCE_IS_REQUIRED: '参考文献が必要です。',
  THIS_DOC_IS_APPROVED: 'この文書は承認されています。を開くにはここをクリックしてください。 ',
  ORIGINAL_DOCUMENT_AND_: 'オリジナルのドキュメントとコメントを挿入します。',
  EDIT_FILE: 'ファイル編集',
  PROPERTIES: '物件情報',
  OPEN: '開く',
  LOCALIZED_DOCUMENTS: 'ローカライズされたドキュメント',
  ORIGINAL_DOCUMENTS: '原本',
  INTERNAL_APPROVAL_NUMBER: '社内承認番号',
  CREATED_BY: '作成者',
  EXPIRATION_DATE: '有効期限',
  VERSION_NUMBER: 'バージョン番号',
  NO_VERSION_AVAILABLE: '利用可能なバージョンはありません',
  ARCHIVED_IF_NO_DRAFT: 'アーカイブされたもの: ドラフト/承認済みのバージョンがない場合. ',
  APPROVED_FOR_USERS: '承認された：ユーザーのために。 ',
  DRAFT_FOR_AUTHORS_: 'ドラフト：著者・編集者向け。',
  VERSION_LEGEND: 'バージョンの伝説                      ',
  VERSION: '版',
  SELECT_ONE_LANG: '言語を1つ選択してください。',
  SELECT_ONE_TYPE: '一つのタイプを選択してください。',
  INTERNAL_NUMBER_REQUIRED: '社内承認番号が必要です。',
  FILE: 'ファイル',
  ATTACH_FILE: ' 添付ファイル',
  LITERATURE: '文学',
  SLIDE_DECK: 'スライドデッキ',
  PIL: 'ピル',
  FAQ: 'よくあるご質問',
  SRD: '符号付き文字列変換',
  ADD_APPROVAL_STEP: '承認ステップの追加',
  ADD_PARALLEL_STEP: 'パラレルステップの追加',
  DELETE_STEP: '削除ステップ',
  DELETE_ALL_PARALLEL_STEPS: 'すべての平行ステップを削除する',
  CHANGE_STATUS_NAME: 'ステータス名の変更',
  SET_REQUIRED_ROLE: '必要な役割を設定する',
  SET_TEAM_MEMBER: 'チームメンバーの設定',
  YOU_DO_NOT_HAVE_THE_PERMISSION_TO_: 'このプロセスを変更する権限がありません。',
  SAVE_ROLE: 'ロールを保存',
  CREATE_NEW_WORKFLOW: '新規ワークフローの作成',
  CREATE_NEW_STEP: '新規ステップを作成する',
  PLEASE_ENTER_THE_NUMBER_OF_:
    '並行承認ステップ数、つまり、同時に承認されるステップを入力してください。並行承認ステップがない場合は、「1」と入力してください。承認ステップ数は後からいつでも変更できます。',
  APPROVAL_MUST_BE_AT_LEAST: '承認は少なくとも',
  INSERT_THE_NEW_STATUS_NAME: '新しいステータス名を挿入する',
  INSERT_THE_NEW_WORKFLOW_NAME: '新しいワークフロー名を挿入します。',
  INSERT_THE_NEW_STEP_NAME: '新しいステップ名を挿入します。',
  NEW_DOCUMENT: '新しいドキュメント',
  CREATE: '作成',
  DOCUMENT_MANAGEMENT: '文書管理',
  CONFIRM: '確認',
  CONFIRM_PROCESS: '確認処理',
  DEFINE_APPROVAL_STEPS_AND_TEAM: '承認ステップとチームを定義する',
  SET_DETAILS: 'セット詳細',
  SELECT_TYPE: 'セレクトタイプ',
  COMPLETE_LOCALIZATION_PROCESS: '完全なローカライズプロセス',
  CREATE_NEW_VERSION: '新しいバージョンを作成する',
  CREATE_NEW_DOCUMENT: '新規ドキュメントの作成',
  IS_PRIVATE: ' プライベートであること',
  SHARED_WITH_OTHER_TEAMS: ' 他チームとの共有',
  PLEASE_ENTER_THE_NUMBER_IF_SEQ_:
    '必要な逐次承認ステップ数を入力してください。2つの承認が並行している場合は、1つのステップとしてカウントされます。承認ステップ数は後からいつでも変更できます。',
  DONE: '了',
  SUBMIT_FOR_APPROVAL: '承認のために提出',
  YOU_HAVE_TO_CONFIRM_THE_: 'アップロード前の処理を確認する必要があります。',
  SELECT_YOUR_DEC_TYPE: 'ドキュメントの種類を選択してください',
  TARGET_AUDIENCE: '対象者',
  PROCESS_DETAILS: 'プロセスの詳細',
  START_DATE: '開始日',
  DOC_DETAILS: 'ドキュメントの詳細',
  CONFIRMATION: '確認',
  APPROVAL_PROCESS: '承認プロセス',
  SCHEDULED_EXP_DATE: '賞味期限予定日',
  SCHEDULED_PUBLISHING_DATE: '発行予定日',
  NO_PROCESS_SELECTED: '選択されたプロセスはありません',
  COUNTRIES: '国・地域',
  DOC_ID: '文書ID',
  THERAPEUTIC_AREA: '治療領域',
  FILTER: 'フィルター',
  BACK: 'うしろ',
  ATTACH: 'アタッチ',
  COPY_ANSWER: 'コピーアンサー',
  SIMILAR_INQUIRIES: '類似のお問い合わせ',
  SDR_FAQ: 'SRD / FAQ',
  SLIDE_DECKS: 'スライドデッキ',
  MEDICAL_DOC: '医療文書',
  SHOW: 'ショー',
  LOAD_MORE: 'もっと読み込む ',
  NO_RESULT_FOUND: '結果は見つかりませんでした。',
  CAN_BE_USED_TO_SEARCH_FOR_AN_:
    'は文全体を検索したい場合に使用し、-は指定した単語の範囲内で検索結果から除外したい場合に使用します。',
  SEARCH_FOR_MEDICAL_SIMILAR_INQ: '医療関係の書類や類似のお問い合わせを検索',
  SEARCH: '検索',
  DRAFT_VERSION: '案',
  AVAILABLE_ACTIONS: '利用可能なアクション',
  OPEN_DOC: 'オープンドキュメント',
  UPLOAD_FILE: 'ファイルのアップロード',
  THE_DOCUMENT_IS_STILL_IN_APPROVAL_:
    'ドキュメントの内容を変更したい場合は、Sharepointでドキュメントを開くか、更新版をアップロードしてください。',
  FINALIZE_LOCALIZATION: 'ローカライズの最終決定',
  THIS_DOC_HAS_BEEN_LOCALIZED:
    'この文書はローカライズされており、承認前にプロセスを最終化する必要があります。',
  Preview: 'プレビュー',
  PREVIEW_AND_SEND: 'プレビューして送信',
  PREVIEW_AS_PDF: 'PDFでプレビュー',
  PUBLISHED_VERSION: '公開バージョン',
  NEW_VERSION: '新バージョン',
  THE_DOC_IS_STILL_IN_APPROVAL_:
    '文書の内容を変更したい場合は、他のファイルをアップロードしてください。',
  OUT_TO: '出向く',
  NEXT: '次',
  SELECTED_SLIDES: '選択されたスライド',
  GO_TO_SLIDE: 'スライドへ',
  PREV: '前',
  SELECT_SLIDE: 'スライドを選択',
  ANSWER: '回答',
  BACK_TO_STANDARD_VIEW: '標準表示に戻ります。',
  INQUIRER: '照会者',
  UPLOAD_NEW_FILE: '新規ファイルのアップロード',
  CREATE_FILE: 'クリエイトファイル',
  EDITING_WORD_DOC: 'ワード文書の編集',
  INSERT_FILE_NAME: 'ファイル名の挿入',
  PROCEED_WITH_THE_LINK_: 'ファイルのリンク先へ進む',
  A_NEW_WINDOW_WILL_BE_: ' 空のファイルで新しいウィンドウが開き、内容を書き込んでタブを閉じる',
  INSERT_THE_NAME_FOR_: '作成したい添付ファイルの名前を挿入してファイル作成を進める',
  INSTRUCTIONS: '指示',
  CREATE_NEW_WORD_FILE_FOR: 'のための新しいワードファイルを作成します。',
  FROM: 'から',
  YOU_CAN_FIND_HERE_THE_LAST_INQ_:
    'ここでは、あなたがシステムに挿入した最後のお問い合わせを確認することができます。挿入した問い合わせを再利用して、より多くの類似した問い合わせを作成したり、挿入が終わっていない問い合わせに戻ったりすることができます。',
  MY_DRAFT_INQ: '私のドラフトに関するお問い合わせ',
  WARNING_CHANGES_NOT_SAVED: '警告。変更が保存されていない',
  PAGE_THAT_WILL_BE_LOST: '続けると決めた場合に失われるページ。',
  YOU_HAVE_UNUSED: 'に未保存の変更があります。',
  ARE_YOU_SURE_YOU_WANT_TO_: '保存せずにこのページを残してもよろしいですか？',
  LEAVE_THIS_PAGE: 'このページを残す ',
  STAY_ON: '留まってください',
  ASSIGNED_TO: '割当先',
  THIS_INQ_HAS_BEEND_INSERTED_: 'この問い合わせはイベント中に挿入されています。',
  STATUS_IN_APPROVAL: ' ステータスを表示します。承認時',
  THE_RESPONSIBLE_FOR_THE_ANSWER_: '回答承認の責任者は、回答を受け入れなければなりません。',
  STATUS_DRAFT: 'ステータス。ドラフト',
  IF_YOU_WANT_CREATE_THE_ACTUAL_INQ_:
    '実際のお問い合わせを作成したい場合は、送信タブから作成ボタンをクリックしてください。',
  INQ_IMPORTED_FROM: 'から輸入したお問い合わせ',
  SEND_NEW_ANSWER: '新解答を送る',
  RETURN_TO_INBOX: '受信箱に戻る',
  SUBMITTED_FOR_APPROVAL: '承認申請',
  ANSWER_SUBMITTED_ON: 'に提出された回答',
  BY: '掖',
  INQ_HAS_BEEN_CLOSED: 'お問い合わせは回答がないまま終了しました。',
  BCC: 'Ｂｃｃ',
  ANSWER_GIVEN_ON: 'に与えられた回答 ',
  ANSWER_SENT_ON: '回答が送信されました ',
  INBOX: '受信箱',
  OPEN_ORIGINAL_INQ: '独自の問い合わせをオープンにする',
  CLOSE_ANSWER_NOT_NEEDED: '閉じろ アンサーは必要ありません',
  HISTORY: '歴史',
  ADD_A_NOTE: 'メモを追加する',
  ADD_A_NOTE_INTO_: '照会履歴にメモを追加します。',
  SHARE_OR_START_A_FOLLOW_UP: 'シェアしたり、フォローを開始したりする',
  SHARE_FROM_HERE_YOU_CAN_:
    '共有：ここから、質問者へのフォローアップを割り当てたり、転送したり、開始したりすることができます。',
  SEE_ALL_INQ_RELATED_TO: '関連するお問い合わせをすべて見る',
  SUGGESTED_INQ: '提案された問い合わせ先',
  SET_AS_NEW_INQUIRER: 'しんきにする',
  SRD_NEEDED: 'SRDが必要',
  ANSWER_UNAVAILABLE: '答えは利用できません',
  QUESTION: '質問',
  SUBMIT: 'サブミット',
  ANSWER_UPPERCASE: '回答',
  DETAILS: '詳細',
  INQUIRER_UPPERCASE: '質問者',
  SPLIT_INQ: '分割問い合わせ',
  ADD_QUESTION: '質問の追加',
  ENTER_QUESTION: '質問を入力してください',
  MARK_AS_ANSWERED: ' 回答としてマークする',
  TO_BE_ANSWERED: '答えられること',
  FOLLOW_UP_RECEIVED: '受信したフォローアップ',
  ERROR: 'エラー',
  SHOW_ORIGINAL_MESSAGE: 'オリジナルのメッセージを表示する',
  ANSWER_HAS_NOT_BEEN_SENT_TO_THE_INQ_:
    'メールアドレスが間違っていたため、問い合わせ者に回答が届いていません。 ',
  SEND: '送信',
  DO_NO_SEND: '送信しないでください',
  SEND_CONFIRMATION_VIA_EMAIL_: '質問者に質問が届いたことをメールで確認する',
  MSG_NOTIFICATION_WILL_BE_SEND_1:
    'ありがとう！ お問い合わせが送信または回答されると、通知が送信されます。',
  MSG_NOTIFICATION_WILL_BE_SEND_2: 'ありがとう！ 照会者が保存されると、通知が送信されます。',
  NOTIFICATION_WILL_BE_SENT_TO_THE_INQ: '次のGDPR通知は、電子メールで照会者に送信されます。',
  NOTIFICATION_SENT_TO_THE_INQ: '次のGDPR通知が、電子メールで照会者に送信されました。',
  SEND_A_NOTIFICATION_TO_THE_INQ: '次のGDPR通知を電子メールで照会者に送信します。',
  OK: '承知しました',
  MAX_LENGTH_FOR_ZIP_CODE: ' 郵便番号の最大長さは',
  CHARACTERS: '登場人物',
  ADDRESS_1_IS_: '住所1は',
  LABEL_IS: '  ラベルは',
  NO_QUESTION_INSERTED: '質問の挿入はありません',
  WARNING: '警告',
  INQ_HAS_NO_EMAIL: '問い合わせ者にはメールがありません。 ',
  FOLLOW_UP_IS_NOT_AVAILABLE_: 'この相互作用ではフォローアップはできません。 ',
  INSERT_INQ_TEXT: 'お問い合わせテキストを挿入する',
  SHOW_MORE: 'もっと見る',
  OUT_OF: 'を離れて',
  INQ_TO_BE_SENT: 'お問い合わせ先',
  HIDE_INQ: 'お問い合わせを隠す',
  SHARE: 'シェア',
  HCP_PROFILE_OF: 'のHCPプロフィール',
  A_NOTIFICATION_HAS_NOT_BEEN_TO_INQ: '照会が受信されたという通知は照会者に送信されていません。',
  INQUIRER_DOES_NOT_HAVE_AN_EMAIL:
    '以下のすべての必須フィールドと少なくとも1つのメールアドレスに入力し、保存してGDPR受領通知をプレビューします。',
  CONFIRMATION_SENT_BY: 'によって送信された通知',
  NOTIFICATION_SENT_TO_THE_INQUIRER: '照会が受信されたという通知が照会者に送信されます。',
  COUNTRY_IS: '国は',
  COUNTRY: '国',
  AT_LEAST_ONE_CONTACT_IS_: ' 少なくとも1つの連絡先',
  EMAIL_IS: '電子メールは',
  EMAIL_LABEL_IS: 'メールラベルは',
  PHONE_FAX_IS: 'Phone / ファクスのラベルは ',
  PHONE_FAX_NUMBER_IS: ' 電話・FAX番号は ',
  ADDRESS: '住所',
  DELETE_PERSONAL_DATA: '個人情報の削除',
  HCP_VERIFIED: 'HCP検証済み',
  KEY_OPINION_LEADER: 'キーオピニオンリーダー',
  HOSPITAL_BASED: '病院ベース',
  HCP_RELATED_INFORMATION: 'hcp関連情報',
  ACCEPT: '受け入れて下さい',
  REJECT: '拒否',
  DO_YOU_WANT_TO_ACCEPT_THESE_Q: 'これらの提案を受け入れますか？',
  SALES_REP: '販売員',
  SUGGEST_A_TOPIC: 'トピックを提案する',
  TEAM_SPECIFIC_FIELDS: 'チーム別分野',
  ADR_RELATED: 'ADR関連',
  NOT_ADR_RELATED: 'ADR関連ではありません',
  PLEASE_REVIEW_THE_MESSAGE_AND_:
    'メッセージを確認し、有害薬物反応に関連しているかどうかを分類してください。',
  ADVERSE_DRUG_REACTION: '副作用',
  CHANGE_DETAILS: '変更の詳細',
  YES: '噫',
  NO: '否',
  DUE_DATE: '期限',
  CRITICAL: 'クリティカル',
  NORMAL: '通常',
  PRIORITY: '優先順位',
  SELECT_DETAILS: '詳細選択',
  SAVE_REVIEW: 'レビューを保存',
  SET_TO_ALL_INQ: 'すべての問い合わせにセット',
  PRODUCT_QUALITY_COMPLAINT: '製品の品質クレーム',
  PQC_PC_A_PRODUCT_:
    'PQC/PC 製品品質苦情（QC）または問題は、任意の医薬品の欠陥または誤動作を含む、または任意の医薬品の品質、性能、安全性に関する懸念として定義されています。',
  OFF_LABEL_USE_OF_PHARMA___:
    '承認されていない適応症で、または承認されていない年齢層、用法・用量、投与経路での医薬品の適応外使用。',
  A_RESPONSE_TO_A_MEDICINAL_:
    ' 医薬品に対する反応が有害性があり、意図しない反応であり、医薬品と有害事象との間に因果関係があることが少なくとも合理的な可能性がある場合。また、特殊な状況の場合（妊娠中、適応外使用、過量投与など）もADRとして表示する必要があります。',
  NORMAL_IS_7_WORKING_DAYS: '通常は7営業日、高は48時間、クリティカル（患者関連）は24時間です。',
  CREATE_MULTIPLE_QUESTIONS: '同じ質問者同士のやりとりのために複数の質問を作成します。',
  CATEGORY_AND_TOPIC_ARE: 'カテゴリとトピックは',
  PRODUCT_IS: '製品は',
  DO_YOU_WANT_TO_ACCEPT_: 'これらの提案を受け入れますか？',
  MERGE: 'マージ',
  Duplicate: '複製',
  Other: 'その他',
  Spam: 'スパム',
  CLOSE_INTERACTION: '緊密な相互作用',
  RE_OPEN_THE_TARGET_: 'マージ後に対象の問い合わせを再度開く ',
  THIS_IS_JUST_A_THANK_YOU:
    'これは、既存の問い合わせの問い合わせ者からのお礼のメッセージに過ぎません。',
  MARK_AS_INTERNAL_: '既存の問い合わせの内部メッセージとしてマークを付けます。',
  MARK_AS_AN_ERROR_: ' 既存の問い合わせのエラーメッセージとしてマークを付けます。',
  THIS_MESSAGE_WILL_BE_EXISTING_:
    'このメッセージは、既存のお問い合わせのフォローアップとしてマークされます。',
  THANK_YOU: 'ありがとうございます',
  AN_INTERNAL_MESSAGE: '内部メッセージ',
  AN_ERROR_MESSAGE: 'エラーメッセージ',
  NEW_INQ: '新規お問い合わせ',
  A_FOLLOW_UP_RESPONSE: 'フォローアップの対応',
  THIS_INQ_IS: '今回のお問い合わせは',
  SPLIT_INTERACTION: 'スプリットインタラクション',
  SAVE_AND_CLOSE: '保存して閉じる',
  SAVE_INQ: 'お問い合わせを保存',
  REVIEW_THE_TEXT_AND: '文章と分類を見直し、確認する',
  CHOOSE_THE_RELEVANT_PRODUCT:
    '選択したテキストに関連する製品、カテゴリ、およびトピックを選択します。',
  SELECT_THE_TEXT_THAT_:
    '1つの製品/トピックに関する質問を識別するテキストを選択してください（挨拶、署名、免責事項、またはその他の関連性のない情報は含まないでください）。',
  REPEAT_THE_PREVIOUS_: '前の手順を繰り返して、問い合わせを追加します。',
  READ_MORE: '続きを読む',
  READ_LESS: '続きを読む',
  DO_NOT_INCLUDE_: '挨拶、署名、免責事項、その他関連性のない情報を含まないでください。',
  SELECT_THE_TEXT_THAT_IDENT: '1つの製品/トピックに関する質問を識別するテキストを選択します。',
  IF_YOU_HAVE_MULTIPLE_: '複数の商品や話題がある場合は、問い合わせを分割する必要があります。',
  THE_FOLLOWING_PROCESS_:
    ' 以下の工程で、商品/トピックごとに関連するテキストを特定することができます。',
  EXPORT_TO_PDF: 'PDFへのエクスポート',
  INQ_CREATED: 'インクイザーが作成した',
  Added: '追加された',
  Deleted: '削除された',
  From: 'より',
  To: '宛先',
  BACK_TO_INBOX: '受信箱に戻る',
  PERMISSION_DENIED: '許可否認',
  THIS_INQ_IS_LOCKED_BY: 'この問い合わせはロックされています。',
  FORCE_UNLOCK: '強制ロック解除',
  INQUIRIES: 'お問い合わせ',
  Download: 'ダウンロード',
  Event: 'イベント',
  INSERTED_BY: '挿入方法',
  LAST_EDITED_ON: '最終更新日',
  LAST_EDITED_BY: '最後に編集されました。',
  CREATED_ON: '作成日',
  TOPIC: 'トピック',
  TABLE_OPTIONS: 'テーブルオプション',
  DEPARTMENT: '部署',
  User: 'ユーザー',
  ADD_THERAPEUTIC_AREA: '治療部位の追加',
  CREATE_THERAPEUTIC_AREA: '新しい治療院を作る',
  ADD_NEW: '新規追加',
  SELECT_TEAM_TO_PROCEED: 'チームを選択して進む',
  MIM: 'ミム',
  MA: 'マサチューセッツ州',
  MSL: 'エムエスエル',
  SALES: '売上高',
  CALL_CENTER: 'コールセンター',
  ALL: 'すべての',
  Warning: '警告',
  clear: '澄んだ',
  THESE_CHANGES_WILL_BE_: 'これらの変更は、選択したユーザーに適用されます。',
  THIS_IS_FOLLOW_A_UP_RESPONSE: 'フォローアップ対応です。',
  THIS_IS_A_FOLLOW_UP_: 'これは質問者に送られたフォローアップです。 ',
  CANT_CREATE_A_FOLLOW_UP_REQUEST:
    '選択した問い合わせ者がいないとフォローアップリクエストが作成できない',
  INSERT_A_FOLLOW_UP_RESP: 'フォローアップ対応を挿入する',
  INSERT_A_NOTE: 'メモを挿入する',
  MERGED_FROM: 'からマージされました。',
  CHANGES: '変更点',
  SHOW_LESS: '疎かにする',
  COMPLETE: '完結',
  MISSING: '欠落',
  CLOSE_AS_FORWARDED: 'フォワードされたように閉じる',
  CLOSE_EXT_TEAM: '外部チームに転送された問い合わせを閉じる',
  THE_INQ_BELONG_TO_A_:
    'このお問い合わせは、外部チームが担当する国に属しています。お問い合わせは自動的にこのチームに転送されます。',
  INQ_INFO: 'お問い合わせ先情報',
  RESOLVE: '解決する',
  TERRITORIES: '領土',
  EXTERNAL_COMMUNICATION: 'がいぶつうしん',
  FOR_INQ_COMING_DIRECTLY_:
    'HCPから直接メールで問い合わせがあった場合は、問い合わせがあったことを知らせる通知を自動的に送信します。',
  INTERNAL_COMMUNICATION: '内部連絡',
  SALESREP_RECEIVES_: 'セールスレップは、お問い合わせに対する回答通知のみを受信します。',
  SALESREP_RECEIVES_FULL_ANSWER_:
    'セールスレップは、オンラベルのお問い合わせに完全に回答しています。',
  SALESREP_RECEIVES_ONLY_NOTIF_: 'セールスレップは、お問い合わせに対する回答通知のみを受信します。',
  Name: '名前',
  Module: 'モジュール',
  Function: '機能',
  LAST_RUN: 'ラストラン',
  End: '終り',
  Every: 'すべての',
  Period: '期間',
  Active: 'アクティブ',
  Resolved: '解決済み',
  ERROR_PREVIEW: 'エラープレビュー',
  FAILURE_COUNT: '失敗回数',
  COMPLETED_ON: '完了',
  STARTED_ON: '開始日',
  TASK_NAME: 'タスク名',
  PRODUCT_SPECIFIC: '製品別',
  THERAPEUTIC_AREAS: '治療領域',
  PRODUCT_GROUPS: '製品グループ',
  LICENSING_PARTNER: 'ライセンスパートナー',
  PRODUCT_CODE: '製品コード',
  EXTERNAL_ID: '外部ID',
  OBJECT_TYPE: 'オブジェクトタイプ',
  MIGRATION_NAME: '移行名',
  MIGRATION_ERRORS: '移行エラー',
  ADD_SALES_REP: '営業担当者を追加',
  ASSIGNED_TO_MSL: 'エムエスエルに割り当てられた',
  NOT_ASSIGNED: '割り当てられていない',
  ASSIGNED_TO_OTHER_MSL: '他のMSLにアサイン',
  ASSIGNED_TO_THIS_MSL: 'このMSLに割り当てられた',
  SHOW_ONLY: '表示のみ',
  SALES_REP_LIST: '営業担当者一覧',
  ACTIVE_TASK: 'タスクをアクティブにする',
  CHOOSE_USER_AND_SET_THE_SHIFT_: 'ユーザーを選択し、イベントのシフトを設定します。',
  MANAGE_SHIFTS: 'シフトの管理',
  CREATE_NEW_USER: '新規ユーザーの作成',
  THIS_TEMPLATE_WILL_BE_AUTO_: 'このテンプレートはチームメンバーに自動的に追加されます。',
  TEMPLATE_FOR: 'テンプレート',
  Day: '日',
  Week: '週',
  Month: '月',
  Today: '今日は',
  Archive: 'アーカイブ',
  PARTICIPANTS: '参加者',
  EVENT_MANAGEMENT: 'イベントマネジメント',
  ADD_TASK: 'タスクの追加',
  EDIT_TASK: 'タスクの編集',
  IS_ACTIVE: 'アクティブ',
  Months: '月間',
  Days: '日数',
  Hours: '時間',
  Minutes: '分数',
  HCP_ADMINISTRATION: 'ＨＣＰ管理',
  AUTH_REQ_ADMINISTRATION: '認証要求管理',
  THE_PRODUCT_WILL_BE_HIGHLIGHTED_IN_: '選択された治療領域で製品が強調表示されます。',
  PRODUCT_GROUPS_DEFINE_: '製品グループは、この製品の問い合わせに対応するチームを定義します。',
  USER_ADMINISTRATION: 'ユーザー管理',
  IS_PRODUCT_SPECIFIC: '製品は特定のものですか？',
  USER_TEMPLATES_ADMIN: 'ユーザーテンプレート管理',
  SET_DEFAULT_VALUES: 'デフォルト値を設定する',
  SET_DEFAULT_VALUES_FOR_: 'イベントのデフォルト値を設定する',
  INQ_DEFAULT_VAL: '問い合わせ者のデフォルト値',
  INQUIRY_DEFAULT_VAL: 'お問い合わせのデフォルト値',
  CREATE_NEW_EVENT: '新規イベントを作成する',
  EDIT_EVENT: 'エディット イベント',
  SET_DETAILS_TO_CREATE_NEW_EVENT: '新規イベントを作成するための詳細設定',
  ADD_DOC_LINKED_TO_: 'イベントにリンクされたドキュメントの追加',
  INC_CHANNEL: '株式会社エヌ・ティ・ティ・ドコモ チャンネル',
  CHANGE_DETAILS_OF_THE_EVENT: 'イベントの詳細を変更する',
  DESCRIPTION: '説明',
  CREATE_APPROVAL_PROCESS: '承認プロセスの選択',
  CREATE_NEW: '新規作成',
  CUSTOMIZE_PROCESS: 'カスタマイズプロセス',
  SELECTED_PROCESS: '選択されたプロセス',
  CLONE: 'クローン',
  PREFERENCES_TITLE: '設定',
  NOTIFICATIONS_TITLE: 'お知らせ',
  TEMPLATES: 'テンプレート',
  LETTER_TEMPLATES: 'レターテンプレート',
  USER_TEMPLATES: 'ユーザーテンプレート',
  TEAM_MEMBERS: '隊員',
  NEW_PASSWORD: '新しいパスワード',
  REPEAT_NEW_PASSWORD: '新しいパスワードを繰り返す',
  AT_LEAST_1_LOWERCASE_CHARACTER: '小文字1文字以上',
  AT_LEAST_1_UPPERCASE_CHARACTER: '大文字1文字以上',
  AT_LEAST_1_DIGIT: '1桁以上',
  AT_LEAST_1_SPECIAL_CHARACTER: '1つ以上の特別なキャラクター',
  MINIMUM_8_CHARACTERS: '最小8文字',
  SHOULD_NOT_CONTAIN_EMAIL_OR_NAMES: '電子メールや名前が含まれていないこと',
  SHOULD_MATCH_PASSWORD: 'パスワードと一致する必要があります',
  USER_MANUAL: 'ユーザーマニュアル',
  SUPPORT: 'サポート',
  BACK_TO_LOGIN: 'ログインに戻る',
  PASSWORD_CONFIRMATION: 'パスワードの確認',
  HAVE_AN_ACCOUNT: 'すでにアカウントをお持ちですか？',
  Submit: '参加する',
  Gender: '性別',
  PROFESSION: '職業',
  MEDICAL_INQ_ROLES: '医療問い合わせ処理の役割',
  MEDICAL_INFO_MANAGER: '医療情報マネージャー',
  MEDICAL_SCIENCE_LIAISON: '医療科学リエゾン',
  NOT_PROCESSING_INQUIRIES: '問い合わせを処理していません',
  ACTIVE_HEALTH_CARE_SPEC: 'アクティブヘルスケアスペシャリスト',
  NOT_ACTIVE_HEALTH_CARE_SPEC: 'アクティブヘルスケアスペシャリストではない',
  PASS_IS_REQUIRED: 'パスワードが必要です！',
  EMAIL_IS_REQUIRED: 'メールアドレスが必要です！',
  VALID_EMAIL: '有効なメールアドレスを入力してください！',
  FIELD_IS_REQUIRED: 'この項目は必須です！',
  ONLY_LETTERS: '文字のみ入力してください！',
  ARE_YOU_daiichi_EMPLOYEE: 'あなたは第一三共の従業員ですか？',
  here: 'ここに',
  CREATE_AN_ACCOUNT: 'アカウントを作成する',
  FORGOT: '忘れましたか？',
  Medical: '医療',
  Medical_not_resp: '医療-MIRに対応する責任はありません',
  Commercial: '商業の',
  Market_access: '市場参入',
  Pharmacovigilance: 'ファーマコビジランス',
  PR: 'PR',
  Please_specify: 'ご指定ください',
  Medical_information_manager: '医療情報マネージャー',
  Medical_advisor: 'メディカルアドバイザー',
  Are_Medical_inquiries: 'あなたは医学的質問に答える責任がありますか？',
  Are_Health_care_professional: 'あなたは医療従事者ですか？',
  Health_care_Industry: 'ヘルスケア業界',
  Health_Authority: '保健当局',
  Journalist: 'ジャーナリスト',
  Lawyer: '弁護士',
  Patient: '患者',
  Payor: '支払人',
  Scientist: '科学者',
  Angiologist: '血管科医',
  Cardiologist: '心臓専門医',
  Cardiovascular_Surgeon: '心臓血管外科医',
  Dentist: '歯科医',
  Gastroenterologist: '消化器専門医',
  General_Practitioner: '一般開業医',
  Geriatrician: '老年病専門医',
  Gynecologist: '婦人科医',
  Haematologist: '血液学者',
  Internist: '内科医',
  Medical_Student: '医学生',
  Nephrologist: '腎臓専門医',
  Neurologist: '神経科医',
  Neurosurgeon: '脳神経外科医',
  Nurse: 'ナース',
  Oncologist: '腫瘍医',
  Oncology_Nurse: '腫瘍学看護師',
  Other_HCP: 'その他のHCP',
  Other_Surgeon: 'その他の外科医',
  Pediatrician: '小児科医',
  Pharmacist: '薬剤師',
  Pharmacologist: '薬理学者',
  Pharmacy_Technician: '薬局の技術者',
  Pneumologist: '肺科医',
  Radiologist: '放射線科医',
  Rheumatologist: 'リウマチ専門医',
  USER_PASS_INCORRECT: 'ユーザー名かパスワードが間違っています',
  SUBMITTED_REQUEST: 'リクエストは正常に送信されました。',
  Reset: 'リセット',
  Reset_pass: 'パスワードを再設定する',
  New_pass: '新しいパスワード',
  RESET_PASS_EMAIL: 'パスワード再設定リンクがメールに送信されました',
  NEW_PASS_SUCCESS: 'パスワードは正常にリセットされました',
  SOMETHING_WENT_WRONG: 'エラーが発生しました。サポートにお問い合わせください。',
  REGISTRATION: '登録',
  Cardiovascular: '心血管',
  Oncology: '腫瘍学',
  Due_On: '期限',
  Follow_Up_Sent_On: 'フォローアップ送信済み',
  Follow_Up_Received_On: 'フォローアップを受け取りました',
  Closed_On: '休業',
  Reopened_On: '再開',
  Inquiry_ID: 'お問い合わせID',
  Search: '探す',
  Assigned_to_Now: '（現在）に割り当てられています',
  Edited_By: 'によって編集',
  Assigned_to_Any_Time: '割り当てられた（いつでも）',
  Closed: '閉まっている',
  Foreign: '外国人',
  Complex_search_Sentence_: '複雑な検索：文：「検索する文」、Not：-word',
  Search_inquiries: 'お問い合わせを検索',
  MIR_INBOX: 'ミールインボックス',
  Filters: 'フィルター',
  Active_Filters: 'アクティブフィルター',
  EVENTS: 'イベント',
  Login_here: 'ここでログイン',
  Create_an_account: 'アカウントを作成する',
  NO_RECORDS: '記録なし',
  INTERACTION: 'インタラクション',
  SPECIALIZATION: '専門',
  SPECIALIZATION_TYPE: '専門分野タイプ',
  SAVED: '保存しました',
  SAVING: '保存中',
  TYPING: 'タイピング',
  CREATE_NEW_INTERACTION: '新しいインタラクションを作成する',
  FILTER_BY: 'フィルター',
  SEND_EMAIL: 'メールを送る',
  SAVE_WITHOUT_EMAIL: 'メールなしで保存',
  SUBMIT_TO_INBOX: '受信トレイに送信',
  SELECT_INQUIRER: '照会者を選択',
  ADR_OBLIGATION_CONSENT_AND_INQUIRER_:
    'インタラクションを送信するには、ADR義務の同意と照会者を選択する必要があります',
  Create_my_acc: 'アカウントを作る',
  NEW_QUESTION: '新しい質問',
  LABEL: 'ラベル',
  SELECT_A_LABEL: 'ラベルを選択',
  CITY: '市',
  SUGGEST_A_NEW_CATEGORY_OR_SELECT_: '新しいカテゴリを提案するか、既存のカテゴリを選択してください',
  SUGGEST_CATEGORY_OR_TOPIC: '新しいカテゴリまたはトピックを提案する',
  URGENT: '緊急',
  SAVE_INQUIRER: '照会者を保存',
  EXISTING_CATEGORY: '既存のカテゴリー',
  NEW_CATEGORY_NAME: '新しいカテゴリ名',
  NEW_TOPIC_NAME: '新しいトピック名',
  CATEGORY: 'カテゴリー',
  MSLA: 'MSLA',
  OUT_CHANNEL: '発信チャンネル',
  LOGIN_TO_CONTINUE: 'ログインしてMadjentaに進む',
  Email_address: '電子メールアドレス',
  FORGOT_PASS: 'パスワードをお忘れですか？',
  Remember_me: '私を覚えてますか',
  YOU_WILL_BE_NOTIFIED: '登録の詳細な手順が記載された確認メールが送信されました',
  SUCCESS_EMAIL_CONFIRMATION: 'あなたの電子メールは正常に確認されました。',
  SUCCESS_EMAIL_CONFIRMATION_SUB: 'リクエストが確認されると通知されます',
  FAILED_EMAIL_CONFIRMATION: 'メールを確認できません。',
  FAILED_EMAIL_CONFIRMATION_SUB:
    '再試行してください。問題が解決しない場合は、問題の説明を添えてサポートにご連絡ください。',
  RESET_FILTERS: 'フィルターをリセット',
  NEW_PASS_RESET_SUCCESS: 'パスワードのリセットに成功しました！',
  SEARCH_REPOSITORY: 'リポジトリを検索',
  CONTACT_SUPPORT: 'サポート問い合わせ先',
  MedicalInformationRequests: 'お問い合わせ（pdf）',
  UserProfileNavigation: 'ユーザープロファイル（pdf）',
  UserManualChanges: 'ユーザーマニュアルの変更（docx）',
  iPad_How_To: 'iPad（pdf）',
  MedIS_Assign_Inquiry: 'お問い合わせ',
  MedIS_Documentation_Approval_Process: '文書承認プロセス',
  MedIS_Documentation_Overview: 'ドキュメントの概要',
  MedIS_Inquiry_Documentation: 'お問い合わせドキュメント',
  MedIS_MIR_Inbox_Filters: 'MIR受信フィルター',
  MedIS_Merge_MIR: 'MIRをマージ',
  MedIS_New_Documentation: '新しいドキュメント',
  MedIS_New_Inquiry: '新しいお問い合わせ',
  MedIS_Out_Of_Office: 'オフィスの外',
  MedIS_Prepare_Custom_Answer: 'カスタム回答を準備する',
  MedIS_Split_MIR_Part_I: 'スプリットMIRパートI',
  MedIS_Split_MIR_Part_II: 'スプリットMIRパートII',
  I_AGREE_TO: '同意する',
  TermsAndConditionsLink: '規約と条件',
  Title: '題名',
  INTERACTION_TITLE: 'インタラクションタイトル',
  Move: '動く',
  Left: '左',
  Right: '正しい',
  Requestor: '依頼者',
  Assigned_To: 'に割り当てられた',
  Status: '状態',
  Note: '注意',
  Confirm: '確認',
  rejectConfirm: 'リクエストを拒否してもよろしいですか？',
  approveConfirm: 'リクエストを承認してもよろしいですか？',
  newUser: '新しいユーザー',
  editUser: 'ユーザーを編集',
  deleteUser: 'ユーザーを削除',
  newProduct: '新製品',
  editProduct: '製品を編集',
  deleteProduct: '製品を削除',
  newCategory: '新たなカテゴリー',
  editCategory: 'カテゴリを編集',
  deleteCategory: 'カテゴリを削除',
  newTopic: '新しい話題',
  editTopic: 'トピックを編集',
  deleteTopic: 'トピックを削除',
  userRequest: 'ユーザーリクエスト',
  productRequest: '製品リクエスト',
  categoryRequest: 'カテゴリーリクエスト',
  categoryTopicRequest: 'カテゴリー/トピックリクエスト',
  topicRequest: 'トピックのリクエスト',
  Suggestion: '提案',
  tradeName: '商標名',
  activeSubstance: '活性物質',
  productName: '商品名',
  productSpecific: '製品固有ですか？',
  EMAIL_IN_USE: 'このメールには既存のアカウントがあります',
  fromDate: '開始日',
  toDate: '現在まで',
  applyFilter: 'フィルターを適用',
  requestReceivedFrom: 'から受け取った要求',
  on: 'オン',
  at: 'で',
  to: 'に',
  add: '追加',
  from: 'から',
  approve: '承認する',
  reject: '拒否する',
  withTheFollowingRole: '次の役割を持つ',
  forTheFollowingTeam: '次のチームのために',
  request: 'リクエスト',
  submittedBy: 'から提出された',
  INVALID_OR_EXPIRED_RESET_REQUEST: 'パスワードリセットリクエストが無効または期限切れです',
  PASS_IS_RECENT: 'パスワードが最近使用されました',
  Assigned_to_me: '私に割り当てられた',
  My_drafts: '私の下書き',
  My_open_inquiries: '私のオープンな問い合わせ',
  My_closed_inquiries: '閉じた問い合わせ',
  Filter_inquiries_by: 'で問い合わせをフィルター',
  New_inq: '新しいお問い合わせ',
  Inq_inbox: 'お問い合わせ',
  REPORTS: '報告書',
  My_teams_open_inq: '私のチームのオープンな問い合わせ',
  My_teams_closed_inq: '私のチームのクローズドな問い合わせ',
  All_teams_open_inq: 'すべてのチームが問い合わせをオープン',
  All_teams_closed_inq: 'すべてのチームが問い合わせを締め切りました',
  Overdue: '期限切れ',
  Due_this_week: '今週まで',
  Pending_approval: '承認待ちの',
  ADMINISTRATION: '行政',
  Show_all: 'すべて表示する',
  In_progress: '進行中',
  Table_Settings_Columns: 'テーブル設定-列',
  Change_column_visibility: '列の表示設定を変更する',
  Reassign_to: 'に再割り当て',
  set_priority: '優先度を設定',
  set_status: 'ステータスを設定',
  Spam_list: 'スパムリスト',
  Institution: '機関',
  DUE_ON: '期限',
  Profile_settings: 'プロファイル設定',
  Preferences: '環境設定',
  Role_settings: 'ロール設定',
  Notification_settings: '通知設定',
  User_profile: 'ユーザープロフィール',
  Select_person: '人を選択',
  FROM_DATE: '日付から',
  TO_DATE: '現在まで',
  JOB_DESC: '仕事内容',
  INQ_Management: '問い合わせ管理',
  DOCS_Management: '文書管理',
  USER_Management: 'ユーザー管理',
  Define_Frequency: '頻度を定義',
  All_teams_all_inq: '全チームのすべてのお問い合わせ',
  My_teams_all_inq: '私のチームへのお問い合わせ',
  Search_people_here: 'ここで人を検索',
  Search_team_here: 'ここでチームを検索',
  people: '人',
  teams: 'チーム',
  empty: '（空の）',
  can_not_be_assigned: 'このユーザーにはチームがなく、割り当てられません',
  select_a_team: 'チームを選択してください',
  confirm_team_change: 'チームの変更を確認',
  change_the_team_: 'チームを次のように変更してもよろしいですか',
  can_not_see_it_anymore: 'あなたは現在それを見ることができなくなります',
  filtered_view: 'フィルターされたビュー。',
  PHONE: '電話',
  FAX: 'ファックス',
  LETTER: '文字',
  F2F_SITE_VISIT: 'F2F（サイト訪問）',
  F2F_CONGRESS: 'F2F（議会）',
  F2F_ADBOARD: 'F2F（AdBoard）',
  F2F_OTHER: 'F2F（その他）',
  WEBINAR_SITE_VISIT: '仮想（サイト訪問）',
  WEBINAR_CONGRESS: 'バーチャル（議会）',
  WEBINAR_ADBOARD: '仮想（AdBoard）',
  WEBINAR_OTHER: 'バーチャル（その他）',
  CHAT: 'チャット',
  COURIER: '宅配便',
  REP_DELIVERY: '担当者配達',
  SYSTEM: 'システム',
  WEB_FORM: 'ウェブフォーム',
  Signature: '署名',
  Disclaimer: '免責事項',
  STATE: '状態',
  ADDRESS_1: '住所（1',
  ADDRESS_2: 'アドレス2',
  ZIP_CODE: '郵便番号',
  HIGH: '高い',
  ADVANCED: '高度な',
  CLICK_BELOW_TO_POPULATE_ANSWER:
    '以下のテキストとフィールドをクリックして、回答を入力および編集します',
  DOCUMENT_VIEW: 'ドキュメントビュー',
  FORM_VIEW: 'フォームビュー',
  YOU_DONT_HAVE_RIGHTS_TO_ANSWER: '回答を送信する権限がありません。',
  ANSWER_TEXT: '回答文',
  CONTACT_INFORMATION: '連絡先',
  COMPANY: '会社',
  SRD_NEEDED_TOOLTIP: 'この質問の回答には新しいSRDドキュメントが必要です',
  ENABLE_ALL: '全て可能にする',
  DISABLE_ALL: 'すべて無効にする',
  BEFORE: '前',
  AFTER: '後',
  EDIT: '編集する',
  MY_TEMPLATES: 'マイテンプレート',
  TEAM_TEMPLATES: 'チームテンプレート',
  TEAM_TEMPLATES_HINT: 'チーム全体のMedical Information Managerによって作成されたテンプレート',
  SOURCE: 'ソース',
  TEMPLATE_NO_MATCH_INTERACTION:
    'このテンプレートはインタラクションの詳細と一致せず、適用されません',
  EMAIL_SUBJECT: 'メールの件名',
  INQUIRY_SUBMIT_HINT: 'お問い合わせに回答するために、すべての必須フィールドに入力してください',
  SCHEDULED_PUBLISH_TO_EXP_DATE: '有効期限までの発行予定',
  COPY_ATTACHMENT_TO_ANSWER: '添付ファイルをコピーして回答する',
  COPY_TEXT_TO_ANSWER: '回答するテキストをコピーする',
  SELECT_INQUIRY: 'お問い合わせを選択',
  EDIT_INQ: '質問の編集',
  SPLIT_ANOTHER: '別の分割',
  DELETE_INQ: 'お問い合わせを削除',
  SELECT_TEXT_FOR_SPLIT: '同じインタラクションで新しい質問を追加する',
  SELECT_TEXT_FOR_SPLIT_NEW_INTERACTION: '個別のインタラクション/ケースを生成する',
  SPLIT_ORIGINAL_TEXT: '元のテキスト（分割するテキストを選択）',
  SPLIT_TEXT_TO_REMAIN_: '最初の問い合わせに残るテキスト',
  SPLIT_TEXT_TO_SPLIT_: '分割するテキスト',
  SPLIT_SELECTED_SECTIONS: '分割するために選択されたセクション',
  SPLIT_NO_MODIFICATIONS_HINT:
    '元のテキストからセクションを選択すると、テキストが自動的に入力されます',
  SPLIT_MODIFICATIONS_HINT:
    'テキストは手動で変更されており、元のテキストからのそれ以上の選択は適用されません。\n 変更を破棄するには、ここをクリックしてください。',
  TEMPLATE_NO_MODIFICATIONS_HINT: 'お問い合わせ内容の変更により、テキストは自動的に更新されます。',
  SELECT_DIFFERENT_TEMPLATE: '別のテンプレートを選択してください',
  APPROVE: '承認する',
  LAST_EDIT_ON: '最終編集日',
  LAST_EDIT_BY: '最終編集者',
  INTERNAL_COMM: '内部のコミュニケーション',
  INACTIVE: '非活性',
  DEACTIVATE: '非アクティブ化',
  ARCHIVED: 'アーカイブ',
  REPUBLISH: '再公開',
  INBOX_NO_FILTERS_HINT:
    'フィルタが選択されていません。\n 探しているお問い合わせが見つからない場合は、正しい受信トレイセクションにいることを確認してください。',
  INTERACTION_TEMPLATE_HINT:
    '署名、挨拶、免責事項は、電子メール、手紙、ファックスなどの書面によるコミュニケーションの一部です。\n それらを使用するには、発信チャネルがそれらの1つに設定されていることを確認してください。',
  CONFIRM_AUTH_REQUEST: '認証リクエストの確認',
  VIEW_AUTH_REQUEST: '認証リクエストを表示',
  QUESTION_TITLE: '質問のタイトル：',
  NO_TITLE_INQUIRY: '-no-title-enquiry-',
  EXISTING_CATEGORIES: '既存のカテゴリ',
  EXISTING_TOPICS: '既存のトピック',
  DO_NOT_ASSIGN_PRODUCT: '製品を割り当てないでください',
  QUESTION_TEXT: '質問テキスト',
  DATE: '日付',
  REJECTED: '拒否されました',
  FILTER_AUTH_REQUESTS_BY: '認証リクエストをフィルタリングする',
  ALL_REQUESTS_ALL_TEAMS: 'すべてのチームのすべてのリクエスト',
  ALL_TEAMS: 'すべてのチーム',
  YOU_DONT_HAVE_RIGHTS_TO_EDIT_REQUEST: 'カテゴリ/トピックリクエストを編集する権限がありません。',
  SEND_FOLLOW_UP: 'フォローアップの送信',
  RECEIPT_NOTIFICATION_CUSTOMER: '顧客への受領通知',
  GDPR_DISCLAIMER: 'GDPRの免責事項',
  ACTIONS: '行動',
  SUBMISSION_CONDITIONS: '提出条件',
  TYPE_OF_NOTIFICATION: '通知の種類',
  DIRECT_SUBMISSION: '直接提出',
  DIRECT_SUBMISSION_TOOLTIP:
    'お客様から直接メールでお問い合わせいただいた場合は、お客様に個人情報の取り扱いについてお知らせします。',
  COMPANY_EMPLOYEE_SUBMISSION: '会社員経由で提出',
  COMPANY_EMPLOYEE_SUBMISSION_TOOLTIP:
    '会社の代表者（営業担当者、MSL、その他）から提出された問い合わせについては、個人データの取り扱いについて問い合わせ者に通知してください。',
  ENABLE_NOTIFICATION: '通知を有効にします',
  NOTIFICATION_SENDER: '送信者への通知',
  SALES_REP_DELIVERED_RESPONSE: '配信された応答の営業担当者',
  NOTIFICATION_DELIVERED_RESPONSE: '配信された応答の通知',
  NOTIFICATION_DELIVERED_RESPONSE_TOOLTIP:
    '会社の代表者（営業担当者、MSL、またはその他）を介して送信された問い合わせの場合、顧客が問い合わせに対する回答を受け取ったときに送信者に通知します',
  FULL_ANSWER: '完全な答え',
  NOTIFICATION: '通知',
  NAME: '名前',
  COLUMN_INIT_QUESTION_TEXT: '問題文の原文',
  NOTIFICATION_ENABLED: '通知が有効',
  DEFAULT_TEMPLATE: 'デフォルトのテンプレート',
  SET_AS_DEFAULT: 'デフォルトとして設定',
  DEFAULT_TEMPLATE_TOOLTIP:
    '少なくとも1つのデフォルトテンプレートが必要なため、トグルは無効になっています。',
  DEFAULT_TEMPLATE_TOOLTIP_DELETION_NOT_POSSIBLE: 'デフォルトのテンプレートは削除できません！',
  BATCH_UPDATE_ONLY: '一番上のチェックボックスからのみバッチ更新が可能です。',
  ACCESS_DENIED: 'アクセス拒否！',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE: 'このページにアクセスする権限がありません。',
  USER_ROLES: 'ユーザーの役割',
  MANAGE_USER_ROLES: 'ユーザーの役割を管理する',
  ARCHIVE_ROLE: 'アーカイブの役割',
  ROLE_ARCHIVE_CONFIRMATION: 'この役割を本当にアーカイブしますか？',
  ROLES_FOR: 'の役割',
  AUDIT_TRAIL: '監査証跡',
  ACTION_BY_USER: 'ユーザーによるアクション',
  ACTION: 'アクション',
  ORIGINAL_VALUE: '元の値',
  CHANGE: '変更',
  CONDITION: '状態',
  IP: 'IP',
  GEOLOCATION: '「ジオロケーション」',
  NEW_VALUE: '新しい値',
  FILTER_BY_ACTION: 'アクションでフィルタリング',
  SELECT_DATE: '日付を選択',
  TEAM_SAVE: '保存',
  TEAM_DELETE: '消去',
  TEAM_CREATE: '作成',
  TEAM_CLONE: 'コピー',
  TEAM_NAME: '名前',
  TEAM_CODE: 'コード',
  TEAM_ACTION_DELETE_CONFIRM: '本当に削除したいですか',
  TEAM_ACTION_DELETE_CONFIRM_TITLE: '気をつけて！',
  TEAM_ACTION_DELETE_CONFIRM_EXTENDED: '削除すると、復元できません！',
  TEAM_FULL_ANSWER_ON_LABEL: 'ラベルの完全な回答',
  TEAM_RESTRICTED_TEAM: '制限付き',
  TEAM_NOTIFY_INQUIRER: 'Inquirerに通知します',
  TEAM_SEGREGATED_DATA: '分離データ',
  TEAM_PRIVATE_TEAM: 'プライベートチーム',
  TEAM_AUTO_ASSIGN: '自動割り当て',
  TEAM_MAILBOX_IN: 'インバウンドメールボックス',
  TEAM_MAILBOX_OUT: 'アウトバウンドメールボックス',
  TEAM_DEFAULT_LANGUAGE: '既定の言語',
  TEAM_COUNTRIES: '割り当てられた国',
  TEAM_PRODUCT_GROUPS: '製品グループを割り当てます',
  TEAM_PARENT: '親チーム',
  TEAM_OVERRIDE_CLUSTER_ROOT: 'クラスタールートチームをオーバーライドします',
  TEAM_AFFILIATIONS: '関連チーム',
  TEAM_TIMEZONE: 'タイムゾーン',
  INQUIRER_TYPE: 'Inquirerタイプ',
  UPDATE: 'アップデート',
  AUTO_ASSIGN_BY: 'auto assign',
  AUTO_ASSIGNMENTS: '自動割り当て',
  NO_ONE: '誰も',
  NOT_APPLICABLE: '適用できない',
  OVERRIDE_NOTIFICATIONS: '通知をオーバーライドします',
  OVERRIDE_NOTIFICATIONS_HINT:
    'オーバーライド通知を備えたユーザーテンプレートがユーザーに設定されると、既存の通知設定を無効にし、ユーザーテンプレートにリストされている通知設定のみを有効にします',
  CREATED_MANUALLY: '手動で作成されます',
  DOCUMENT_TITLE_FOR_WORD:
    'Wordドキュメントのタイトルは、通常、ドキュメント自体内のタイトルフォーマットされたセクションから取得されます。 ドキュメントにタイトルが含まれていない場合は、ここで指定できます。 ここでタイトルを指定していない場合、Word Documentにタイトルフォーマットされたセクションが含まれていない場合、ドキュメントIDはタイトルに使用されます。',
  ORDER_BY: '注文',
  FORMAT: 'フォーマット',
  SELECT_QUESTION: '質問を選択してください',
  SELECT_QUESTION_FOR_NOTE: 'メモを追加する質問を選択してください',
  ADD_NOTE_FOR_WHOLE_ENQUIRY: 'お問い合わせ全体にメモを追加します',
  REQUESTED_DATE: '依頼日',
  UNASSIGNED: '割り当てられていない',
  BLANK: '空欄',
  ANSWER_SAVED_WITHOUT_EMAIL: '電子メールなしで保存された回答',
  CHANGE_INQUIRER: 'Inquirerを変更します',
  INQ_RELATED_TO: 'に関連する問い合わせ',
  UPDATED_INQUIRER_DETAILS: 'Inquirerの詳細を更新しました',
  CREATED_INQUIRER: 'Inquirerを作成しました',
  QUESTION_TEXT_BEFORE_SPLIT: '分割前の質問テキスト',
  SPLIT_MERGE: '分割してマージします',
  CLARIFICATION_QUESTION: '明確化の質問',
  ACTIVE_ON: 'アクティブ',
  DISMISS: '解散',
  GO_TO_ENQUIRY: 'お問い合わせに進みます',
  NO_TEAMS: 'チームなし',
  NO_TEAMS_TIP:
    'どのチームにも割り当てられていないユーザーが含まれています。 それらのいくつかがあなたのチームからであるかどうかを確認し、適切な役割を認めることができます。',
  TASKS: 'タスク',
  Therapeutic_Areas: '治療領域',
  Product_Groups: '製品グループ',
  Licensing_Partner: 'ライセンスパートナー',
  Product_Code: '製品コード',
  SELECT_EXISTING_ONE: '既存のものを選択します',
  OR: 'また',
  MANAGE_FORM: 'フォームを管理します',
  EVENT_FORM_ADDRESS: 'イベントフォームアドレス',
  EVENT_FORM_PREVIEW_WARING:
    '議会のフォームを開くか、その住所をコピーする前に、変更を保存したことを確認してください',
  SEARCH_SAVED_SEARCHES: '保存された検索を検索します',
  GO: '行け',
  ASSIGN: '割当',
  CERTIFICATION: '認証',
  SORT: '選別',
  CREATE_NEW_MAILBOX: '新しいメールボックスを作成します',
  EDIT_MAILBOX: 'メールボックスを編集します',
  ADD_MAILBOX: 'メールボックスを追加します',
  UNSET_INCOMING_MAILBOX: '着信メールボックスを設定します',
  SET_INCOMING_MAILBOX: '着信メールボックスを設定します',
  UNSET_OUTGOING_MAILBOX: '外出するメールボックスを設定します',
  SET_OUTGOING_MAILBOX: '送信メールボックスを設定します',
  ALL_TEAMS_WITH_CURRENT_MAILBOX: '現在のメールボックスを備えたすべてのチーム',
  MAILBOX_HISTORY: 'メールボックスの履歴',
  ALL_TEAMS_WITH_MAILBOX: 'メールボックスを備えたすべてのチーム',
  MAILBOX: 'メールボックス',
  EMAIL_POLLING: '電子メール投票',
  ADVANCED_SEARCH: '高度な検索',
  SELECT_FIELDS: 'フィールドを選択します',
  SEARCH_TERM: '検索語',
  COLUMN_INTERACTION_ID: '相互作用ID',
  COLUMN_QUESTION_ID: '質問ID',
  COLUMN_CREATED_ON: 'に作成されました',
  COLUMN_LAST_EDITED_DATE: '最後に編集',
  COLUMN_ASSIGNED_TO: '割りあてる',
  COLUMN_DUE_DATE: '期日',
  COLUMN_FOLLOW_SENT_DATE: 'フォローアップ送信日',
  COLUMN_FOLLOW_RECEIVED_DATE: 'フォローアップ受信日',
  COLUMN_ANSWER_DATE: '回答日',
  COLUMN_CLOSED_ON_DATE: '日付が閉じられました',
  COLUMN_REOPENED_DATE: '再オープン日',
  COLUMN_INSERTED_BY: '挿入',
  COLUMN_LAST_EDITED_BY: '最後に編集',
  COLUMN_ANSWERED_BY: '応答',
  COLUMN_APPROVED_BY: 'によって承認された',
  COLUMN_INT_COMM_USER: '内部通信ユーザー',
  COLUMN_INT_COMM_DATE: '内部通信日',
  COLUMN_MY_TEAM: '私のチーム',
  COLUMN_PQC_RELATED: 'PQC関連',
  COLUMN_INCOMING_CHANNEL: '着信チャネル',
  COLUMN_OUTGOING_CHANNEL: '発信チャネル',
  COLUMN_OFF_LABEL: '未公認',
  COLUMN_HCP_SPEC: 'HCP +専門化',
  COLUMN_NON_HCP_SPEC: '非HCP +職業',
  COLUMN_KEE: 'キー',
  COLUMN_HOSPITAL_BASED: '病院ベース',
  COLUMN_CITY: '街',
  COLUMN_INSTITUTION: '機関',
  COLUMN_TERRITORY: '地域',
  CASE_ID: 'ケースID',
  OPTION_ADD: '追加',
  OPTION_AND: 'と',
  OPTION_OR: 'また',
  OPTION_NOT: 'いいえ',
  OPTION_YES: 'はい',
  OPTION_NO: 'いいえ',
  QUERY: 'クエリ',
  EXPORT_LABEL: 'エクスポート列を定義します',
  SAVE_SEARCH: '検索を保存します',
  SHARE_SEARCH: '検索を共有します',
  SEARCH_BTN: '探す',
  BACK_ACTION: '戻る',
  SAVE_CHANGES: '変更内容を保存',
  SAVED_SEARCH: '保存された検索',
  NEW: '新しい',
  SAVE_AS: '名前として保存します',
  ENTER_RECIPIENTS: '受信者を入力します',
  CLEAR_SAVED_SEARCH: '保存された検索をクリアします',
  NO_SAVED_SEARCH: '保存された検索は見つかりませんでした',
  INT_COMM_TEXT: '内部通信テキスト',
  FOLLOW_UP_TEXT: 'フォローアップテキスト',
  CONFIRM_DELETION: '削除を確認します',
  ARE_SURE: '保存された検索を削除したいですか',
  PERMANENTLY_DELETED: '検索は永続的に<b>削除されます</b>。',
  NOTHING_SAVE: '保存するものは何もありません。',
  NOTHING_SEARCH: '検索するものは何もありません。',
  NOTHING_EXPORT: '検索にはエクスポートの記録はありません。',
  SHARE_SUCCESS: '検索は正常に共有されています',
  SHARE_FAILURE: '検索は共有できませんでした',
  DELETED_SUCCESS: '保存された検索を正常に削除しました',
  ALL_DELETED_SUCCESS: '保存されたすべての検索を正常に削除しました',
  VIEW_SAVED_SEARCH: '保存された検索を表示します',
  EDIT_SAVED_SEARCH: '保存された検索を編集します',
  EXPORT_SAVED_SEARCH: '保存された検索をエクスポートします',
  EXPORT_SEARCH: 'エクスポート検索',
  DELETE_SAVED_SEARCH: '保存された検索を削除します',
  SELECT_EXPORT_COLUMNS: '列を選択してエクスポートします',
  SAVED_SEARCH_ALREADY_EXIST: 'その名前で検索はすでに存在します。',
  CHARACTER_LIMIT: '最小3文字。 最大35文字。',
  CHARACTER_LEFT: '文字が残っています',
  ACTIVATE: '活性化',
  INACTIVE_USER:
    'このユーザーはもはやアクティブではありません。 システム管理者に連絡してください。',
  FOLLOW_UP: 'ファローアップ',
  USER_NOT_FOUND: 'ユーザーが見つかりません',
  DEPARTMENTS: '部門',
  EMAIL: 'Eメール',
  ADD_NEW_DEPARTMENT: '新しい部門を追加します',
  DEPARTMENT_COUNTRY: '国',
  DEPARTMENT_COMPANY: '会社',
  DEPARTMENT_TEAM: 'チーム',
  DEPARTMENT_TA: '治療領域',
  DEPARTMENT_PRODUCT: '製品',
  DEPARTMENT_ACTIONS: '行動',
  DEPARTMENT_CONFIRMATION_1: '削除を確認しますか？',
  DEPARTMENT_CONFIRMATION_2: 'このアクションは不可逆的です。',
  DEPARTMENT_NAME: '部署名',
  TYPE_DEPARTMENT: 'タイプ',
  COUNTRY_PLEASE_SELECT_TEAM_FIRST: '国 - 最初にチームを選択してください',
  DEP_NAME_CHARACTER_LIMIT: '最大200文字。',
  AUTH_REQUESTS: {
    TITLE: {
      UserRequest: 'ユーザーリクエスト',
      CategoryTopicRequest: 'カテゴリ/トピックリクエスト',
      ProductRequest: '製品リクエスト',
      UserUpdateRequest: 'ユーザーの更新リクエスト',
    },
    TYPE: {
      NewUser: '新しいユーザー',
      NewCategoryTopic: '新しいカテゴリ/トピック',
      NewUserUpdate: 'ユーザーの更新',
    },
  },
  ADMINISTRATION_SECTION: {
    SPECIALIZATION: {
      TYPE: 'Inquirerタイプ',
      SPECIALIZATION_EXISTS: '「Inquirer Type/Specialization」の構成はすでに存在します。',
      DEFINE_COUNTRY_SPECIFIC_SPECIALIZATIONS: '国固有の専門化リストを定義します',
      IS_COUNTRY_SPECIFIC: '国固有のものですか',
    },
    AUTHORIZATION_REQUESTS: { GENDER: '性別', COUNTRY: '国', TITLE: '題名' },
  },
  hcp: 'HCP',
  emp: '従業員',
  cons: '非HCP',
  OOO_DEPUTY: 'oooo代理',
  NO_DEPUTY: '代理人はいません',
  UNSPECIFIED_END_DATE: '不特定の終了日',
  DELEGATORS: '委任者',
  NO_DELEGATORS: '代表者はいません',
  PREVIEW_DELEGATORS: 'プレビュー委任者',
  REMOVE_DEPUTY: '代理人を削除します',
  USERS_WHO_SET_YOU_AS_DEPUTY: 'あなたを代理として設定したユーザー',
  CHANGED_USER: '変更されたユーザー',
  DEFAULT: 'デフォルト',
  SPECIALIZATIONS: '専門化',
  ENQUIRERS_LIST: 'Enquirersリスト',
  EDIT_THE_RECORD: 'レコードを編集します',
  DEACTIVATE_THE_RECORD: 'レコードを無効にします',
  USER_PROFILE: {
    OUT_OF_OFFICE: {
      OUT_OF_OFFICE: 'オフィスの外',
      HINT: 'お問い合わせ処理の役割を担っているアクティブユーザーのみが、オフィスを廃止されていない選択が可能です。',
      MODAL_TITLE: 'オフィスの設定を確認してください',
      MODAL_MSG_1:
        'あなたはすぐに効果をもたらしてオフィスから出発しました、あなたのすべての問い合わせはすぐに代理に割り当てられます。',
      MODAL_MSG_2:
        'あなたはオフィスからのオプションを設定しました、あなたのすべての問い合わせは、不在時に代理人に割り当てられます。',
    },
  },
  GENDER_MALE: '氏',
  GENDER_FEMALE: '夫人',
  TITLE_DR: '博士',
  TITLE_PHD: '博士号',
  TITLE_PROF: '教授',
  TITLE_PROF_DR: '教授',
  HOME: '家',
  HOSPITAL: '病院',
  OFFICE: 'オフィス',
  WORK: '仕事',
  INSERT_IMAGE: '画像を挿入します',
  USER_ADMIN: 'ユーザー管理者',
  MAILBOXES: 'メールボックス',
  AUTHORIZATION_REQUESTS: '承認リクエスト',
  METADATA: 'メタデータ',
  NOTIFICATIONS_PAGE: {
    TITLES: {
      NEW_INQUIRY: '新しい問い合わせ',
      ASSIGNMENT: '割り当て',
      FORWARD: '前方',
      FOLLOW_UP: 'ファローアップ',
      INQUIRY_APPROVAL: 'お問い合わせの承認',
      DUE_DATE_SETTINGS: '期日設定',
      CLOSE: '近い',
      DELIVERY_ERROR: '配信エラー',
      SCHEDULER: 'スケジューラ',
      USER_REPORTS: 'ユーザーレポート',
      DOCUMENT_APPROVAL: '文書の承認',
      AUTHORIZATION_REQUEST: '承認リクエスト',
    },
    TYPES: {
      ANSWER_1: '調査が私に答えられました',
      ANSWER_2: '私が関わった問い合わせに答えました',
      ASSIGNMENT_1: '問い合わせが私に割り当てられました',
      AUTHORIZATION_REQUEST_1: 'ロールリクエストが承認/拒否されました',
      AUTHORIZATION_REQUEST_3: '役割は私の承認を待っています',
      CLOSE_1: '問い合わせが終了しました',
      DOCUMENT_APPROVAL_1: '文書があなたの承認を待っています',
      DOCUMENT_APPROVAL_2: '文書が承認/拒否されました',
      DOCUMENT_APPROVAL_3: 'ドキュメントがアーカイブされました',
      DOCUMENT_APPROVAL_4: 'ドキュメントに変更が承認されました',
      DOCUMENT_APPROVAL_5: 'ドキュメントがアーカイブされます',
      DOCUMENT_APPROVAL_6: '承認されたドキュメントのリスト',
      DUE_DATE_1: '私が割り当てられている問い合わせは期日近くにあります、私に知らせてください',
      DUE_DATE_2: '過去の期限の後に繰り返します',
      ERROR_1: '配信通知の失敗（例：誤った電子メール）',
      FOLLOW_UP_1: 'フォローアップまたはリマインダーが顧客に送信されました',
      FOLLOW_UP_4: 'フォローアップが受けられました',
      FOLLOW_UP_7: 'フォローアップのために問い合わせが失われました',
      FORWARD_1: '問い合わせが転送されました',
      IMPORT_DATA_1: 'インポート中に新しいオブジェクトが作成されました',
      IMPORT_DATA_2: 'インポート中に新しいInquirerが作成されました',
      IMPORT_DATA_3:
        'Inquirerはすでに存在しているものと一致していましたが、以前の輸入品からではありませんでした',
      IMPORT_DATA_4: 'インポート中に新しいユーザーが作成されました',
      IMPORT_DATA_5:
        'ユーザーはすでに既存のものと一致していましたが、以前のインポートから来ていませんでした',
      IMPORT_DATA_6: '外部ソースからのデータのインポート中にエラーが発生しました',
      INQUIRY_APPROVAL_1: 'お問い合わせがあなたの承認を待っています',
      INQUIRY_APPROVAL_4: 'あなたの提案された回答は、変更で承認 /承認 /拒否されました',
      INTERNAL_1: '割り当てられた問い合わせにメモが追加されました',
      METADATA_MANAGEMENT_1: '新しいメタデータが作成されました',
      NEW_DOCUMENT_1: '私の国では、新しい承認された文書が利用可能です',
      NEW_DOCUMENT_2: '新しいドキュメントは、ローカル承認のために利用できます',
      NEW_DOCUMENT_3: '新しいローカルドキュメントはヨーロッパ /グローバル承認のために利用できます',
      NEW_DOCUMENT_4: '新しいグローバルドキュメントが利用可能です',
      NEW_DOCUMENT_5: '新しいヨーロッパの文書が利用可能です',
      NEW_DOCUMENT_6: '新しいヨーロッパの文書は、グローバルな承認のために利用できます',
      NEW_INQUIRY_1: '私の国では新しい問い合わせが受けられました',
      SCHEDULER_TASK_1: 'タスクの実行中にエラーが発生しました',
      SPAM_1: 'スパムで受け取った新しい問い合わせ',
      USER_REPORTS_1: 'アクティブユーザーのリスト',
      TEXT_GUIDES: {
        ANSWER_2:
          '私が協力者として働いていたが、問い合わせに直接割り当てられていないことに答えられた問い合わせの通知を受け取る',
        AUTHORIZATION_REQUEST_3:
          'ユーザーの役割リクエストを承認/拒否する必要があるときに通知する通知を受信する',
        FOLLOW_UP_4:
          'お問い合わせの通知を受け取ってください私はInquirerからフォローアップ応答を受け取ったものに割り当てられています',
        NEW_INQUIRY_1:
          'お住まいの国で受け取った新しい問い合わせ、Inquirer/ユーザーによって挿入された、または別のチームから転送された通知を受け取る',
        AUTHORIZATION_REQUEST_2:
          '新しいユーザーが作成されたとき、またはチームのユーザーが役割を要求したときに通知を受け取ります',
        AUTHORIZATION_REQUEST_4:
          '新しいトピックおよび/またはカテゴリが提案されたときに通知を受信する',
      },
      ASSIGNMENT_5: 'お問い合わせが私の代理人に割り当てられました',
      FREQUENCIES: {
        INSTANTLY: 'すぐに',
        DAILY: '毎日',
        WEEKLY: '毎週',
        QUARTERLY: '四半期',
        MONTHLY: '毎月',
        SECOND_DAY: '毎日毎日',
        THIRD_DAY: '3日ごとに',
        ONE_WEEK: '一週間',
        ONE_MONTH: '一か月',
        TWO_MONTHS: '2ヶ月',
        THREE_MONTHS: '3ヶ月',
        SIX_MONTHS: '六ヶ月',
        NINE_MONTHS: '9ヶ月',
        EVERY_TWO_WEEKS: '二週ごとに',
        EVERY_THREE_WEEKS: '3週間ごとに',
        EVERY_FOUR_WEEKS: '4週間ごとに',
      },
      OPTIONS: {
        ALMOST_DUE: 'ほぼ期限',
        DUE_NOW: '今すぐです',
        DUE_SOON: 'お早めに',
        EARLY_ON: '早い段階で',
        TEXT_GUIDES: {
          EARLY_ON:
            '期日がある問い合わせの早い段階で通知を受け取ってください：通常の4日間 - 高2日 - クリティカル24時間',
          DUE_SOON:
            'すぐに予定されている問い合わせの通知を受け取ってください：通常の48時間 - 高い12時間 - クリティカル6時間',
          ALMOST_DUE:
            'ほぼ期限のある問い合わせの通知を受け取ってください：通常の24時間 - 高6時間 - クリティカル3時間',
          DUE_NOW: 'お問い合わせの期間がある場合は通知を受け取ります',
        },
      },
      AUTHORIZATION_REQUEST_4: '私のチームでは、カテゴリ/トピックが要求されました',
      AUTHORIZATION_REQUEST_2: 'ユーザーリクエスト',
    },
  },
  NEW_TEAM: '新しいチーム',
  SmPc: 'SMPC',
  USERS: 'ユーザー',
  PAGINATOR_NEXT_PAGE_LABEL: '次のページ',
  PAGINATOR_PREV_PAGE_LABEL: '前のページ',
  PAGINATOR_FIRST_PAGE_LABEL: '先頭ページ',
  PAGINATOR_LAST_PAGE_LABEL: '最後のページ',
  PAGINATOR_ITEMS_PER_PAGE_LABEL: 'ページごとにアイテム',
  Date: '日にち',
  Action: 'アクション',
  'Action by user': 'ユーザーによるアクション',
  'Changed user': '変更されたユーザー',
  'Original value': '元の値',
  'New value': '新しい価値',
  REGION: '領域',
  TEAM_REGION_LABEL: '地域 - 最初に会社を選択してください',
  TEAM_THERAPEUTIC_AREA_LABEL: '治療分野 - 最初に会社を選択してください',
  TEAM_PRODUCT_LABEL: '製品 - 最初に治療エリアを選択してください',
  TEAM_COUNTRIES_LABEL: '国 - 最初に地域を選択してください',
  DEFINE_PRODUCTS_FOR_EACH_COUNTRY: '各国の製品を定義します',
  PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_FOR: '少なくとも1つの製品を選択してください',
  VIEW_CHANGES: '変更を表示します',
  'Changed inquirer': 'Inquirerを変更しました',
  Sales_Representative: '営業担当者',
  Other_Commercial: 'その他のコマーシャル',
  REGIONS: '地域',
  MAIN_CLIENT: 'メインクライアント',
  LICENSE_PARTNER: 'ライセンスパートナー',
  TEAM_SET_UP: 'チームのセットアップ',
  LIST_TEAMS: 'チームをリストします',
  COMPANIES: '会社',
  DOMAINS: 'ドメイン',
  TOKEN_REFRESH_NOTICE: 'ENQMEDアカウント登録の新しい確認メールを要求しました。',
  TOKEN_REFRESH_SUCCESS: '新しい確認リンクがメールに送信されました。',
  TOKEN_REFRESH_ERROR:
    'エラーが発生した。 さらにサポートが必要な場合は、サポートにお問い合わせください。',
  PLEASE_ENTER_MAX: '最大入力してください',
  COMPANY_ACTION_EDIT_CONFIRM_TITLE:
    '以下の変更を確認し、会社を変更する必要があるかどうかを確認してください。',
  COMPANY_ACTION_EDIT_WARNING_MESSAGE: '変更がチームに影響を与えるため、注意してください',
  THERE_ARE_NO_CHANGES: '変更はありません。',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '私たちはすでに同じ価値を持っている会社があるので、ダイアログの背後にあるテーブルにある既存の企業を確認してください。',
  SIMPLE_TEXT:
    '文字（a-z、a-z）、数字（0-9）、および（ダッシュ、ピリオド、スペース、コロン）などの特別な記号のみを入力してください。',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_REGIONS: '地域の少なくとも1つの国を選択してください',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_THERAPEUTIC_AREAS:
    '治療領域の少なくとも1つの製品を選択してください',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_PRODUCTS:
    '複数の治療領域に属する製品/を選択しました。必要なすべての治療領域を選択してください。',
  HTML_CHANGES: 'HTMLの変更',
  SIDE_BY_SIDE_VIEW: '並んで並んでいます',
  DOMAIN_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'ダイアログの背後にあるテーブル内の既存のドメインを確認してください。同じ値のドメインが既にあるためです。',
  OUT_CHANNEL_ERROR_MSG: '発信チャネルレターEnquirerの郵便住所',
  OUT_CHANNEL_ERROR_MSG_LONG:
    'レターとして選択された発信チャネル。 Enquirerエリア内にEnquirerの郵便住所を入力してください',
  LABEL_FOR: 'のラベル',
  male: '男',
  female: '女性',
  ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE:
    'この役割をアーカイブする権利はありません。 チームのMIMS </b>のみが、チームメンバーの役割をアーカイブできます。',
  SEND_EMAIL_UPON_APPROVAL_OR_REJECTION:
    'リクエストに応じて承認/拒否に応じてユーザーにメールを送信する',
  APPROVE_REQUEST_QUESTION: 'リクエストを承認しますか？',
  NEW_USER_REQUEST: '新しいユーザーリクエスト',
  OPEN_REQUESTS: 'オープンリクエスト',
  APPROVED_REQUESTS: '承認されたリクエスト',
  REJECTED_REQUESTS: 'リクエストを拒否しました',
  USER_REQUESTS: 'ユーザーリクエスト',
  REQUEST_FOR: 'のリクエスト',
  CATEGORY_TOPIC_REQUESTS: 'カテゴリ/トピックリクエスト',
  WITH_THE_FOLLOWING_PAIRS: '次のペアで：',
  MY_TEAM_OPEN_REQUESTS: 'オープンリクエスト',
  MY_TEAM_APPROVED_REQUESTS: '承認されたリクエスト',
  MY_TEAM_REJECTED_REQUESTS: 'リクエストを拒否しました',
  DOCUMENT_APPROVAL_WITHIN_ENQMED: 'ENQMED内のドキュメント承認',
  DOCUMENTS_APPROVAL: '文書の承認',
  DOCUMENTS_APPROVAL_TITLE: '選択したチームのドキュメント承認プロセスの設定',
  OUT_CHANNEL_FAX_ERROR_MSG_LONG:
    'ファックスとして選択された発信チャネル。 Enquirerエリア内にEnquirerのファックス番号を入力してください',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG_LONG:
    '電話として選択された発信チャネル。 Enquirerエリア内にEnquirerの電話番号を入力してください',
  OUT_CHANNEL_FAX_ERROR_MSG: '発信チャネルFAX Enquirerの価値',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG: '発信チャネル電話Enquirerの価値',
  COPYRIGHT_PART_ONE: '著作権',
  COPYRIGHT_PART_TWO: 'Madjenta Ltd. All Rights Reserved。',
  DELETE_INQUIRER_PII: 'Inquirerの個人情報を削除します',
  REVIEWED_DATE: 'レビュー日',
  OUT_CHANNEL_EMAIL_ERROR_MSG_LONG:
    '電子メールとして選択された発信チャネル。 Enquirerエリア内にEnquirerのメールを入力してください',
  OUT_CHANNEL_EMAIL_ERROR_MSG: '発信チャネルメールEnquirerの値',
  ENTER_USER_COMMENT_OPTIONAL: 'ユーザーにコメントを入力する（オプション）',
  SELECT_ANOTHER_CATEGORY: '別のカテゴリを選択します',
  SELECT_ANOTHER_PRODUCT: '別の製品を選択します',
  SUGGESTED_TOPIC: '提案されたトピック',
  EDIT_USER_REQUEST: 'ユーザーリクエストを編集します',
  NEW_TOPIC_REQUEST: '新しいトピックが提案されました',
  EDIT_TOPIC_REQUEST: '推奨されるトピックを編集します',
  NEW_CATEGORY_REQUEST: '新しいカテゴリが提案されました',
  EDIT_CATEGORY_REQUEST: '推奨されるカテゴリを編集します',
  REVIEW_COMMENT: 'コメントを確認してください',
  DOCUMENTS_APPROVAL_WORKFLOW: {
    PAGE_TITLE: 'ドキュメント承認ワークフロー',
    CREATE_NEW: '新しいワークフローを作成します',
    CUSTOMIZE: 'ワークフローをカスタマイズします',
    NAME: 'ワークフロー名',
    ERROR_MSG_DUPLICATE_NAME: 'この名前のワークフローはすでに存在しています。',
    SAVE: 'ワークフローを保存します',
    DELETE: 'ワークフローを削除します',
    STEP: 'ステップ',
    ALL_USERS_WITH_DOC_AUTHOR_ROLE: '文書著者の役割を持つすべてのユーザー。',
    APPROVAL_DEADLINE: '承認期限',
    SELECT_APPROVAL_TYPE: '承認タイプを選択します',
    SELECT_APPROVAL_TYPE_FIRST: '最初に承認タイプを選択します',
    SELECT_REVIEWERS: 'レビュー担当者を選択します',
    ACTION_ON_APPROVE_WITH_CHANGES: '変更とともに承認するアクション',
    ACTION_ON_REJECT: '拒否に対する行動',
    FILL_ALL_REQUIRED_FIELDS:
      '新しいステップを追加するために、必要なすべてのフィールドを入力してください。',
    ALL_APPROVAL_TYPES_USED:
      '現在のステップで利用可能なすべてのタイプを使用したため、承認サブステップを追加することはできません。',
    CONFIRMATION_ROW_1: 'チームのドキュメント承認ワークフローを削除しようとしています {{ NAME }}.',
    CONFIRMATION_ROW_2: '本当に承認ワークフローを削除したいですか？',
    CONFIRMATION_TITLE: 'ドキュメント承認ワークフローの削除',
    TEAM_VIEW_SELECT_EXISTING: '以下のリストから既存のドキュメント承認ワークフローを選択するか、',
    DOC_VIEW_SELECT_EXISTING: '既存のドキュメント承認ワークフローを選択します',
    ONLY_SINGLE_APPROVAL_STEP_CAN_BE_LISTED_AS_FINAL:
      '最終的な承認ステップのみをリストすることができます。',
    APPROVAL_TYPE_TOOLTIP:
      'このチームのドキュメント承認の役割を持つユーザーはいません。 最初に承認の役割を追加してください。',
    INFO_DIALOG_TITLE: 'このチームにはドキュメント承認ワークフローが設定されていません。',
    INFO_DIALOG_MSG:
      '地元のMIMに連絡して、チームのドキュメント承認ワークフローを作成してください。',
    STEP_LIMIT_REACHED: '承認ステップの上限である {{ MAX_STEPS }} に達しました。',
  },
  DOCUMENTS_WITHOUT_APPROVAL: '承認を必要としないドキュメントタイプ',
  PLEASE_SELECT_TYPE: 'タイプを選択してください',
  ABORT_FILE_UPLOAD: 'ファイルアップロードを中止します',
  USERS_REPORT: 'ユーザーが報告します',
  DOCUMENT_CREATE_CONFIRM_CLOSE_MSG:
    'このウィンドウを閉じることで、すでに記入された情報を失います。',
  DO_YOU_WANT_TO_PROCEED: '先に進みたいですか？',
  USERS_REPORT_DESCRIPTION:
    'Daiichi Sankyo EuropeのMIMが腫瘍学と心血管を備えたすべての追加チームのユーザーのリストがMIMの役割を持っています。',
  TEMPLATE_CONFIRM_MSG: 'テンプレートが削除されると、復元できません！',
  EVENT_CONFIRM_MSG: 'イベントがアーカイブされると、復元できません！',
  CREATE_DRAFT: 'ドラフトを作成します',
  CREATE_USER: 'ユーザーを作成',
  ADD_ROLE: '役割を追加します',
  DOMAIN_ADDRESS_INVALID_FOR: 'ドメインアドレスが無効です',
  AVAILABLE_ADDRESSES: '利用可能なアドレス',
  IN_TEAM: 'チームで',
  TO_THE_SELECTED_USER: '選択したユーザーに',
  USER_INFORMATION: 'ユーザー情報',
  LOGIN_VERIFY_EMAIL_ADDRESS:
    '使用しているメールアドレスは、ドメインアドレスがチームに関連付けられていないため、更新する必要があります。',
  MESSAGE: 'メッセージ',
  IN_APPROVAL: '承認',
  IN_REVIEW: 'レビュー',
  DOCUMENT_APPROVAL: '文書の承認',
  DOCUMENT_TITLE: 'ドキュメントのタイトル',
  SEARCH_DOCUMENTS: 'ドキュメントを検索します',
  APPROVAL_INBOX: '承認受信トレイ',
  FILTER_DOCUMENTS_BY: 'ドキュメントをフィルターします',
  submit_to_approval: '承認を提出してください',
  create_document_metadata: 'ドキュメントメタデータを作成します',
  archive: 'アーカイブ',
  I_ACKNOWLEDGE_THAT_IT_IS_:
    '地元の要件に応じて、すべての医療製品のすべての有害な薬物反応（ADR）を報告することは私の義務であることを認めます。 ENQMEDは、薬物の不利な反応に関する報告を捉えることを意図していません -  ADRは、現地の要件に従って薬局性動員に直接報告する必要があります',
  VERSION_DIALOG: {
    TITLE: '新しいバージョンが利用可能になりました',
    MESSAGE:
      '新しいバージョンのソフトウェアが利用可能になりました。\n 新しいバージョンの改良点を活用し、古いバージョンの使用による問題を回避するには、新しいバージョンを入手する必要があります。',
    SAVE_BUTTON: '新しいバージョンを入手',
  },
  SELECT_PREFERRED_CONTACT_ERROR: '問い合わせ者の優先連絡先を選択する必要があります。',
  ACTIVE_INACTIVE: 'アクティブ/非アクティブ',
  CATEGORY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '同じ値のカテゴリがすでに存在するため、ダイアログの後ろの表で既存のカテゴリを確認してください。',
  ERROR_EMAIL_COULD_NOT_BE_DELIVERED: 'エラー！配信の失敗',
  VIA: '経由',
  INSERTED_MANUALLY: '手動で挿入',
  AS_A: 'として',
  FOLLOW_UP_INITIATED_BY_INQUIRER: '問い合わせ者によって開始されたフォローアップ',
  PRODUCT_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '同じ値の製品がすでに存在するため、ダイアログの後ろの表で既存の製品を確認してください。',
  NOT_PRODUCT_SPECIFIC: '製品固有ではありません',
  TOPIC_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '同じ値のトピックがすでに存在するため、ダイアログの後ろの表で既存のトピックを確認してください。',
  PLEASE_ENTER_MAX_CHARACTERS: '最大 {{ MAX_CHARS }} 文字を入力してください。',
  THANK_YOU_MESSAGE: 'ありがとうのメッセージ',
  AS: 'として',
  THERAPEUTIC_AREA_PARENT: '親エリア',
  MAIN_THERAPEUTIC_AREA: '主な治療領域',
  CHOOSE_AN_IMAGE: '画像を選択してください',
  ALLOWED_FORMATS: '{{ FORMATS }} 形式のみが許可されます',
  REMOVE_IMAGE: '画像を削除',
  THERAPEUTIC_AREA_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'すでに同じ値の領域があるため、ダイアログの後ろの表で既存の治療領域を確認してください。',
  PLAIN_TEXT_VIEW: 'プレーンテキストビュー',
  RICH_TEXT_VIEW: 'リッチテキストビュー',
  EDIT_ITEM: '{{ ITEM }} を編集します',
  CREATE_NEW_ITEM: '新しい {{ ITEM }} を作成します',
  ADD_NEW_ITEM: '新しい {{ ITEM }} を追加',
  ADD_ITEM: '{{ ITEM }} を追加',
  AND_HENCE_STATUS_CHANGED_FROM: 'したがって、ステータスはから変わりました',
  FOLLOW_UP_WITH_ENQUIRER: '問い合わせ者へのフォローアップ',
  _MUST_BE_INSERTED_TO_SAVE_REVIEW: '変更を保存するには、以下を挿入する必要があります。',
  FOLLOW_UP_IS_NOT_POSSIBLE_FOR_CLOSED_INTERACTION: '非公開のやり取りのためフォローはできません。',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_REVIEW:
    'レビューステータスでのインタラクション。このメニューを有効にするには、レビューを保存してください。',
  METADATA_ACTION_EDIT_CONFIRM_TITLE:
    '選択した {{METADATA_TYPE}} を非アクティブ化すると、このアクションにより次の関連メタデータ項目が非アクティブ化されます。',
  QUESTION_TEXT_MIN_SIX_CHARS: '質問テキスト (最低 6 文字)',
  PLEASE_PROVIDE_AN_ANSWER_: '回答を入力してください (6 文字以上、または添付ファイル)。',
  USER_MUST_RECOVER_PASSWORD_MESSAGE:
    'ユーザーは「パスワードを忘れた場合」からパスワードを回復する必要があることに注意してください。',
  ENABLE_USER_LOGIN_MESSAGE: 'これにより、ユーザーのログインが可能になります。',
  DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE:
    'これにより、ユーザーのログインと通知が無効になります。',
  SET_NOTIFICATIONS_PREFERENCES_MESSAGE: 'ユーザープロフィールから通知設定を設定してください。',
  SET_PASSWORD: 'パスワードを設定してください',
  ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION:
    'アカウントを再アクティブ化した後、パスワードは消去されていました。',
  SPECIALIZATION_DEACTIVATION_CONFIRMATION: '特化解除を確認！',
  REMOVE_DEACTIVATED_SPECIALIZATION_FROM_ACTIVE_LIST_MESSAGE:
    'これにより、アクティブなスペシャライゼーションのリストから削除されます。',
  GO_TO_ROLES: '役割に移動',
  CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE:
    'ユーザーの連絡先情報を変更すると、ログインメールアドレスが {{ OLD_USERNAME }} から {{ NEW_USERNAME }} に変更されました',
  ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE: '役割と通知を割り当ててください。',
  DO_YOU_WANT_TO_CONTINUE: '続けたいですか？',
  COPY_ANSWER_TO_NEW_INTERACTION: '新しいインタラクションへの回答をコピーします',
  DELETE_INQUIRY_MESSAGE:
    'この問い合わせを削除しようとしています。この操作は元に戻すことはできません。',
  NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE:
    '新しい問い合わせがインポートされました。すべての質問には必ず特徴を明らかにして答えてください。',
  LEAVE_WITHOUT_SAVING: '保存せずに終了する',
  KEEP_INQUIRER_DETAILS_OPEN: '問い合わせ者の詳細をオープンにしておく',
  LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE:
    '未保存の変更があり、照会者の詳細を閉じると失われます。',
  PENDING_CHANGES_MESSAGE: '保留中の変更は {{ CHANGES }} ですか?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION:
    '新しいインタラクションで選択したテキストを分割してもよろしいですか?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE:
    'これにより、選択したテキストがインタラクションから削除され、そこから新しいテキストが作成されます。',
  DOWNLOAD_ERROR: 'ダウンロードエラー!',
  DOWNLOAD_INQUIRIES_ERROR_MESSAGE:
    '1000 件を超える問い合わせをダウンロードしようとしています。サポートされる最大数は 1000 です。選択を絞り込んでください。',
  TRYING_TO_CHANGE_QUESTION_CHARACTERIZATION: '質問の特徴を変更しようとしています。',
  CHANGING_QUESTION_CHARACTERIZATION_NOT_ALLOW_CHANGES_IN_ANSWER_MESSAGE:
    '質問の特徴を変更すると、以前に与えられた回答を変更したり、新しい回答を送信したりすることはできないことに注意してください。',
  RE_OPEN_CLOSED_INTERACTION_MESSAGE:
    '新しい回答を送信するために、閉じられたインタラクションを再開しようとしています。',
  QUESTION_REQUIRE_ANSWER_MESSAGE: '問い合わせを終了するには、新しい質問に回答する必要があります。',
  CONVERT_FOLLOWUP_TO_QUESTION_MESSAGE: 'このフォローアップを質問に変換してもよろしいですか?',
  ARE_YOU_SURE: '本気ですか？',
  USER_DEACTIVATION_CONFIRMATION_QUESTION: '{{ USER_INFO }} の非アクティブ化を確認します',
  USER_REACTIVATION_CONFIRMATION_QUESTION: '{{ USER_INFO }} の再アクティブ化を確認します',
  USER_REACTIVATED_MESSAGE: 'ユーザー {{ USER_INFO }} が正常に再アクティブ化されました。',
  DELETE_NOT_POSSIBLE: '削除できません!',
  SEARCH_NO_LONGER_EXIST: 'この検索は送信者によって削除されたため、存在しません。',
  LOGIN_CREDENTIALS_CHANGED: 'ログイン資格情報が変更されました!',
  EDIT_TEXT: 'テキストを編集する',
  FOLLOW_UP_ATTEMPTS: 'フォローアップの試み',
  FIRST_FOLLOW_UP_INFO_TEXT: '個別のやり取り内で最初のフォローアップが送信される',
  SECOND_AND_THIRD_FOLLOW_UP_INFO_TEXT:
    '最初のリクエスト後に以下のいずれかが無効になっている場合、プロセスは次のステップに移行します。',
  SECOND_ATTEMPT: '2 回目の試行 (1 回目のリマインダー)',
  THIRD_ATTEMPT: '3 回目の試行 (1 回目のリマインダー)',
  FINAL_ATTEMPT: 'フォローアップに失敗しました（閉じる）',
  REMINDER_FREQUENCY: 'リマインダーの頻度',
  AUTO_TRANSLATE_FOLLOW_UP_TEMPLATE: '問い合わせ者への連絡に使用される言語への自動翻訳を適用する',
  ON_OFF_SWITCH: 'オンオフ',
  DAY: '日',
  DAYS: '日々',
  MONTH: '月',
  MONTHS: '月',
  RESET_TEMPLATES_TO_DEFAULTS: 'テンプレートをデフォルトにリセットする',
  SAVE_FREQUENCY: '保存頻度',
  SELECT_FREQUENCY: '周波数の選択',
  TEMPLATE_MODIFICATIONS_HINT:
    'テキストは手動で変更されており、今後は自動的に変更されません。変更を破棄し、自動更新を再度有効にするには、ここをクリックしてください。',
  LOGIN_INCORRECT_EMAIL_ADDRESS: '正しくないメールアドレス！',
  AUDIT_TRAIL_FOR: '{{ ITEM }} の監査証跡',
  AUDIT_TRAIL_FOR_ALL: 'すべての {{ ITEM }} の監査証跡',
  HISTORY_CHANGES_FOR: '履歴の変更',
  HOLIDAY_GDPR_DISCLAIMER: 'ホリデー GDPR の免責事項',
  MANAGE_HOLIDAY_GDPR_DISCLAIMER: '休暇中の GDPR 免責条項を管理する',
  MISSING_HOLIDAY_GDPR_TEMPLATE_FOR_TEAMS: '次のチームの休日 GDPR テンプレートがありません',
  BULK_CREATE: '一括作成',
  LAST_SUCCESS: '最後の成功',
  LAST_FAILURE: '最後の失敗',
  FAILURE: '失敗',
  TASK: 'タスク',
  Weeks: '週間',
  REPEAT_EVERY: '毎回繰り返します',
  DEACTIVATE_ITEM: '{{ ITEM }}を無効化します',
  RESET_FAILURE_COUNT: '失敗回数のリセット',
  TASK_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    '同じ値のタスクがすでに存在するため、ダイアログの後ろの表で既存のタスクを確認してください。',
  ACTIVATE_ITEM: '{{ ITEM }}をアクティブにする',
  PLEASE_ENTER_MIN_NUMBER: '{{ MIN_NUMBER }} より大きい数値を入力してください。',
  MERGE_AS_NEW_INQUIRY: {
    DESCRIPTION_FOR_CLOSED_INTERACTION:
      '選択したマージ対象のインタラクションはすでに閉じられています。この問い合わせは新しい対話であるため、マージする必要はありません。',
    DESCRIPTION_FOR_OPEN_INTERACTION:
      '既存の問い合わせに新しい質問が追加されます。すべての質問に回答する必要があります。結合する質問を選択してください。',
    VALIDATION_MSG:
      'マージを続行するには、マージされたインタラクションから少なくとも 1 つの質問を選択してください。',
  },
  MERGED_INTERACTION: 'マージされたインタラクション',
  TARGET_INTERACTION: 'ターゲットの相互作用',
  MERGE_SUGGESTION: 'マージ提案',
  PLEASE_REVIEW_MERGE_SUGGESTION_BELOW: '以下のマージ提案を確認してください',
  INQUIRERS_COMPARISON_TITLE:
    'マージされたインタラクションとターゲット インタラクションでは異なる問い合わせ者に注意してください。',
  INQUIRERS_COMPARISON_QUESTION: 'この違いにもかかわらず続行するかどうかを確認してください。',
  NO_INQUIRER: '質問者なし',
  TERRITORY: '地域',
  DOMAIN: 'ドメイン',
  IN: 'で',
  MARKED_INQUIRER_PII_DELETE: '質問者の個人情報に削除のマークを付けます',
  UNMARKED_INQUIRER_PII_DELETE: '削除対象の質問者個人情報のマークなし',
  TEAM_GROUP_FILTER: {
    TITLE: '追加のチームを表示する権限を定義する',
    SAVE_BTN_TOOLTIP: 'チームの必須フィールドをすべて入力し、最初にチームを保存してください。',
  },
  ADR_REF_ID: 'ADR参照ID',
  PQC_REF_ID: 'PQC 参照 ID',
  ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS:
    'この添付ファイルは複数のインタラクションに存在します',
  SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS:
    'すべてのインタラクションから添付ファイルを削除したい場合は、チェックボックス オプションを選択してください。',
  CONFIRM_DELETE_ATTACHMENT: 'この添付ファイルを削除するかどうかを確認してください。',
  DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE: '上記のすべてのやり取りから削除します',
  ATTACHMENT_DELETED: '添付ファイルが削除されました',
  ROLES: '役割',
  SET_TO_ALL_INQ_TOOLTIP: 'すべての質問に対して同じ ADR、PQC、および適応外の特性評価を設定します。',
  TASKS_LIST: 'タスクリスト',
  TASK_LOGS: 'タスクログ',
  SELECTED: '選択済み',
  LICENSE_COUNTER: 'ライセンスカウンター',
  LICENSES_LIMIT: 'ライセンス数の制限',
  USERS_TO_EXCLUDE: '除外するユーザー',
  ENTER_MAX_NUMBER_OF_LICENSES: '契約済みのライセンス料金 (ユーザーの役割)。',
  SELECT_USER_ROLES_TO_COUNT: 'ライセンス料金のカウントに適用されるユーザーの役割を選択します。',
  SELECT_USERS_TO_EXCLUDE_FROM_COUNT: 'ライセンス料金のカウントからユーザーを除外します。',
  RECEIPT_AND_SALES_NOTIFICATIONS: '入荷と販売の通知',
  REFRESH_MIGRATION_ERRORS: '移行エラーリストを更新',
  MARKED_AS_RESOLVED_BY: '解決済みとしてマークされています',
  MARKED_AS_RESOLVED_ON: '解決済みとしてマークされました',
  MARK_AS_RESOLVED: '解決済みとしてマークする',
  ERROR_ON: 'エラーオン',
  TEXT_WITH_SPECIAL_CHARS:
    '文字 (a ～ z、A ～ Z)、数字 (0 ～ 9)、およびダッシュ (-)、ピリオド (.)、スペース、コロン (:)、カンマ (,)、括弧 (())、前送りなどの特殊記号のみを入力してください。スラッシュ (/) とプラス (+)。',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_APPROVAL:
    '承認のために送信されたステータスでのインタラクション。このメニューを有効にするには承認/拒否してください。',
  REFRESH_TASK_LOGS: 'タスクログリストを更新',
  LICENSE_COUNTER_ACTION_EDIT_CONFIRM_TITLE: '変更を続行したい場合は、確認してください。',
  DEPUTY_ROLE_ARCHIVE_NOT_ALLOWED: '代理ロールでは手動アーカイブは許可されません。',
  RUN_COUNTER: 'ランカウンタ',
  EXPORT_LICENSES: '輸出ライセンス',
  LICENSE_COUNT_MESSAGE: '使用されているライセンスの数は次のとおりです。',
  QUESTIONS: '質問',
  CLARIFICATION: '説明',
  MERGE_AS_INQUIRY: {
    VALIDATION_MSG:
      'マージを続行するには、ターゲット インタラクションで少なくとも 1 つの質問を選択してください。',
    DESCRIPTION: '説明の質問は、ターゲット インタラクションで選択した質問の下に追加されます。',
  },
  MERGE_AS_DUPLICATE: {
    DESCRIPTION:
      'インタラクションに既存のインタラクションの重複としてフラグを立てるには、このオプションを選択します。',
  },
  DETECTED_CLARIFICATION_QUESTION_WITHOUT_ANSWER:
    '説明の質問として以前のマージが検出されました。ターゲット インタラクションで以前に選択した回答フィールドに加えて、選択したすべての回答フィールドが回答のために再度開かれます。',
  DISABLED_ANSWER_FIELD_TOOLTIP:
    '別の質問に対する回答を提供するために対話が再開されました。この質問に対する答えはすでに以前に提供されています。',
  TIP: 'ヒント',
  MERGE_TIP_TEXT:
    '代わりにインタラクション {{ MERGED_INTO_ID }} を再度開きたい場合は、「いいえ」を押して、ターゲット インタラクション {{ MERGED_INTO_ID }} を示す新しいマージ プロセスを開始してください。',
  YOU_ARE_SELECTING_MERGE_WITHOUT_REOPEN:
    'ターゲット インタラクションを開かずにマージ操作を選択しています。',
  YOU_ARE_SELECTING_MERGE_INTO_INVALID_INTERACTION:
    '無効なターゲット インタラクション {{ TARGET_INTERACTION_ID }} へのマージ オペレーションを選択しています。<br>インタラクション {{ TARGET_INTERACTION_ID }} はステータス {{ STATUS }}{{ LAST_MERGE_INFO }} で閉じられました。',
  CLOSED_INTERACTION_AND_INABILITY_TO_PROVIDE_ANSWER:
    'その結果、対話は閉じられ、答えを提供できなくなります。',
  INTO_INTERACTION: 'インタラクションへ',
  PLEASE_SELECT_INTERACTION_FOR_MERGE: 'マージするインタラクションを選択してください。',
  PLEASE_SELECT_MERGE_REASON: 'マージ理由を選択してください。',
  QUESTION_CANNOT_BE_DELETED: '関連する未回答の説明質問があるため、この質問は削除できません。',
  MERGE_REASON: 'マージ理由',
  WITH_REOPEN: '再開に伴い',
  MERGED_INTO_QUESTION_IDS: '質問 ID に統合されました',
  MERGED_INTO_QUESTIONS: '質問に統合されました',
  MERGED_PREVIOUSLY_FROM: '以前にマージされた',
  FROM_INTERACTION_ID: 'インタラクションIDから',
  IN_INTERACTION_ID: 'インタラクションID内',
  YOU_ARE_ABOUT_TO_CANCEL_MERGE_SUGGESTION: 'マージ提案をキャンセルしようとしています。',
  DO_NOT_SHOW_MERGE_SUGGESTION_AGAIN: 'マージ提案を再度表示しませんか?',
  PRODUCT_RESOLVED_VIA_AI: '製品（AIによる解決）',
  REQUIRED_FIELDS_HINT: 'すべての必須フィールドに入力してください',
  AT_LEAST_ONE_CONTACT_IS_REQUIRED: '少なくとも 1 つの連絡先が必要です。',
  DISCLAIMER_INFO: '免責事項について',
  QUESTION_ID: '質問ID',
  QUESTION_IDS: '質問ID',
  SHOW_TEAMS: 'チームを表示する',
  LAST_POLLING: '最後のポーリング',
  HOST: 'ホスト',
  PROTOCOL: 'プロトコル',
  FROM_EMAIL: 'メールから',
  REASON_FOR_REMOVAL: '削除の理由',
  A_CLOSED_INTERACTION_IS_NOT_EDITABLE: '閉じたインタラクションは編集できません。',
  METADATA_DROPDOWNS_TOOLTIP_TEXT:
    'インタラクションには複数の質問があります。編集はオープンなインタラクションからのみ可能です。',
  BULK_EDIT: '一括編集',
  HOW_TO_ENABLE_BULK_EDIT:
    '一括更新を行うには、同じ言語で少なくとも 2 つのテンプレートを選択する必要があります。',
  REASON_FOR_UPDATE: '更新の理由',
  FOLLOW_UP_RESEND: 'フォローアップを再送信',
  SENT_FOLLOW_UP_REMINDER: 'フォローアップリマインダーを送信しました',
  CLOSED_INTERACTION_AND_SENT_LOST_TO_FOLLOW_UP_NOTIFICATION:
    'やり取りを終了し、フォローアップ通知を送信しました',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS: '15文字以上の長さのテキストを入力してください。',
  FOLLOW_UP_DISABLED_INSERT_ENQUIRER_FIRST:
    'フォローアップは無効です。まず問い合わせ者を入力してください。',
  FOLLOW_UP_REQUEST_DISABLED_INSERT_ENQUIRER_EMAIL_FIRST:
    'フォローアップ リクエストが無効です。まず有効な問い合わせ者のメール アドレスを入力してください。',
  FOLLOW_UP_FREQUENCY_VALIDATION_MSG:
    '選択した周波数タイプの数値は、1 から {{ MAX_ALLOWED_FREQUENCY }} までの範囲でなければなりません。',
  FOLLOW_UP_PLEASE_SELECT_FREQUENCY_TYPE: '周波数タイプを選択してください。',
  FOLLOW_UP_FREQUENCY_TOOLTIP:
    'カウントは最初のフォローアップ リクエストの日時から始まります。日数を週数に変換する場合は 7 を掛けます (例: 2 週間 = 14 日)。日数を月数に変換する場合は 30 を掛けます (例: 1 か月 = 30 日)。',
  VALID_PHONE_FAX: '有効な電話番号/ファックス番号を入力してください。',
  SELECT_LANG_TO_VIEW_AND_MANAGE_TRANSLATED_TEMPLATES:
    '翻訳されたテンプレートを表示および管理するには言語を選択します。',
  RETRANSLATE: '再翻訳',
  FOLLOW_UP_REMINDERS_INFO_TEXT: '以下のテンプレートは英語のみで、翻訳は以下で管理されています。',
  FOLLOW_UP_RESET_BTN_TOOLTIP:
    '上記のすべてのテンプレートはデフォルトにリセットされ (手動による変更は削除され、頻度は 14 日にリセットされ、翻訳チェックボックスは選択解除されます)、すべての言語に再翻訳され、is_manually_modified がクリアされます。',
  FOLLOW_UP_EDIT_BTN_TOOLTIP:
    'テンプレート テキストを編集し、手動で変更されていないすべての翻訳を再翻訳します。',
  FOLLOW_UP_RETRANSLATE_BTN_TOOLTIP:
    '手動で変更されたものも含め、元のテンプレート テキストを再翻訳します。',
  FOLLOW_UP_REMINDER_TEMPLATES: 'フォローアップリマインダーテンプレート',
  MANUALLY_MODIFIED: '手動で変更',
  SYNC: '同期',
  DIFFERENT: '違う',
  DEFAULT_CONFIRMATION_QUESTION: '続行する場合は確認してください。',
  SEND_WELCOME_MAIL_: 'パスワードリセットを含むウェルカムメールを送信',
  CONFIRM_FU_RESPONSE_WITH_REOPEN:
    '問い合わせ者から新たなフォローアップ情報が受け取られたことを確認し、回答を提供するためにやり取りを再開したいと思います。',
  ONLY_LETTERS_SPACE_AND_DASH: '文字、スペース、- 記号のみを入力してください。',
  INFO: '情報',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG:
    '問い合わせ者の国が変更されると、インタラクションの割り当てがチーム {{ CURRENT_TEAM }} からチーム {{ FUTURE_TEAM }} に変更されます。',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_REMOVE_ASSIGNMENT:
    '問い合わせ者の国を変更すると、インタラクションの割り当てがチーム {{ CURRENT_TEAM }} からチーム {{ FUTURE_TEAM }} に変更されます。ユーザー {{ ASSIGNED_TO }} には {{ FUTURE_TEAM }} チームに対する権限がないため、個人の割り当ては削除されます。',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'この変更は、次の追加のインタラクションにおけるチーム割り当てに影響し、個人の割り当ては削除されます: {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_HCP_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    '問い合わせ者の国が変更されると、次の追加のやり取りでチームの割り当てが変更され、個人の割り当てが削除されます。 {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_RELATED_INTERACTIONS_DATA:
    'インタラクション ID: {{ INTERACTION_ID }} が {{ CURRENT_TEAM }} から {{ FUTURE_TEAM }} へ、ユーザー {{ ASSIGNED_TO }} から個人の割り当てが削除されました',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'ユーザー {{ EDITING_USER }} が、インタラクション ID: {{ UPDATED_INTERACTION_ID }} の問い合わせ者 {{ ENQUIRER }} の国を変更しました。これにより、現在のインタラクションの割り当てがチーム {{ OLD_TEAM }} からチーム {{ NEW_TEAM }} に変更されました。',
  INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'ユーザー {{ EDITING_USER }} が HCP 管理で問い合わせ者 {{ ENQUIRER }} の国を変更したため、現在のやり取りの割り当てがチーム {{ OLD_TEAM }} からチーム {{ NEW_TEAM }} に変更されました。',
  INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'ユーザー {{ INTERACTION_ASSIGN_TO }} には {{ NEW_TEAM }} チームに対する権限がないため、個人の割り当ては削除されます。',
  PERSONAL_ASSIGNMENT: '個人的な課題',
  DUE_TO_FU_REQUEST: 'フォローアップのリクエストにより',
  DUE_TO_FU_RESPONSE: 'フォローアップの対応により',
  DUE_TO_FU_REMINDERS_FREQ_UPDATE: '管理ページからのフォローアップリマインダーの頻度の更新による',
  UPDATED_FROM_INBOX: '受信トレイから更新',
  ARCHIVED_ROLE: 'アーカイブされた役割',
  USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA:
    'ユーザー ロールをアーカイブすると、次の追加のインタラクションの割り当てが変更され、個人の割り当てが削除されます: {{ USER_INTERACTIONS }}',
  USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'ユーザー {{ EDITING_USER }} が {{ INTERACTION_ASSIGN_TO }} ユーザーを編集し、チーム {{ TEAM }} の {{ USER_ROLE }} としてその役割をアーカイブしました。ユーザー {{ INTERACTION_ASSIGN_TO }} には {{ TEAM }} チームに対する権限がないため、個人の割り当ては削除されました。',
  ASSIGNMENT: '割り当て',
  PERIOD_TO_PERIOD_OVERLAP: '期間と終了期間が重複しています',
  PERIOD_TO_PERIOD_MISMATCH:
    '期間と終了期間の期間が異なります - {{ PERIOD }} {{ INTERVAL }} と {{ TO_PERIOD }} {{ TO_INTERVAL }}',
  CREATED_QUESTION_FROM_FU_RESPONSE: 'フォローアップの回答から作成された質問',
  CREATE_QUESTION_FROM_FU_RESPONSE_TOOLTIP:
    'このオプションを使用すると、フォローアップから新しい質問を作成できます。',
  ADD_AS_A_NEW_QUESTION: '新しい質問として追加',
  ROLE_ARCHIVE_WITH_USER_DEACTIVATION:
    'このユーザーの最後に承認されたロールをアーカイブしようとしていることに注意してください。これにより、ユーザーが非アクティブ化され、EnqMed への今後のアクセスができなくなります。',
  ATTEMPTED_FOLLOW_UP: 'フォローアップを試みた',
  FAILED_FU_ATTEMPT: 'フォローアップの試みが失敗しました',
  UNABLE_TO_REACH_ENQUIRER: '問い合わせ者に連絡できません。',
  FAILED_FU_ATTEMPT_TOOLTIP:
    '問い合わせ者に連絡を取ろうとしたが成功しなかった場合は、このオプションを選択します。たとえば、電話で連絡が取れなかった、対面またはその他のアポイントメントが失敗したなどです。\nこの試みは、インタラクション履歴に記録されます。',
};
