export const dutchLangData = {
  MEDICAL_INFO_SYSTEM: 'Medicinsk informationssystem',
  ACTIVE_EVENTS: 'Aktive begivenheder',
  SELECT_AN_EVENT_TO_BROWSE_USERS: 'Vælg en begivenhed for at gennemse brugere.',
  BACK_TO_THE_EVENT_SELECTION: 'Tilbage til valg af begivenhed',
  IF_YOU_FORGOT_YOUR_PASSWORD: 'Hvis du har glemt din adgangskode',
  LOGIN: 'Log på',
  RESET_IT_HERE: 'nulstil det her',
  CHANGE_LANGUAGE_TO: 'Skift sprog til',
  TEST_SYSTEM: 'TESTSYSTEM',
  HELP: 'Hjælp',
  THIS_IS_TEST_ENV: 'Dette er et testmiljø, hvis du vil indsætte reel undersøgelse, gå til',
  USER_OR_PASS_IS_INCORRECT: 'Brugernavn eller adgangskode er forkert',
  OPS: 'Ops',
  WE_DID_NOT_FIND: 'Vi fandt ikke, hvad du ledte efter',
  VIDEO_TUTORIALS: 'Video-tutorials',
  STILL_DIDNT_FIND: 'Fandt stadig ikke det, du leder efter,',
  ASK_THE_SUPPORT_TEAM: 'Spørg supportteamet',
  LOGGED_IN_AS: 'logget ind som',
  MY_PROFILE: 'Min profil',
  LOGOUT: 'Log ud',
  RETRIEVING_YOUR_ACCESS_INFO: 'Henter dine adgangsoplysninger',
  LOGGED_IN_CAN_CLOSE: 'Logget ind, kan du nu lukke denne fane',
  TOKEN_HAS_NOT_BEEN_SAVED: 'Token er ikke blevet gemt korrekt. Kontakt supporten',
  SHARE_POINT_LOGIN: 'SharePoint-login',
  LOGIN_TO_SHARE_POINT: 'Log ind på SharePoint for at fortsætte',
  PASSWORD_RESET: 'Nulstil kodeord',
  RESET_PASSWORD: 'Nulstille kodeord',
  INSERT_YOUR_EMAIL_TO_RESET_PASS: 'Indsæt din e-mail for at nulstille din adgangskode',
  CANCEL: 'Afbestille',
  CANCEL_UPPERCASE: 'AFBESTILLE',
  RETURN_TO_LOGIN: 'Tilbage til login',
  USERNAME: 'Brugernavn',
  USERNAME_IS_REQUIRED: 'Brugernavn kræves',
  PASSWORD: 'Adgangskode',
  PASSWORD_IS_REQUIRED: 'adgangskode er påkrævet',
  FIRST_NAME: 'Fornavn',
  LAST_NAME: 'Efternavn',
  REGISTER: 'Tilmeld',
  LOADING: 'Indlæser',
  INSERT_NEW_PASS: 'Indsæt din nye adgangskode',
  VERIFYING_TOKEN: 'Bekræfter token',
  PASSWORD_DO_NOT_MATCH: 'Kodeordene er ikke ens',
  PASS_RESET_SUCCESSFUL: 'Nulstilling af adgangskode vellykket',
  SAVE_NEW_PASS: 'Gem nyt kodeord',
  GO_TO_INBOX: 'Gå til Indbakke',
  SELECT_ALL: 'Vælg alle',
  DESELECT_ALL: 'Fravælg alle',
  DO_NOT_SHOW_AGAIN: 'Vis ikke igen',
  INQUIRY: 'Forespørgsel',
  ATTACHMENT_WITH_THIS_INQUIRY: '> Vedhæftede filer med denne undersøgelse',
  USER_DETAILS: 'Brugeroplysninger',
  INQUIRER_DETAILS: 'Forespørgselsdetaljer',
  CLOSE: 'Tæt',
  TEAMS: 'Hold',
  TEAM: 'Hold',
  SPECIALIZATION_IS: 'Specialisering er',
  REQUIRED: 'påkrævet',
  RESULTS_PER_PAGE: 'resultater pr. side',
  RESULTS_FOUND: 'Resultater fundet',
  EXPORT: 'Eksport',
  PERSONAL_SETTINGS: 'Personlige indstillinger',
  TEAM_SETTINGS: 'Holdindstillinger',
  USER_SETTINGS_OF: 'BRUGERINDSTILLINGER AF',
  SET_TEMPLATE: 'Indstil skabelon',
  NOTIFICATIONS_WILL_BE_OVERWRITTEN: 'Underretninger overskrives med de nuværende',
  PREVIEW: 'Eksempel',
  NOTIFICATIONS: 'MEDDELELSER',
  ROLE: 'ROLLE',
  TEMPLATE_NAME: 'TEMPLAT NAVN',
  SAVE_THIS_USER_AS_A_TEMPLATE: 'Gem denne bruger som en skabelon',
  ADD_A_TEMPLATE_TO_YOUR_USER:
    'Føj en skabelon til din bruger og kopier rolle og præferencer for underretninger',
  AUTH_AND_NOTIFY_TEMPLATE: 'Autoriserings- og underretningsskabelon',
  MY_ROLES: 'Mine roller',
  STATUS: 'STATUS',
  THIS_ROLE_IS_TEMP:
    'Denne rolle er midlertidig, den er givet på grund af det faktum, at denne bruger er en stedfortræder',
  CREATED: 'CREATED',
  APPROVED_REJECTED: 'GODKENDT / AFVIST',
  APPROVED_REJECTED_BY: 'GODKENDT / afvist af',
  ARCHIVE: 'ARKIV',
  SAVE: 'Gemme',
  IMMEDIATE_EFFECT: 'Øjeblikkelig effekt',
  OUT_OF_OFFICE_DEPUTY: 'UDEN FOR KONTORADPUTET',
  AUTO_ASSIGN_TO: 'DETTE FREMRAGENDE',
  GEOGRAPHY: 'GEOGRAFI',
  ALIGNMENT: 'JUSTERING',
  Alignments: 'Opstillinger',
  Address: 'Adresse',
  PHONE_FAX: 'Telefon / fax',
  Email: 'E-mail',
  Contacts: 'Kontakter',
  WORK_INFO: 'Arbejdsinformation',
  LAST_NAME_IS: 'Efternavn er',
  PERSONAL_INFO: 'Personlig information',
  USER: 'Bruger',
  ID: 'ID',
  UPDATED_FROM: 'opdateret fra',
  ON: 'på',
  CRM: 'CRM',
  IMPORTED_FROM: 'importeret fra',
  CHANGE_PASS: 'Skift kodeord',
  NOTIFICATION_IN_TEMPLATE: 'Underretninger i skabelon',
  CONTINUE: 'Blive ved',
  DONT_SHOW_AGAIN: 'Vis ikke denne meddelelse igen',
  USING_THE_TABS_ON_THE: 'ved hjælp af fanerne øverst på skærmen',
  PERSONAL_PROFILE_SETTINGS: 'Indstillinger for personlig profil',
  AND_YOUR: 'og din',
  YOU_CAN_SWITCH_BETWEEN: 'Du kan skifte mellem din',
  WELCOME_TO_YOUR_USER_PROFILE: 'Velkommen til din brugerprofil',
  SAVE_THIS_USER_AS: 'Gem denne bruger som skabelon',
  INCLUDE_NOTIFY_SETTINGS: 'Inkluderer underretningsindstillinger',
  INCLUDE_ROLES_SETTINGS: 'Inkluderer rolleindstillinger',
  ACTIVE_NOTIFICATIONS: 'AKTive MEDDELELSER',
  SET_THIS_USER_AS_A_: 'Indstil denne brugergodkendelse og meddelelser som ny skabelon',
  REQUEST_AUTH: 'Anmod om tilladelse',
  REQUEST: 'Anmodning',
  FEEDBACK_SUPPORT: 'Feedback og support',
  IF_YOU_STILL_CANNOT_FIND_WHAT_:
    'Hvis du stadig ikke kan finde det, du leder efter, så spørg supportteamet! Også hvis du har et forslag til fremtidige forbedringer, er dette det rigtige sted.',
  TEMPLATE: 'Skabelon',
  SIGNATURE: 'Underskrifter',
  Disclaimers: 'Forbehold',
  Greetings: 'Vær hilset',
  CUSTOMIZE: 'CUSTOMIZE',
  SAVE_UPPERCASE: 'GEMME',
  DELETE: 'SLET',
  RESET_TO_DEFAULT: 'NULSTILLING TIL STANDARD',
  AFTER_SIGNATURE: 'Efter underskrift',
  BEFORE_SIGNATURE: 'Før underskrift',
  AFTER_ANSWER: 'Efter svar',
  BEFORE_ANSWER: 'Før du svarer',
  AFTER_GREET: 'Efter hilsener',
  BEFORE_GREET: 'Før hilsener',
  Any: 'Nogen',
  HCP: 'HCP',
  NO_HCP: 'Intet HCP',
  Position: 'Position',
  ON_LABEL: 'On label',
  OFF_LABEL: 'Off label',
  YOU_ARE_TRYING_TO_CUSTOMIZE: 'Du prøver at tilpasse skabelonen',
  YOU_DO_NOT_HAVE_THE_PERMISSON:
    'Du har ikke tilladelse til at gøre det. Hvis du vil fortsætte, oprettes en kopi af skabelonen, og du foretager ændringerne der.',
  CREATE_COPY: 'Opret kopi',
  RECEIVED_ON: 'Modtaget den',
  SENDER_EMAIL: 'Sender e-mail',
  SUBJECT: 'Emne',
  UNSPAM: 'Unspam',
  UPLOAD: 'Upload',
  APPROVED: 'godkendt',
  DRAFT: 'Udkast',
  SELECT_STATUS: 'Vælg status',
  UPLOAD_AS: 'Upload som',
  TITLE: 'Titel',
  REPOSITORY: 'Repository',
  BROWSE: 'Gennemse',
  URL: 'URL',
  APPROVED_ON: 'Godkendt den',
  AUTH_HOLDER: 'Godkendelsesindehaver',
  AUTH_NUMBERS: 'Autorisationsnumre',
  APPROVED_FOR: 'Godkendt for',
  SELECT_ONE_REGION: 'Vælg en region',
  OWNED_BY: 'Ejet af',
  KEYWORDS: 'nøgleord',
  TOPICS: 'Emner',
  CATEGORIES: 'Kategorier',
  PRODUCTS: 'Produkter',
  PRODUCT: 'Produkt',
  LANGUAGE_IS_REQUIRED: 'Sprog er påkrævet',
  LANGUAGE: 'Sprog',
  TARGET_CUSTOMERS: 'Målret mod kunder',
  TYPE_IS_REQUIRED: 'Type kræves',
  TYPE: 'Type',
  TITLE_IS_REQUIRED: 'Titel kræves',
  DEFAULT_REFERENCE_IS_CREATED_:
    'Standardreference oprettes med Vancouver-stilen. Hvis du vil tilsidesætte dette, skal du indtaste hele referencen her',
  REFERENCE: 'Reference',
  DOI: 'DOI',
  PUBMED_ID: 'Pubmed ID',
  PAGES: 'sider',
  ISSUE: 'Problem',
  VOLUME: 'Bind',
  JOURNAL: 'Tidsskrift',
  AUTHORS: 'Forfattere',
  ABSTRACT: 'Abstrakt',
  REFERENCE_IS_REQUIRED: 'Henvisning kræves',
  THIS_DOC_IS_APPROVED: 'Dette dokument er godkendt. Klik her for at åbne',
  ORIGINAL_DOCUMENT_AND_: 'originaldokument, og indsæt dine kommentarer',
  EDIT_FILE: 'Rediger fil',
  PROPERTIES: 'Ejendomme',
  OPEN: 'Åben',
  LOCALIZED_DOCUMENTS: 'Lokaliserede dokumenter',
  ORIGINAL_DOCUMENTS: 'Originalt dokument',
  INTERNAL_APPROVAL_NUMBER: 'Internt godkendelsesnummer',
  CREATED_BY: 'Lavet af',
  EXPIRATION_DATE: 'Udløbsdato',
  VERSION_NUMBER: 'Versionsnummer',
  NO_VERSION_AVAILABLE: 'Ingen version tilgængelig',
  ARCHIVED_IF_NO_DRAFT: 'Arkiveret: hvis der ikke findes nogen udkast / godkendte versioner.',
  APPROVED_FOR_USERS: 'Godkendt: til brugere.',
  DRAFT_FOR_AUTHORS_: 'Udkast: til forfattere og redaktører.',
  VERSION_LEGEND: 'Versionslegende',
  VERSION: 'version',
  SELECT_ONE_LANG: 'Vælg et sprog.',
  SELECT_ONE_TYPE: 'Vælg en type.',
  INTERNAL_NUMBER_REQUIRED: 'Internt godkendelsesnummer er påkrævet.',
  FILE: 'Fil',
  ATTACH_FILE: 'Vedhæft fil',
  LITERATURE: 'Litteratur',
  SLIDE_DECK: 'Slide Deck',
  PIL: 'PIL',
  FAQ: 'Ofte stillede spørgsmål',
  SRD: 'SRD',
  ADD_APPROVAL_STEP: 'Tilføj godkendelsestrin',
  ADD_PARALLEL_STEP: 'Tilføj parallelt trin',
  DELETE_STEP: 'Slet trin',
  DELETE_ALL_PARALLEL_STEPS: 'Slet alle parallelle trin',
  CHANGE_STATUS_NAME: 'Skift statusnavn',
  SET_REQUIRED_ROLE: 'Indstil den krævede rolle',
  SET_TEAM_MEMBER: 'Indstil teammedlem',
  YOU_DO_NOT_HAVE_THE_PERMISSION_TO_: 'Du har ikke tilladelser til at ændre denne proces.',
  SAVE_ROLE: 'SPAR ROLE',
  CREATE_NEW_WORKFLOW: 'Opret ny arbejdsgang',
  CREATE_NEW_STEP: 'Opret nyt trin',
  PLEASE_ENTER_THE_NUMBER_OF_:
    'Indtast antallet af parallelle godkendelsestrin, hvilket betyder trin, der er godkendt på samme tid (ikke sekventielt). Hvis du ikke har nogen parallelle godkendelsestrin, skal du indtaste 1. Du kan altid ændre antallet af godkendelsestrin senere.',
  APPROVAL_MUST_BE_AT_LEAST: 'Godkendelsen skal mindst være',
  INSERT_THE_NEW_STATUS_NAME: 'Indsæt det nye statusnavn',
  INSERT_THE_NEW_WORKFLOW_NAME: 'Indsæt det nye workflow-navn.',
  INSERT_THE_NEW_STEP_NAME: 'Indsæt det nye trinnavn.',
  NEW_DOCUMENT: 'Nyt dokument',
  CREATE: 'skab',
  DOCUMENT_MANAGEMENT: 'Dokumenthåndtering',
  CONFIRM: 'Bekræfte',
  CONFIRM_PROCESS: 'KONFIRM PROCESS',
  DEFINE_APPROVAL_STEPS_AND_TEAM: 'DEFINER godkendelsestrin og -team',
  SET_DETAILS: 'INDSTILL DETALJER',
  SELECT_TYPE: 'VÆLG TYPE',
  COMPLETE_LOCALIZATION_PROCESS: 'Komplet lokaliseringsproces',
  CREATE_NEW_VERSION: 'Opret ny version',
  CREATE_NEW_DOCUMENT: 'Opret nyt dokument',
  IS_PRIVATE: 'Er privat',
  SHARED_WITH_OTHER_TEAMS: 'Delt med andre hold',
  PLEASE_ENTER_THE_NUMBER_IF_SEQ_:
    'Indtast antallet af nødvendige sekventielle godkendelsestrin. Hvis to godkendelser er parallelt, tæller det som et trin. Du kan altid ændre antallet af godkendelsestrin senere.',
  DONE: 'Færdig',
  SUBMIT_FOR_APPROVAL: 'Indsend til godkendelse',
  YOU_HAVE_TO_CONFIRM_THE_: 'Du skal bekræfte processen inden upload.',
  SELECT_YOUR_DEC_TYPE: 'Vælg din dokumenttype',
  TARGET_AUDIENCE: 'Målgruppe',
  PROCESS_DETAILS: 'Process detaljer',
  START_DATE: 'Start dato',
  DOC_DETAILS: 'Dokumentoplysninger',
  CONFIRMATION: 'Bekræftelse',
  APPROVAL_PROCESS: 'Godkendelsesproces',
  SCHEDULED_EXP_DATE: 'Planlagt udløbsdato',
  SCHEDULED_PUBLISHING_DATE: 'Planlagt udgivelsesdato',
  NO_PROCESS_SELECTED: 'Ingen proces valgt',
  COUNTRIES: 'Lande',
  DOC_ID: 'Dokument ID',
  THERAPEUTIC_AREA: 'Terapeutisk område',
  FILTER: 'Filter',
  BACK: 'tilbage',
  ATTACH: 'Vedhæft',
  COPY_ANSWER: 'Kopier svar',
  SIMILAR_INQUIRIES: 'Lignende undersøgelser',
  SDR_FAQ: 'SRD / FAQ',
  SLIDE_DECKS: 'Slide dæk',
  MEDICAL_DOC: 'Medicinsk dokument',
  SHOW: 'At vise',
  LOAD_MORE: 'Indlæs mere',
  NO_RESULT_FOUND: 'Ingen resultater fundet.',
  CAN_BE_USED_TO_SEARCH_FOR_AN_:
    'kan bruges til at søge efter en hel sætning, - kan bruges til at ekskludere fra søgeresultaterne inden for det specificerede ord',
  SEARCH_FOR_MEDICAL_SIMILAR_INQ: 'Søg efter medicinske dokumenter eller lignende undersøgelser',
  SEARCH: 'SØG',
  DRAFT_VERSION: 'Udkast til version',
  AVAILABLE_ACTIONS: 'Tilgængelige handlinger',
  OPEN_DOC: 'Åbn dokument',
  UPLOAD_FILE: 'Upload fil',
  THE_DOCUMENT_IS_STILL_IN_APPROVAL_:
    'Dokumentet er stadig i godkendelse, hvis du vil ændre dokumentindholdet, kan du åbne dokumentet i Sharepoint eller uploade en opdateret version.',
  FINALIZE_LOCALIZATION: 'Færdiggør lokalisering',
  THIS_DOC_HAS_BEEN_LOCALIZED:
    'Dette dokument er lokaliseret, processen skal afsluttes før godkendelse.',
  Preview: 'Eksempel',
  PREVIEW_AND_SEND: 'Voorbeeld bekijken en verzenden',
  PREVIEW_AS_PDF: 'Vis som pdf',
  PUBLISHED_VERSION: 'Udgivet version',
  NEW_VERSION: 'Ny version',
  THE_DOC_IS_STILL_IN_APPROVAL_:
    'Dokumentet er stadig i godkendelse, hvis du vil ændre dokumentindholdet, upload en anden fil.',
  OUT_TO: 'ude for at',
  NEXT: 'Næste',
  SELECTED_SLIDES: 'Valgte lysbilleder',
  GO_TO_SLIDE: 'GÅ TIL SLIDE',
  PREV: 'prev',
  SELECT_SLIDE: 'Vælg Slide',
  ANSWER: 'Svar',
  BACK_TO_STANDARD_VIEW: 'Tilbage til standardvisningen.',
  INQUIRER: 'Inquirer',
  UPLOAD_NEW_FILE: 'Upload ny fil',
  CREATE_FILE: 'Opret fil',
  EDITING_WORD_DOC: 'Redigering af Word-dokument',
  INSERT_FILE_NAME: 'Indsæt filnavn',
  PROCEED_WITH_THE_LINK_: 'Fortsæt med linket til filen',
  A_NEW_WINDOW_WILL_BE_: 'Et nyt vindue åbnes med en tom fil, skriv indholdet og luk fanen',
  INSERT_THE_NAME_FOR_:
    'Indsæt navnet på den vedhæftede fil, du vil oprette, og fortsæt med filoprettelsen',
  INSTRUCTIONS: 'Instruktioner',
  CREATE_NEW_WORD_FILE_FOR: 'Opret ny ordfil til',
  FROM: 'fra',
  YOU_CAN_FIND_HERE_THE_LAST_INQ_:
    'Du kan her finde de sidste forespørgsler, du har indsat i systemet, både indsendt og endnu ikke indsendt. Du kan genbruge forespørgsler, du har indsat for at oprette flere lignende henvendelser eller vende tilbage til forespørgsler, som du ikke er færdig med at indsætte.',
  MY_DRAFT_INQ: 'Mine udkast til forespørgsler',
  WARNING_CHANGES_NOT_SAVED: 'Advarsel: Ændringer er ikke gemt',
  PAGE_THAT_WILL_BE_LOST: 'side, der vil gå tabt, hvis du beslutter at fortsætte.',
  YOU_HAVE_UNUSED: 'Du har ikke gemt ændringer i',
  ARE_YOU_SURE_YOU_WANT_TO_: 'Er du sikker på, at du vil forlade denne side uden at gemme?',
  LEAVE_THIS_PAGE: 'Forlad denne side',
  STAY_ON: 'Bliv på',
  ASSIGNED_TO: 'Tildelt',
  THIS_INQ_HAS_BEEND_INSERTED_: 'Denne forespørgsel er indsat under begivenheden.',
  STATUS_IN_APPROVAL: 'Status: I godkendelse',
  THE_RESPONSIBLE_FOR_THE_ANSWER_: 'Den ansvarlige for svargodkendelsen skal acceptere svaret.',
  STATUS_DRAFT: 'Status: Udkast',
  IF_YOU_WANT_CREATE_THE_ACTUAL_INQ_:
    'Hvis du vil oprette den faktiske forespørgsel, skal du gå til fanen Send og klikke på knappen Opret.',
  INQ_IMPORTED_FROM: 'Forespørgsel importeret fra',
  SEND_NEW_ANSWER: 'SEND NYT SVAR',
  RETURN_TO_INBOX: 'RETURN TIL INBOX',
  SUBMITTED_FOR_APPROVAL: 'Indsendt til godkendelse',
  ANSWER_SUBMITTED_ON: 'Svar indsendt den',
  BY: 'ved',
  INQ_HAS_BEEN_CLOSED: 'Forespørgsel er blevet lukket uden svar.',
  BCC: 'Bcc',
  ANSWER_GIVEN_ON: 'Svaret givet den',
  ANSWER_SENT_ON: 'Svaret blev sendt videre',
  INBOX: 'Indbakke',
  OPEN_ORIGINAL_INQ: 'Åben original forespørgsel',
  CLOSE_ANSWER_NOT_NEEDED: 'Luk: Anser ikke nødvendigt',
  HISTORY: 'Historie',
  ADD_A_NOTE: 'Tilføj en note',
  ADD_A_NOTE_INTO_: 'Tilføj en note i undersøgelseshistorikken.',
  SHARE_OR_START_A_FOLLOW_UP: 'Del eller start en opfølgning',
  SHARE_FROM_HERE_YOU_CAN_:
    'Del: herfra kan du tildele, videresende eller starte en opfølgning med forespørgeren.',
  SEE_ALL_INQ_RELATED_TO: 'Se alle forespørgsler relateret til',
  SUGGESTED_INQ: 'Foreslåede forespørgere',
  SET_AS_NEW_INQUIRER: 'Sæt som ny forespørger',
  SRD_NEEDED: 'SRD Nødvendigt',
  ANSWER_UNAVAILABLE: 'Svar Ikke tilgængelig',
  QUESTION: 'Spørgsmål',
  SUBMIT: 'INDSEND',
  ANSWER_UPPERCASE: 'SVAR',
  DETAILS: 'DETALJER',
  INQUIRER_UPPERCASE: 'Inquirer',
  SPLIT_INQ: 'Opdelte forespørgsler',
  ADD_QUESTION: 'Tilføj spørgsmål',
  ENTER_QUESTION: 'Indtast spørgsmål',
  MARK_AS_ANSWERED: 'Marker som besvaret',
  TO_BE_ANSWERED: 'Skal besvares',
  FOLLOW_UP_RECEIVED: 'Opfølgning modtaget',
  ERROR: 'Fejl',
  SHOW_ORIGINAL_MESSAGE: 'Vis den originale meddelelse',
  ANSWER_HAS_NOT_BEEN_SENT_TO_THE_INQ_:
    'Svaret er ikke blevet sendt til den spørgende på grund af forkert e-mail-adresse.',
  SEND: 'Sende',
  DO_NO_SEND: 'Send ikke',
  SEND_CONFIRMATION_VIA_EMAIL_:
    'Send bekræftelse via e-mail til forespørgeren om, at hans / hendes spørgsmål blev modtaget',
  MSG_NOTIFICATION_WILL_BE_SEND_1:
    'Dank u! De melding wordt verzonden zodra de aanvraag is ingediend of beantwoord.',
  MSG_NOTIFICATION_WILL_BE_SEND_2:
    'Dank u! De melding wordt verzonden zodra de aanvrager is opgeslagen.',
  NOTIFICATION_WILL_BE_SENT_TO_THE_INQ:
    'De volgende AVG-kennisgeving wordt via e-mail naar de aanvrager gestuurd.',
  NOTIFICATION_SENT_TO_THE_INQ: 'De volgende AVG-melding is via e-mail naar de aanvrager gestuurd.',
  SEND_A_NOTIFICATION_TO_THE_INQ: 'Stuur de volgende AVG-melding per e-mail naar de aanvrager.',
  OK: 'Okay',
  MAX_LENGTH_FOR_ZIP_CODE: 'Maksimal længde for postnummer er',
  CHARACTERS: 'tegn',
  ADDRESS_1_IS_: 'Adresse 1 er',
  LABEL_IS: 'Mærket er',
  NO_QUESTION_INSERTED: 'Intet spørgsmål indsat',
  WARNING: 'ADVARSEL',
  INQ_HAS_NO_EMAIL: 'forespørger har ingen e-mail.',
  FOLLOW_UP_IS_NOT_AVAILABLE_: 'Opfølgning er ikke tilgængelig for denne interaktion.',
  INSERT_INQ_TEXT: 'Indsæt forespørgselstekst',
  SHOW_MORE: 'Vis mere',
  OUT_OF: 'ud af',
  INQ_TO_BE_SENT: 'Forespørgsler, der skal sendes',
  HIDE_INQ: 'Skjul forespørgsler',
  SHARE: 'Del',
  HCP_PROFILE_OF: 'HCP PROFIL af',
  A_NOTIFICATION_HAS_NOT_BEEN_TO_INQ:
    'Er is GEEN bericht verzonden naar de aanvrager dat het verzoek is ontvangen.',
  INQUIRER_DOES_NOT_HAVE_AN_EMAIL:
    'Vul alle onderstaande verplichte velden en ten minste één e-mailadres in en sla vervolgens op om een voorbeeld van de AVG-ontvangstmelding te zien.',
  CONFIRMATION_SENT_BY: 'Melding verzonden door',
  NOTIFICATION_SENT_TO_THE_INQUIRER:
    'Kennisgeving verzonden naar de aanvrager dat de aanvraag is ontvangen.',
  COUNTRY_IS: 'Land er',
  COUNTRY: 'Land',
  AT_LEAST_ONE_CONTACT_IS_: 'Mindst en kontakt er',
  EMAIL_IS: 'E-mail er',
  EMAIL_LABEL_IS: 'E-mail-etiketten er',
  PHONE_FAX_IS: 'Telefon / fax etiket er',
  PHONE_FAX_NUMBER_IS: 'Telefon / faxnummer er',
  ADDRESS: 'Adresse',
  DELETE_PERSONAL_DATA: 'Slet personlige data',
  HCP_VERIFIED: 'HCP verificeret',
  KEY_OPINION_LEADER: 'Nøglemeningens leder',
  HOSPITAL_BASED: 'Baseret på hospitalet',
  HCP_RELATED_INFORMATION: 'HCP-RELATEREDE OPLYSNINGER',
  ACCEPT: 'Acceptere',
  REJECT: 'Afvise',
  DO_YOU_WANT_TO_ACCEPT_THESE_Q: 'Vil du acceptere disse forslag?',
  SALES_REP: 'SALGSREPRÆSENTANT',
  SUGGEST_A_TOPIC: 'Foreslå et emne',
  TEAM_SPECIFIC_FIELDS: 'SPECIFIKKE FELD FOR TEAM',
  ADR_RELATED: 'ADR-relateret',
  NOT_ADR_RELATED: 'Ikke ADR-relateret',
  PLEASE_REVIEW_THE_MESSAGE_AND_:
    'Gennemgå meddelelsen, og klassificer den som skadelig eller ikke-relevant.',
  ADVERSE_DRUG_REACTION: 'Negativ lægemiddelreaktion',
  CHANGE_DETAILS: 'ÆNDRE DETALJER',
  YES: 'Ja',
  NO: 'Ingen',
  DUE_DATE: 'Afleveringsdato',
  CRITICAL: 'Kritisk',
  NORMAL: 'Normal',
  PRIORITY: 'Prioritet',
  SELECT_DETAILS: 'VÆLG DETALJER',
  SAVE_REVIEW: 'Gem anmeldelse',
  SET_TO_ALL_INQ: 'INDSTILLET TIL ALLE UNDERSØGELSER',
  PRODUCT_QUALITY_COMPLAINT: 'Klager over produktkvalitet',
  PQC_PC_A_PRODUCT_:
    'PQC / PC En produktkvalitetsklage (QC) eller et problem defineres som enhver bekymring, der involverer en defekt eller funktionsfejl i et lægemiddelprodukt eller vedrørende kvalitet, ydeevne, sikkerhed for et lægemiddelprodukt, f.eks. ændret lugt og / eller smag, dårlig eller mangelfuld emballage, mistanke om forurening osv.',
  OFF_LABEL_USE_OF_PHARMA___:
    'Off-label brug af farmaceutiske lægemidler til en ikke godkendt indikation eller i en ikke godkendt aldersgruppe, dosering eller indgivelsesvej.',
  A_RESPONSE_TO_A_MEDICINAL_:
    'Et svar på et lægemiddel, der er skadeligt og utilsigtet, og årsagssammenhæng mellem et lægemiddel og en bivirkning er i det mindste en rimelig mulighed. Også tilfælde af specielle situationer (f.eks. Graviditetssager, off-label-brug, overdosering) skal markeres som ADR.',
  NORMAL_IS_7_WORKING_DAYS:
    'Normal er 7 arbejdsdage, Høj er 48 timer og Kritisk (patientrelateret) er 24 timer',
  CREATE_MULTIPLE_QUESTIONS: 'Opret flere spørgsmål til den samme forespørgerinteraktion.',
  CATEGORY_AND_TOPIC_ARE: 'Kategori og emne er',
  PRODUCT_IS: 'Product is',
  DO_YOU_WANT_TO_ACCEPT_: 'Vil du acceptere disse forslag?',
  MERGE: 'Go',
  Duplicate: 'Duplikere',
  Other: 'Andet',
  Spam: 'Spam',
  CLOSE_INTERACTION: 'Tæt interaktion',
  RE_OPEN_THE_TARGET_: 'Åbn målsøgningen igen efter fusion',
  THIS_IS_JUST_A_THANK_YOU:
    'Dette er bare en takbesked fra spørgeren til den eksisterende undersøgelse.',
  MARK_AS_INTERNAL_: 'Marker som en intern meddelelse for den eksisterende undersøgelse.',
  MARK_AS_AN_ERROR_: 'Marker som en fejlmeddelelse for den eksisterende forespørgsel.',
  THIS_MESSAGE_WILL_BE_EXISTING_:
    'Denne meddelelse markeres som opfølgende svar fra en eksisterende undersøgelse.',
  THANK_YOU: 'tak skal du have',
  AN_INTERNAL_MESSAGE: 'En intern besked',
  AN_ERROR_MESSAGE: 'En fejlmeddelelse',
  NEW_INQ: 'Ny undersøgelse',
  A_FOLLOW_UP_RESPONSE: 'Et opfølgende svar',
  THIS_INQ_IS: 'Denne forespørgsel er',
  SPLIT_INTERACTION: 'Delet interaktion',
  SAVE_AND_CLOSE: 'Gem og luk',
  SAVE_INQ: 'Gem forespørgsel',
  REVIEW_THE_TEXT_AND: 'Gennemgå teksten og kategoriseringen, og bekræft',
  CHOOSE_THE_RELEVANT_PRODUCT: 'Vælg det relevante produkt, kategori og emne til den valgte tekst',
  SELECT_THE_TEXT_THAT_:
    'Vælg teksten, der identificerer spørgsmålet om et produkt / emne (inkluderer ikke hilsener, underskrifter, ansvarsfraskrivelser eller anden ikke-relevant information)',
  REPEAT_THE_PREVIOUS_: 'Gentag de foregående trin for at tilføje flere forespørgsler',
  READ_MORE: 'Læs mere',
  READ_LESS: 'Læs mindre',
  DO_NOT_INCLUDE_:
    'inkluderer ikke hilsener, underskrifter, ansvarsfraskrivelser eller anden ikke-relevant information',
  SELECT_THE_TEXT_THAT_IDENT: 'Vælg teksten, der identificerer spørgsmålet om et produkt / et emne',
  IF_YOU_HAVE_MULTIPLE_: 'Hvis du har flere produkter eller emner, skal forespørgslen opdeles.',
  THE_FOLLOWING_PROCESS_:
    'Følgende proces hjælper dig med at identificere den relevante tekst til hvert produkt / emne: ',
  EXPORT_TO_PDF: 'EXPORT TIL PDF',
  INQ_CREATED: 'Forespørger oprettet',
  Added: 'Lagt',
  Deleted: 'Slettet',
  From: 'Fra',
  To: 'Til',
  BACK_TO_INBOX: 'Tilbage til indbakke',
  PERMISSION_DENIED: 'Adgang nægtet',
  THIS_INQ_IS_LOCKED_BY: 'Denne forespørgsel er låst af',
  FORCE_UNLOCK: 'FORCE UNLOCK',
  INQUIRIES: 'Forespørgsler',
  Download: 'Hent',
  Event: 'Begivenhed',
  INSERTED_BY: 'Indsat af',
  LAST_EDITED_ON: 'Sidst redigeret på',
  LAST_EDITED_BY: 'Sidst redigeret af',
  CREATED_ON: 'Oprettet den',
  TOPIC: 'Emne',
  TABLE_OPTIONS: 'Tabelindstillinger',
  DEPARTMENT: 'Afdeling',
  User: 'Bruger',
  ADD_THERAPEUTIC_AREA: 'Tilføj terapeutisk område',
  CREATE_THERAPEUTIC_AREA: 'Opret nyt terapeutisk område',
  ADD_NEW: 'Tilføj ny',
  SELECT_TEAM_TO_PROCEED: 'Vælg Team for at fortsætte',
  MIM: 'MIM',
  MA: 'MA',
  MSL: 'MSL',
  SALES: 'Salg',
  CALL_CENTER: 'Call Center',
  ALL: 'Alle',
  Warning: 'Advarsel',
  clear: 'klar',
  THESE_CHANGES_WILL_BE_: 'Disse ændringer anvendes til de valgte brugere',
  THIS_IS_FOLLOW_A_UP_RESPONSE: 'Dette er en opfølgende reaktion.',
  THIS_IS_A_FOLLOW_UP_: 'Dette er en opfølgning sendt til forespørgeren.',
  CANT_CREATE_A_FOLLOW_UP_REQUEST:
    'Kan ikke oprette en opfølgningsanmodning uden en valgt forespørger',
  INSERT_A_FOLLOW_UP_RESP: 'Indsæt en opfølgende reaktion',
  INSERT_A_NOTE: 'Indsæt en note',
  MERGED_FROM: 'Fusioneret fra',
  CHANGES: 'Ændringer',
  SHOW_LESS: 'vis mindre',
  COMPLETE: 'Komplet',
  MISSING: 'Mangler',
  CLOSE_AS_FORWARDED: 'Luk som videresendt',
  CLOSE_EXT_TEAM: 'Luk forespørgslen som videresendt til eksternt team',
  THE_INQ_BELONG_TO_A_:
    'Denne forespørgsel hører til et land, der er ansvarligt for et eksternt team. Forespørgslen vil derefter blive videresendt til dette team automatisk.',
  INQ_INFO: 'Forespørgselsinfo',
  RESOLVE: 'Beslutte',
  TERRITORIES: 'territorier',
  EXTERNAL_COMMUNICATION: 'EKSTERN KOMMUNIKATION',
  FOR_INQ_COMING_DIRECTLY_:
    'For henvendelser, der kommer direkte fra HCP via e-mail, skal du automatisk sende en anmeldelse for at informere ham om, at forespørgslen er modtaget.',
  INTERNAL_COMMUNICATION: 'INTERN KOMMUNIKATION',
  SALESREP_RECEIVES_: 'Salesrep modtager kun meddelelser om, at forespørgslen blev besvaret',
  SALESREP_RECEIVES_FULL_ANSWER_: 'Salesrep modtager fuldt svar på On Label Enquiries',
  SALESREP_RECEIVES_ONLY_NOTIF_:
    'Salesrep modtager kun meddelelser om, at forespørgslen blev besvaret',
  Name: 'Navn',
  Module: 'Modul',
  Function: 'Fungere',
  LAST_RUN: 'Sidste løb',
  End: 'Ende',
  Every: 'Hver',
  Period: 'Periode',
  Active: 'Aktiv',
  Resolved: 'løst',
  ERROR_PREVIEW: 'Forhåndsvisning af fejl',
  FAILURE_COUNT: 'Fejltælling',
  COMPLETED_ON: 'Færdiggjort',
  STARTED_ON: 'Begyndt på',
  TASK_NAME: 'Opgavens navn',
  PRODUCT_SPECIFIC: 'PRODUCT SPECIFIEK',
  THERAPEUTIC_AREAS: 'TERAPEUTISKE OMRÅDER',
  PRODUCT_GROUPS: 'PRODUKTGRUPPER',
  LICENSING_PARTNER: 'LICENSIERINGSPARTNER',
  PRODUCT_CODE: 'PRODUKTKODE',
  EXTERNAL_ID: 'Ekstern ID',
  OBJECT_TYPE: 'Objekttype',
  MIGRATION_NAME: 'Migrationsnavn',
  MIGRATION_ERRORS: 'Migrationsfejl',
  ADD_SALES_REP: 'Tilføj salgsrepr',
  ASSIGNED_TO_MSL: 'TILDELT TIL MSL',
  NOT_ASSIGNED: 'Ikke tildelt',
  ASSIGNED_TO_OTHER_MSL: 'Tildelt til anden MSL',
  ASSIGNED_TO_THIS_MSL: 'Tildelt til denne MSL',
  SHOW_ONLY: 'Vis kun',
  SALES_REP_LIST: 'Liste over salgsrepræsentanter',
  ACTIVE_TASK: 'Aktivér opgave',
  CHOOSE_USER_AND_SET_THE_SHIFT_: 'Vælg bruger, og indstil skiftet for begivenheden',
  MANAGE_SHIFTS: 'Håndter skift',
  CREATE_NEW_USER: 'Opret ny bruger',
  THIS_TEMPLATE_WILL_BE_AUTO_: 'Denne skabelon tilføjes automatisk til dit teammedlem.',
  TEMPLATE_FOR: 'Skabelon til',
  Day: 'Dag',
  Week: 'Uge',
  Month: 'Måned',
  Today: 'I dag',
  Archive: 'Arkiv',
  PARTICIPANTS: 'DELTAGERE',
  EVENT_MANAGEMENT: 'EVENT MANAGEMENT',
  ADD_TASK: 'Tilføj opgave',
  EDIT_TASK: 'Rediger opgave',
  IS_ACTIVE: 'Er aktiv',
  Months: 'måneder',
  Days: 'Dage',
  Hours: 'timer',
  Minutes: 'minutter',
  HCP_ADMINISTRATION: 'HCP-ADMINISTRATION',
  AUTH_REQ_ADMINISTRATION: 'Autentisk anmodning om administration',
  THE_PRODUCT_WILL_BE_HIGHLIGHTED_IN_: 'Produktet fremhæves i de valgte terapeutiske områder.',
  PRODUCT_GROUPS_DEFINE_:
    'Produktgrupper definerer det team, der er ansvarligt for at besvare dette produkthenvendelser.',
  USER_ADMINISTRATION: 'Brugernes administration',
  IS_PRODUCT_SPECIFIC: 'Er produktspecifik',
  USER_TEMPLATES_ADMIN: 'Administration af brugerskabeloner',
  SET_DEFAULT_VALUES: 'INDSTILL DEFAULT VÆRDIER',
  SET_DEFAULT_VALUES_FOR_: 'Indstil standardværdier for begivenheden',
  INQ_DEFAULT_VAL: 'Forespørgselsværdier',
  INQUIRY_DEFAULT_VAL: 'Forespørgsel standardværdier',
  CREATE_NEW_EVENT: 'Opret nyt begivenhed',
  EDIT_EVENT: 'REDIGER EVENT',
  SET_DETAILS_TO_CREATE_NEW_EVENT: 'Angiv detaljer for at oprette en ny begivenhed',
  ADD_DOC_LINKED_TO_: 'Tilføj dokumenter, der er knyttet til begivenheden',
  INC_CHANNEL: 'Inc. Channel',
  CHANGE_DETAILS_OF_THE_EVENT: 'Skift detaljer om begivenheden',
  DESCRIPTION: 'Beskrivelse',
  CREATE_APPROVAL_PROCESS: 'Vælg godkendelsesproces',
  CREATE_NEW: 'Lav ny',
  CUSTOMIZE_PROCESS: 'Tilpas proces',
  SELECTED_PROCESS: 'Valgt proces',
  CLONE: 'Klon',
  PREFERENCES_TITLE: 'Indstillinger',
  NOTIFICATIONS_TITLE: 'underretninger',
  TEMPLATES: 'Skabeloner',
  LETTER_TEMPLATES: 'Brev skabelon',
  USER_TEMPLATES: 'Brugerskabeloner',
  TEAM_MEMBERS: 'Holdkammerater',
  NEW_PASSWORD: 'Nyt kodeord',
  REPEAT_NEW_PASSWORD: 'Gentag den nye adgangskode',
  AT_LEAST_1_LOWERCASE_CHARACTER: 'Mindst 1 bogstaver',
  AT_LEAST_1_UPPERCASE_CHARACTER: 'Mindst 1 store bogstaver',
  AT_LEAST_1_DIGIT: 'Mindst 1 cifret',
  AT_LEAST_1_SPECIAL_CHARACTER: 'Mindst 1 specialtegn',
  MINIMUM_8_CHARACTERS: 'Minimum 8 tegn',
  SHOULD_NOT_CONTAIN_EMAIL_OR_NAMES: 'Bør ikke indeholde e-mail eller navne',
  SHOULD_MATCH_PASSWORD: 'Skal matche adgangskode',
  USER_MANUAL: 'Brugermanual',
  SUPPORT: 'Support',
  BACK_TO_LOGIN: 'Tilbage til login',
  PASSWORD_CONFIRMATION: 'Kodeords bekræftelse',
  HAVE_AN_ACCOUNT: 'Har du allerede en konto?',
  Submit: 'Indsend',
  Gender: 'Køn',
  PROFESSION: 'Erhverv',
  MEDICAL_INQ_ROLES: 'Medicinske undersøgelser Behandling af rolle',
  MEDICAL_INFO_MANAGER: 'Medicinsk informationschef',
  MEDICAL_SCIENCE_LIAISON: 'Liaison for medicinsk videnskab',
  NOT_PROCESSING_INQUIRIES: 'Behandler ikke forespørgsler',
  ACTIVE_HEALTH_CARE_SPEC: 'Aktiv sundhedsvæsen specialist',
  NOT_ACTIVE_HEALTH_CARE_SPEC: 'Ikke aktiv sundhedsplejer',
  PASS_IS_REQUIRED: 'Adgangskode er påkrævet!',
  EMAIL_IS_REQUIRED: 'E-mail er påkrævet!',
  VALID_EMAIL: 'Indtast venligst en gyldig e-mailadresse!',
  FIELD_IS_REQUIRED: 'Dette felt er påkrævet!',
  ONLY_LETTERS: 'Indtast kun bogstaver!',
  ARE_YOU_daiichi_EMPLOYEE: 'Er du Daiichi Sankyo medarbejder?',
  here: 'her',
  CREATE_AN_ACCOUNT: 'Opret en konto',
  FORGOT: 'Glemte?',
  Medical: 'Medicinsk',
  Medical_not_resp: "Medicinsk - ikke ansvarlig for at reagere på MIR'er",
  Commercial: 'Kommerciel',
  Market_access: 'Markedsadgang',
  Pharmacovigilance: 'Lægemiddelovervågning',
  PR: 'PR',
  Please_specify: 'Vær venlig at uddybe',
  Medical_information_manager: 'Medicinsk informationschef',
  Medical_advisor: 'Medicinsk rådgiver',
  Are_Medical_inquiries: 'Er du ansvarlig for at besvare medicinske forespørgsler?',
  Are_Health_care_professional: 'Er du praktiserende professionel inden for sundhedsvæsenet?',
  Health_care_Industry: 'Sundhedsvæsenets industri',
  Health_Authority: 'Sundhedsmyndighed',
  Journalist: 'Journalist',
  Lawyer: 'Jurist',
  Patient: 'Patient',
  Payor: 'payor',
  Scientist: 'Videnskabsmand',
  Angiologist: 'Angiologist',
  Cardiologist: 'kardiolog',
  Cardiovascular_Surgeon: 'Kardiovaskulær kirurg',
  Dentist: 'Tandlæge',
  Gastroenterologist: 'gastroenterolog',
  General_Practitioner: 'Allmennpraktiker',
  Geriatrician: 'geriatrician',
  Gynecologist: 'Gynækolog',
  Haematologist: 'hæmatolog',
  Internist: 'Internist',
  Medical_Student: 'Medicinstuderende',
  Nephrologist: 'nephrologist',
  Neurologist: 'neurolog',
  Neurosurgeon: 'neurosurgeon',
  Nurse: 'Amme',
  Oncologist: 'onkologer',
  Oncology_Nurse: 'Onkologi sygeplejerske',
  Other_HCP: 'Andet HCP',
  Other_Surgeon: 'Anden kirurg',
  Pediatrician: 'Børnelæge',
  Pharmacist: 'Farmaceut',
  Pharmacologist: 'farmakolog',
  Pharmacy_Technician: 'Apoteketekniker',
  Pneumologist: 'Pneumologist',
  Radiologist: 'radiolog',
  Rheumatologist: 'reumatolog',
  USER_PASS_INCORRECT: 'Brugernavn eller adgangskode er forkert',
  SUBMITTED_REQUEST: 'Din anmodning blev sendt.',
  Reset: 'Nulstil',
  Reset_pass: 'Nulstille kodeord',
  New_pass: 'nyt kodeord',
  RESET_PASS_EMAIL: 'Der blev sendt et link til nulstilling af adgangskode til din e-mail',
  NEW_PASS_SUCCESS: 'Din adgangskode blev nulstillet',
  SOMETHING_WENT_WRONG: 'Der gik noget galt. Kontakt supporten.',
  EVENTS: 'Begivenheder',
  Login_here: 'Log ind her',
  REGISTRATION: 'Registrering',
  Create_an_account: 'Opret en konto',
  NO_RECORDS: 'Ingen poster',
  INTERACTION: 'Interaktion',
  SPECIALIZATION: 'specialisering',
  SPECIALIZATION_TYPE: 'Specialisering Type',
  SAVED: 'Gemte',
  SAVING: 'Lagring',
  TYPING: 'Typing',
  CREATE_NEW_INTERACTION: 'Opret ny interaktion',
  FILTER_BY: 'Sorter efter',
  SEND_EMAIL: 'Send e-mail',
  SAVE_WITHOUT_EMAIL: 'Gem uden e-mail',
  SUBMIT_TO_INBOX: 'Send til indbakke',
  SELECT_INQUIRER: 'Vælg forespørgsel',
  ADR_OBLIGATION_CONSENT_AND_INQUIRER_:
    'ADR-forpligtelse samtykke og forespørger skal vælges for at indsende interaktionen',
  Create_my_acc: 'Lav min profil',
  NEW_QUESTION: 'Nyt spørgsmål',
  LABEL: 'Etiket',
  SELECT_A_LABEL: 'Vælg en etiket',
  CITY: 'by',
  SUGGEST_A_NEW_CATEGORY_OR_SELECT_: 'Foreslå en ny kategori, eller vælg en eksisterende',
  SUGGEST_CATEGORY_OR_TOPIC: 'Foreslå ny kategori eller emne',
  URGENT: 'Presserende',
  SAVE_INQUIRER: 'Gem forespørgsel',
  EXISTING_CATEGORY: 'Eksisterende kategori',
  NEW_CATEGORY_NAME: 'Nyt kategorinavn',
  NEW_TOPIC_NAME: 'Nyt emne navn',
  CATEGORY: 'Kategori',
  MSLA: 'MSLA',
  OUT_CHANNEL: 'Udgående kanal',
  LOGIN_TO_CONTINUE: 'Log ind for at fortsætte til Madjenta',
  Email_address: 'Email adresse',
  FORGOT_PASS: 'Glemt kodeord?',
  Remember_me: 'Husk mig',
  YOU_WILL_BE_NOTIFIED:
    'Bekræftelses-e-mail blev sendt med yderligere instruktioner til din registrering',
  SUCCESS_EMAIL_CONFIRMATION: 'Din e-mail blev bekræftet.',
  SUCCESS_EMAIL_CONFIRMATION_SUB: 'Du får besked, når din anmodning er gennemgået',
  FAILED_EMAIL_CONFIRMATION: 'Vi kan ikke bekræfte din e-mail.',
  FAILED_EMAIL_CONFIRMATION_SUB:
    'Prøv igen, og hvis problemet fortsætter, skriv til support med beskrivelse af problemet',
  RESET_FILTERS: 'Nulstil filtre',
  NEW_PASS_RESET_SUCCESS: 'Nulstil adgangskode!',
  Cardiovascular: 'Hjerte-kar-',
  Oncology: 'Onkologi',
  Due_On: 'På grund af',
  Follow_Up_Sent_On: 'Opfølgning sendt på',
  Follow_Up_Received_On: 'Opfølgning modtaget',
  Closed_On: 'Lukket på',
  Reopened_On: 'Åbnet igen',
  Inquiry_ID: 'Forespørgsels-ID',
  Search: 'Søg',
  Assigned_to_Now: 'Tildelt til (nu)',
  Edited_By: 'Redigeret af',
  Assigned_to_Any_Time: 'Tildelt til (når som helst)',
  Closed: 'Lukket',
  Foreign: 'Udenlandsk',
  Complex_search_Sentence_: 'Kompleks søgning: Setning: "sætning til søgning", ikke: -ord',
  Search_inquiries: 'Søg forespørgsler',
  SEARCH_REPOSITORY: 'Søgeplads',
  MIR_INBOX: 'MIR INBOX',
  Filters: 'filtre',
  Active_Filters: 'Aktive filtre',
  CONTACT_SUPPORT: 'Kontakt support',
  MedicalInformationRequests: 'Forespørgsler (pdf)',
  UserProfileNavigation: 'Brugerprofil (pdf)',
  UserManualChanges: 'Brugermanual Ændringer (docx)',
  iPad_How_To: 'iPad (pdf)',
  MedIS_Assign_Inquiry: 'Tildel forespørgsel',
  MedIS_Documentation_Approval_Process: 'Dokumentationsgodkendelsesproces',
  MedIS_Documentation_Overview: 'Dokumentationsoversigt',
  MedIS_Inquiry_Documentation: 'Forespørgseldokumentation',
  MedIS_MIR_Inbox_Filters: 'MIR-indbakkefiltre',
  MedIS_Merge_MIR: 'Flet MIR',
  MedIS_New_Documentation: 'Ny dokumentation',
  MedIS_New_Inquiry: 'Ny undersøgelse',
  MedIS_Out_Of_Office: 'Ikke på kontoret',
  MedIS_Prepare_Custom_Answer: 'Forbered tilpasset svar',
  MedIS_Split_MIR_Part_I: 'Opdel MIR del I',
  MedIS_Split_MIR_Part_II: 'Opdel MIR del II',
  I_AGREE_TO: 'Jeg er enig',
  TermsAndConditionsLink: 'Betingelser og vilkår',
  Title: 'Titel',
  INTERACTION_TITLE: 'Interaktionstitel',
  Move: 'Bevæge sig',
  Left: 'Venstre',
  Right: 'Højre',
  Requestor: 'Anmoders',
  Assigned_To: 'Tildelt',
  Status: 'status',
  Note: 'Bemærk',
  Confirm: 'Bekræfte',
  rejectConfirm: 'Er du sikker på, at du vil afvise anmodningen?',
  approveConfirm: 'Er du sikker på, at du vil godkende anmodningen?',
  newUser: 'Ny bruger',
  editUser: 'Rediger bruger',
  deleteUser: 'Slet bruger',
  newProduct: 'Nyt produkt',
  editProduct: 'Rediger produkt',
  deleteProduct: 'Slet produkt',
  newCategory: 'Ny kategori',
  editCategory: 'Rediger kategori',
  deleteCategory: 'Slet kategori',
  newTopic: 'Nyt emne',
  editTopic: 'Rediger emne',
  deleteTopic: 'Slet emne',
  userRequest: 'Brugeranmodning',
  productRequest: 'Produktanmodning',
  categoryRequest: 'Kategori-anmodning',
  categoryTopicRequest: 'Kategori / emneanmodning',
  topicRequest: 'Emneanmodning',
  Suggestion: 'Forslag',
  tradeName: 'Handelsnavn',
  activeSubstance: 'Aktivt stof',
  productName: 'produktnavn',
  productSpecific: 'Er det produktspecifikt?',
  EMAIL_IN_USE: 'Der er en eksisterende konto med denne e-mail',
  fromDate: 'Fra dato',
  toDate: 'Til dato',
  applyFilter: 'Anvend filter',
  requestReceivedFrom: 'Anmodning modtaget fra',
  on: 'på',
  at: 'på',
  to: 'til',
  add: 'tilføje',
  from: 'fra',
  approve: 'godkende',
  reject: 'afvise',
  withTheFollowingRole: 'med følgende rolle',
  forTheFollowingTeam: 'for følgende hold',
  request: 'anmodning',
  submittedBy: 'indsendt af',
  INVALID_OR_EXPIRED_RESET_REQUEST:
    'Anmodningen om nulstilling af adgangskode er ugyldig eller udløbet',
  PASS_IS_RECENT: 'Adgangskode er for nylig brugt',
  Assigned_to_me: 'Tildelt til mig',
  My_drafts: 'Mine udkast',
  My_open_inquiries: 'Mine åbne henvendelser',
  My_closed_inquiries: 'Mine lukkede henvendelser',
  Filter_inquiries_by: 'Filtrer forespørgsler efter',
  New_inq: 'Ny undersøgelse',
  Inq_inbox: 'Forespørgsler Indbakke',
  REPORTS: 'Rapporter',
  My_teams_open_inq: 'Mit teams åbne henvendelser',
  My_teams_closed_inq: 'Mit teams afsluttede henvendelser',
  All_teams_open_inq: 'Alle hold åbner forespørgsler',
  All_teams_closed_inq: 'Alle hold lukkede forespørgsler',
  Overdue: 'Forsinket',
  Due_this_week: 'På grund af denne uge',
  Pending_approval: 'Afventer godkendelse',
  ADMINISTRATION: 'Administration',
  Show_all: 'Vis alt',
  In_progress: 'I gang',
  Table_Settings_Columns: 'Tabelindstillinger - Kolonner',
  Change_column_visibility: 'Skift søjlesynlighed',
  Reassign_to: 'Overfør til',
  set_priority: 'Indstil prioritet',
  set_status: 'Indstil status',
  Spam_list: 'Spam-liste',
  Institution: 'Institution',
  DUE_ON: 'På grund af',
  Profile_settings: 'Profilindstillinger',
  Preferences: 'Indstillinger',
  Role_settings: 'Rolleindstillinger',
  Notification_settings: 'Underretningsindstillinger',
  User_profile: 'Brugerprofil',
  Select_person: 'Vælg person',
  FROM_DATE: 'Fra dato',
  TO_DATE: 'Til dato',
  JOB_DESC: 'Job beskrivelse',
  INQ_Management: 'Forespørgselsstyring',
  DOCS_Management: 'Dokumenthåndtering',
  USER_Management: 'Brugeradministration',
  Define_Frequency: 'Definer frekvens',
  All_teams_all_inq: 'Alle henvendelser til alle hold',
  My_teams_all_inq: 'Alle henvendelser til mit team',
  Search_people_here: 'Søg mennesker her',
  Search_team_here: 'Søg team her',
  people: 'MENNESKER',
  teams: 'HOLD',
  empty: '(tom)',
  can_not_be_assigned: 'Denne bruger har intet team og kan ikke tildeles',
  select_a_team: 'Vælg et team til',
  confirm_team_change: 'Bekræft holdskift',
  change_the_team_: 'Er du sikker på, at du vil ændre holdet til',
  can_not_see_it_anymore: 'Du kan ikke se det i nuværende',
  filtered_view: 'filtreret visning.',
  PHONE: 'telefon',
  FAX: 'Fax',
  LETTER: 'Brev',
  F2F_SITE_VISIT: 'F2F (Besøg på webstedet)',
  F2F_CONGRESS: 'F2F (Kongres)',
  F2F_ADBOARD: 'F2F (AdBoard)',
  F2F_OTHER: 'F2F (Andet)',
  WEBINAR_SITE_VISIT: 'Virtuel (Besøg på webstedet)',
  WEBINAR_CONGRESS: 'Virtuel (kongres)',
  WEBINAR_ADBOARD: 'Virtuel (AdBoard)',
  WEBINAR_OTHER: 'Virtuel (Andet)',
  CHAT: 'Snak',
  COURIER: 'kurer',
  REP_DELIVERY: 'Rep. Levering',
  SYSTEM: 'System',
  WEB_FORM: 'Webformular',
  Signature: 'Underskrift',
  Disclaimer: 'Ansvarsfraskrivelse',
  STATE: 'Stat',
  ADDRESS_1: 'adresse 1',
  ADDRESS_2: 'Adresse 2',
  ZIP_CODE: 'Postnummer',
  HIGH: 'Høj',
  ADVANCED: 'Fremskreden',
  CLICK_BELOW_TO_POPULATE_ANSWER:
    'Klik på teksten og felterne nedenfor for at udfylde og redigere svaret',
  DOCUMENT_VIEW: 'Dokumentvisning',
  FORM_VIEW: 'Formvisning',
  YOU_DONT_HAVE_RIGHTS_TO_ANSWER: 'Du har ikke rettigheder til at sende svaret.',
  ANSWER_TEXT: 'Svartekst',
  CONTACT_INFORMATION: 'Kontakt information',
  COMPANY: 'Selskab',
  SRD_NEEDED_TOOLTIP: 'Svaret på denne forespørgsel kræver nyt SRD-dokument',
  ENABLE_ALL: 'Aktivér alle',
  DISABLE_ALL: 'Slå alt fra',
  BEFORE: 'Før',
  AFTER: 'Efter',
  EDIT: 'Redigere',
  MY_TEMPLATES: 'Mine skabeloner',
  TEAM_TEMPLATES: 'Teamskabeloner',
  TEAM_TEMPLATES_HINT: 'Skabelon oprettet af Medical Information Manager for hele teamet',
  SOURCE: 'Kilde',
  TEMPLATE_NO_MATCH_INTERACTION:
    'Denne skabelon matcher ikke interaktionsoplysningerne og kan ikke anvendes',
  EMAIL_SUBJECT: 'Email emne',
  INQUIRY_SUBMIT_HINT: 'Udfyld venligst alle påkrævede felter for at besvare forespørgslen',
  SCHEDULED_PUBLISH_TO_EXP_DATE: 'Planlagt udgivelse til udløbsdato',
  COPY_ATTACHMENT_TO_ANSWER: 'Kopier vedhæftet fil til svar',
  COPY_TEXT_TO_ANSWER: 'Kopier tekst til svar',
  SELECT_INQUIRY: 'Vælg Forespørgsel',
  EDIT_INQ: 'Rediger spørgsmål',
  SPLIT_ANOTHER: 'Split en anden',
  DELETE_INQ: 'Slet forespørgsel',
  SELECT_TEXT_FOR_SPLIT: 'Tilføj nyt spørgsmål i samme interaktion',
  SELECT_TEXT_FOR_SPLIT_NEW_INTERACTION: 'Generer separat interaktion / sag',
  SPLIT_ORIGINAL_TEXT: 'Oprindelig tekst (Vælg tekst til opdeling)',
  SPLIT_TEXT_TO_REMAIN_: 'Tekst forbliver i den første undersøgelse',
  SPLIT_TEXT_TO_SPLIT_: 'Tekst, der skal deles',
  SPLIT_SELECTED_SECTIONS: 'Valgte sektioner til opdeling',
  SPLIT_NO_MODIFICATIONS_HINT:
    'Teksten udfyldes automatisk, når du vælger sektioner fra den originale tekst',
  SPLIT_MODIFICATIONS_HINT:
    'Teksten er blevet ændret manuelt, og ethvert yderligere valg fra originalteksten anvendes ikke.',
  TEMPLATE_NO_MODIFICATIONS_HINT:
    'Teksten opdateres automatisk med ændringerne i forespørgselsoplysningerne.',
  SELECT_DIFFERENT_TEMPLATE: 'Vælg en anden skabelon',
  APPROVE: 'Godkende',
  LAST_EDIT_ON: 'Sidste redigering til',
  LAST_EDIT_BY: 'Sidste redigering af',
  INTERNAL_COMM: 'Intern kommunikation',
  INACTIVE: 'Inaktiv',
  DEACTIVATE: 'Deaktiver',
  ARCHIVED: 'Arkiveret',
  REPUBLISH: 'Genudgiv',
  INBOX_NO_FILTERS_HINT: 'Der er ikke valgt nogen filtre.',
  INTERACTION_TEMPLATE_HINT:
    'Underskrifter, hilsener og ansvarsfraskrivelser er en del af skriftlig kommunikation, såsom e-mail, brev og fax.',
  CONFIRM_AUTH_REQUEST: 'Bekræft godkendelsesanmodning',
  VIEW_AUTH_REQUEST: 'Se godkendelsesanmodning',
  QUESTION_TITLE: 'Spørgsmålets titel: ',
  NO_TITLE_INQUIRY: '-ingen titel-forespørgsel-',
  EXISTING_CATEGORIES: 'Eksisterende kategorier',
  EXISTING_TOPICS: 'Eksisterende emner',
  DO_NOT_ASSIGN_PRODUCT: 'Tildel ikke produktet',
  QUESTION_TEXT: 'Spørgsmålstekst',
  DATE: 'Dato',
  REJECTED: 'Afvist',
  FILTER_AUTH_REQUESTS_BY: 'Filtrer godkendelsesanmodninger efter',
  ALL_REQUESTS_ALL_TEAMS: 'Alle anmodninger til alle hold',
  ALL_TEAMS: 'Alle hold',
  YOU_DONT_HAVE_RIGHTS_TO_EDIT_REQUEST:
    'Du har ikke rettigheder til at redigere anmodninger om kategori / emne.',
  SEND_FOLLOW_UP: 'Opvolging verzenden',
  RECEIPT_NOTIFICATION_CUSTOMER: 'Ontvangstmelding aan klant',
  GDPR_DISCLAIMER: 'AVG-disclaimer',
  ACTIONS: 'Acties',
  SUBMISSION_CONDITIONS: 'Voorwaarde voor indiening',
  TYPE_OF_NOTIFICATION: 'Type melding',
  DIRECT_SUBMISSION: 'Directe indiening',
  DIRECT_SUBMISSION_TOOLTIP:
    'Voor vragen die rechtstreeks door de klant via e-mail worden ingediend, stuurt u een melding om de klant te informeren over de verwerking van zijn persoonlijke gegevens.',
  COMPANY_EMPLOYEE_SUBMISSION: 'Ingezonden via bedrijfsmedewerker',
  COMPANY_EMPLOYEE_SUBMISSION_TOOLTIP:
    'Voor vragen die zijn ingediend via een bedrijfsvertegenwoordiger (verkoper, MSL of andere), informeer de aanvrager over de verwerking van hun persoonlijke gegevens.',
  ENABLE_NOTIFICATION: 'Notificaties aanzetten',
  NOTIFICATION_SENDER: 'Melding aan afzender',
  SALES_REP_DELIVERED_RESPONSE: 'Verkoper voor geleverde reactie',
  NOTIFICATION_DELIVERED_RESPONSE: 'Melding voor geleverde reactie',
  NOTIFICATION_DELIVERED_RESPONSE_TOOLTIP:
    'Voor vragen die zijn ingediend via een bedrijfsvertegenwoordiger (verkoper, MSL of andere), moet u de afzender op de hoogte stellen wanneer de klant een antwoord op zijn vraag heeft ontvangen',
  FULL_ANSWER: 'Volledig antwoord',
  NOTIFICATION: 'Kennisgeving',
  NAME: 'naam',
  COLUMN_INIT_QUESTION_TEXT: 'Oorspronkelijke vraagtekst',
  NOTIFICATION_ENABLED: 'Melding ingeschakeld',
  DEFAULT_TEMPLATE: 'Standaardsjabloon',
  SET_AS_DEFAULT: 'Stel in als standaard',
  DEFAULT_TEMPLATE_TOOLTIP:
    'Toggle is uitgeschakeld omdat er minimaal één standaardsjabloon moet zijn!',
  DEFAULT_TEMPLATE_TOOLTIP_DELETION_NOT_POSSIBLE: 'Standaardsjabloon kan niet worden verwijderd!',
  BATCH_UPDATE_ONLY: 'Alleen een batch-update is mogelijk via het bovenste selectievakje.',
  ACCESS_DENIED: 'Toegang geweigerd!',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE:
    'U heeft geen toestemming om deze pagina te openen.',
  USER_ROLES: 'Gebruikersrollen',
  MANAGE_USER_ROLES: 'Gebruikersrollen beheren',
  ARCHIVE_ROLE: 'Archiefrol',
  ROLE_ARCHIVE_CONFIRMATION: 'Wil je deze rol echt archiveren?',
  ROLES_FOR: 'Rollen voor',
  AUDIT_TRAIL: 'Auditspoor',
  ACTION_BY_USER: 'Actie door gebruiker',
  ACTION: 'Actie',
  ORIGINAL_VALUE: 'Oorspronkelijke waarde',
  CHANGE: 'Wijzigen',
  CONDITION: 'Conditie',
  IP: 'IP',
  GEOLOCATION: 'Geolocatie',
  NEW_VALUE: 'nieuwe waarde',
  FILTER_BY_ACTION: 'Filter op actie',
  SELECT_DATE: 'Selecteer datum',
  TEAM_SAVE: 'Opslaan',
  TEAM_DELETE: 'Verwijderen',
  TEAM_CREATE: 'Creëren',
  TEAM_CLONE: 'Kopiëren',
  TEAM_NAME: 'Naam',
  TEAM_CODE: 'Code',
  TEAM_ACTION_DELETE_CONFIRM: 'Wil je echt verwijderen',
  TEAM_ACTION_DELETE_CONFIRM_TITLE: 'Doe voorzichtig!',
  TEAM_ACTION_DELETE_CONFIRM_EXTENDED: 'Eenmaal verwijderd, kan het niet worden hersteld!',
  TEAM_FULL_ANSWER_ON_LABEL: 'Volledig antwoord op label',
  TEAM_RESTRICTED_TEAM: 'Beperkt',
  TEAM_NOTIFY_INQUIRER: 'Informeer de aanvraag op de hoogte',
  TEAM_SEGREGATED_DATA: 'Gescheiden gegevens',
  TEAM_PRIVATE_TEAM: 'Privé -team',
  TEAM_AUTO_ASSIGN: 'Automatisch toekennen',
  TEAM_MAILBOX_IN: 'Inkomende mailbox',
  TEAM_MAILBOX_OUT: 'Uitgaande mailbox',
  TEAM_DEFAULT_LANGUAGE: 'Standaard taal',
  TEAM_COUNTRIES: 'Toegewezen landen',
  TEAM_PRODUCT_GROUPS: 'Wijs productgroepen toe',
  TEAM_PARENT: 'Ouderteam',
  TEAM_OVERRIDE_CLUSTER_ROOT: 'Overschrijven cluster root -team',
  TEAM_AFFILIATIONS: 'Aangesloten teams',
  TEAM_TIMEZONE: 'Tijdzone',
  INQUIRER_TYPE: 'Type aanvraag',
  UPDATE: 'Update',
  AUTO_ASSIGN_BY: 'Auto toewijzen door',
  AUTO_ASSIGNMENTS: 'Auto -opdrachten',
  NO_ONE: 'Niemand',
  NOT_APPLICABLE: 'Niet toepasbaar',
  OVERRIDE_NOTIFICATIONS: 'Meldingen overschrijven',
  OVERRIDE_NOTIFICATIONS_HINT:
    'Wanneer gebruikerssjabloon met override -meldingen op de gebruiker is ingesteld, wordt het bestek van reeds bestaande meldingsinstellingen en schakelt u alleen de meldingsinstellingen in de gebruikerssjabloon in',
  CREATED_MANUALLY: 'Handmatig gemaakt',
  DOCUMENT_TITLE_FOR_WORD:
    'De titel voor Word -documenten is meestal afkomstig uit titelgemaakte sectie binnen het document zelf. Als het document geen titel bevat, kunt u het hier opgeven. Als u hier niet de titel opgeeft en het Word -document bevat geen titelgemaakte sectie, dan zou de document -ID worden gebruikt voor de titel.',
  ORDER_BY: 'Bestellen door',
  FORMAT: 'Formaat',
  SELECT_QUESTION: 'Selecteer vraag',
  SELECT_QUESTION_FOR_NOTE: 'Selecteer de vraag waarvoor u opmerking wilt toevoegen',
  ADD_NOTE_FOR_WHOLE_ENQUIRY: 'Noot toevoegen voor hele aanvraag',
  REQUESTED_DATE: 'Gevraagde datum',
  UNASSIGNED: 'Niet toegewezen',
  BLANK: 'Blanco',
  ANSWER_SAVED_WITHOUT_EMAIL: 'Antwoord opgeslagen zonder e -mail',
  CHANGE_INQUIRER: 'Veranderaanvraag',
  INQ_RELATED_TO: 'Vragen met betrekking tot',
  UPDATED_INQUIRER_DETAILS: 'Bijgewerkte onderzoeksdetails',
  CREATED_INQUIRER: 'Opgericht onderzoeker',
  QUESTION_TEXT_BEFORE_SPLIT: 'Vraag tekst voor gesplitst',
  SPLIT_MERGE: 'Splitsen en samenvoegen',
  CLARIFICATION_QUESTION: 'Opheldering',
  ACTIVE_ON: 'Actief op',
  DISMISS: 'Afwijzen',
  GO_TO_ENQUIRY: 'Ga naar onderzoek',
  NO_TEAMS: 'Zonder team',
  NO_TEAMS_TIP:
    'Bevat gebruikers, die aan geen enkel team zijn toegewezen. U kunt controleren of sommige daarvan uit uw team zijn en hen de juiste rol geven.',
  TASKS: 'Taken',
  Therapeutic_Areas: 'Therapeutische gebieden',
  Product_Groups: 'Productgroepen',
  Licensing_Partner: 'Licentiepartner',
  Product_Code: 'Productcode',
  SELECT_EXISTING_ONE: 'Selecteer bestaande',
  OR: 'of',
  MANAGE_FORM: 'Formulier beheren',
  EVENT_FORM_ADDRESS: 'Evenementformulieradres',
  EVENT_FORM_PREVIEW_WARING:
    'Zorg ervoor dat u wijzigingen hebt opgeslagen voordat u het congresformulier hebt geopend of het adres kopieert',
  SEARCH_SAVED_SEARCHES: 'Zoek opgeslagen zoekopdrachten',
  GO: 'Gaan',
  ASSIGN: 'Toewijzen',
  CERTIFICATION: 'Certificering',
  SORT: 'Soort',
  CREATE_NEW_MAILBOX: 'Maak een nieuwe mailbox',
  EDIT_MAILBOX: 'Bewerk mailbox',
  ADD_MAILBOX: 'Voeg mailbox toe',
  UNSET_INCOMING_MAILBOX: 'Unset Inkomende mailbox',
  SET_INCOMING_MAILBOX: 'Stel inkomende mailbox in',
  UNSET_OUTGOING_MAILBOX: 'UNTSET OUTLATTE MAAMBOX',
  SET_OUTGOING_MAILBOX: 'Zet een uitgaande mailbox',
  ALL_TEAMS_WITH_CURRENT_MAILBOX: 'Alle teams met de huidige mailbox',
  MAILBOX_HISTORY: 'Mailbox -geschiedenis',
  ALL_TEAMS_WITH_MAILBOX: 'Alle teams met mailbox',
  MAILBOX: 'Postbus',
  EMAIL_POLLING: 'E -mailpeiling',
  ADVANCED_SEARCH: 'geavanceerd zoeken',
  SELECT_FIELDS: 'Selecteer velden',
  SEARCH_TERM: 'Zoekterm',
  COLUMN_INTERACTION_ID: 'Interactie -ID',
  COLUMN_QUESTION_ID: 'Vraag ID',
  COLUMN_CREATED_ON: 'Gemaakt op',
  COLUMN_LAST_EDITED_DATE: 'Laatst bewerkt op',
  COLUMN_ASSIGNED_TO: 'Toewijzen',
  COLUMN_DUE_DATE: 'Opleveringsdatum',
  COLUMN_FOLLOW_SENT_DATE: 'Follow-up verzonden datum',
  COLUMN_FOLLOW_RECEIVED_DATE: 'Follow-up ontvangen datum',
  COLUMN_ANSWER_DATE: 'Antwoorddatum',
  COLUMN_CLOSED_ON_DATE: 'Gesloten op datum',
  COLUMN_REOPENED_DATE: 'Opnieuw geopend datum',
  COLUMN_INSERTED_BY: 'Ingevoegd door',
  COLUMN_LAST_EDITED_BY: 'Laatst bewerkt door',
  COLUMN_ANSWERED_BY: 'Beantwoord door',
  COLUMN_APPROVED_BY: 'Goedgekeurd door',
  COLUMN_INT_COMM_USER: 'Interne communicatie -gebruiker',
  COLUMN_INT_COMM_DATE: 'Interne communicatiedatum',
  COLUMN_MY_TEAM: 'Mijn team',
  COLUMN_PQC_RELATED: 'PQC gerelateerd',
  COLUMN_INCOMING_CHANNEL: 'Inkomend kanaal',
  COLUMN_OUTGOING_CHANNEL: 'Uitgaande kanaal',
  COLUMN_OFF_LABEL: 'Label',
  COLUMN_HCP_SPEC: 'HCP + specialisatie',
  COLUMN_NON_HCP_SPEC: 'Niet-HCP + beroep',
  COLUMN_KEE: 'Stoot',
  COLUMN_HOSPITAL_BASED: 'Ziekenhuis',
  COLUMN_CITY: 'Stad',
  COLUMN_INSTITUTION: 'Instelling',
  COLUMN_TERRITORY: 'Grondgebied',
  CASE_ID: 'Case id',
  OPTION_ADD: 'TOEVOEGEN',
  OPTION_AND: 'EN',
  OPTION_OR: 'OF',
  OPTION_NOT: 'NIET',
  OPTION_YES: 'Ja',
  OPTION_NO: 'Nee',
  QUERY: 'Vraag',
  EXPORT_LABEL: 'Definieer exportkolommen',
  SAVE_SEARCH: 'Zoekopdracht opslaan',
  SHARE_SEARCH: 'Deel zoeken',
  SEARCH_BTN: 'Zoeken',
  BACK_ACTION: 'Rug',
  SAVE_CHANGES: 'Wijzigingen opslaan',
  SAVED_SEARCH: 'Opgeslagen zoekopdracht',
  NEW: 'Nieuw',
  SAVE_AS: 'Opslaan als naam',
  ENTER_RECIPIENTS: 'Voer ontvangers in',
  CLEAR_SAVED_SEARCH: 'Opgenomen opgeslagen zoekopdrachten',
  NO_SAVED_SEARCH: 'Geen opgeslagen zoekopdracht gevonden',
  INT_COMM_TEXT: 'Interne communicatietekst',
  FOLLOW_UP_TEXT: 'Vervolgtekst',
  CONFIRM_DELETION: 'Bevestig verwijdering',
  ARE_SURE: 'Weet je zeker dat je de opgeslagen zoekopdracht wilt verwijderen',
  PERMANENTLY_DELETED: 'Zoeken wordt permanent <b> verwijderd </b>.',
  NOTHING_SAVE: 'Niets te redden.',
  NOTHING_SEARCH: 'Niets om te zoeken.',
  NOTHING_EXPORT: 'Zoeken heeft geen record voor exporteren.',
  SHARE_SUCCESS: 'Zoeken is met succes gedeeld',
  SHARE_FAILURE: 'Zoeken kon niet worden gedeeld',
  DELETED_SUCCESS: 'Met succes opgeslagen opgeslagen zoekopdracht',
  ALL_DELETED_SUCCESS: 'Met succes alle opgeslagen zoekopdrachten verwijderd',
  VIEW_SAVED_SEARCH: 'Bekijk opgeslagen zoekopdracht',
  EDIT_SAVED_SEARCH: 'Bewerk opgeslagen zoekopdracht',
  EXPORT_SAVED_SEARCH: 'Exporteren opgeslagen zoekopdracht',
  EXPORT_SEARCH: 'Export zoeken',
  DELETE_SAVED_SEARCH: 'Verwijder opgeslagen zoekopdracht',
  SELECT_EXPORT_COLUMNS: 'Selecteer kolommen om te exporteren',
  SAVED_SEARCH_ALREADY_EXIST: 'Zoeken met die naam bestaat al.',
  CHARACTER_LIMIT: 'Min 3 tekens. Max 35 tekens.',
  CHARACTER_LEFT: 'Tekens zijn over',
  ACTIVATE: 'Activeren',
  INACTIVE_USER: 'Deze gebruiker is niet langer actief. Neem contact op met uw systeembeheerder.',
  FOLLOW_UP: 'Opvolgen',
  USER_NOT_FOUND: 'Gebruiker niet gevonden',
  DEPARTMENTS: 'Afdelingen',
  EMAIL: 'E -mail',
  ADD_NEW_DEPARTMENT: 'Voeg een nieuwe afdeling toe',
  DEPARTMENT_COUNTRY: 'LAND',
  DEPARTMENT_COMPANY: 'BEDRIJF',
  DEPARTMENT_TEAM: 'TEAM',
  DEPARTMENT_TA: 'Therapeutisch gebied',
  DEPARTMENT_PRODUCT: 'PRODUCT',
  DEPARTMENT_ACTIONS: 'Acties',
  DEPARTMENT_CONFIRMATION_1: 'Bevestig verwijdering?',
  DEPARTMENT_CONFIRMATION_2: 'Deze actie is onomkeerbaar.',
  DEPARTMENT_NAME: 'Afdelingsnaam',
  TYPE_DEPARTMENT: 'Type',
  COUNTRY_PLEASE_SELECT_TEAM_FIRST: 'Land - Selecteer eerst team',
  DEP_NAME_CHARACTER_LIMIT: 'Max 200 tekens.',
  AUTH_REQUESTS: {
    TITLE: {
      UserRequest: 'Gebruikersverzoek',
      CategoryTopicRequest: 'Categorie/onderwerpverzoek',
      ProductRequest: 'Productverzoek',
      UserUpdateRequest: 'Gebruikersupdate -verzoek',
    },
    TYPE: {
      NewUser: 'Nieuwe gebruiker',
      NewCategoryTopic: 'Nieuwe categorie/onderwerp',
      NewUserUpdate: 'Gebruikersupdate',
    },
  },
  ADMINISTRATION_SECTION: {
    SPECIALIZATION: {
      TYPE: 'Type aanvraag',
      SPECIALIZATION_EXISTS: 'Configuratie voor "onderzoekstype/specialisatie" bestaat al.',
      DEFINE_COUNTRY_SPECIFIC_SPECIALIZATIONS: 'Definieer landspecifieke specialisatielijst',
      IS_COUNTRY_SPECIFIC: 'Is landspecifiek',
    },
    AUTHORIZATION_REQUESTS: { GENDER: 'Geslacht', COUNTRY: 'Land', TITLE: 'Titel' },
  },
  hcp: 'HCP',
  emp: 'Medewerker',
  cons: 'Niet-HCP',
  OOO_DEPUTY: 'Ooo plaatsvervanger',
  NO_DEPUTY: 'Geen plaatsvervanger',
  UNSPECIFIED_END_DATE: 'niet -gespecificeerde einddatum',
  DELEGATORS: 'Delegators',
  NO_DELEGATORS: 'Geen delegators',
  PREVIEW_DELEGATORS: 'Voorbeeld van delegators',
  REMOVE_DEPUTY: 'Verwijder plaatsvervanger',
  USERS_WHO_SET_YOU_AS_DEPUTY: 'Gebruikers die u als plaatsvervanger hebben',
  CHANGED_USER: 'Gewijzigde gebruiker',
  DEFAULT: 'Standaard',
  SPECIALIZATIONS: 'Specialisaties',
  ENQUIRERS_LIST: 'Enquirers -lijst',
  EDIT_THE_RECORD: 'Bewerk het record',
  DEACTIVATE_THE_RECORD: 'Deactiveer het record',
  USER_PROFILE: {
    OUT_OF_OFFICE: {
      OUT_OF_OFFICE: 'Niet op kantoor',
      HINT: 'Alleen actieve gebruikers, met onderzoeksrol, die niet op kantoor zijn, zijn beschikbaar voor selectie.',
      MODAL_TITLE: 'Bevestig uit kantoorinstellingen',
      MODAL_MSG_1:
        'U hebt met onmiddellijke ingang van de kantooroptie vrijgesproken, al uw vragen zullen onmiddellijk worden toegewezen aan de plaatsvervanger.',
      MODAL_MSG_2:
        'U hebt de optie op kantoor gezet, al uw vragen zullen tijdens de Out of Office -periode aan de afgevaardigde worden toegewezen.',
    },
  },
  GENDER_MALE: 'Dhr.',
  GENDER_FEMALE: 'Mevr.',
  TITLE_DR: 'Dr.',
  TITLE_PHD: 'Promotie',
  TITLE_PROF: 'Prof.',
  TITLE_PROF_DR: 'Prof. Dr.',
  HOME: 'Huis',
  HOSPITAL: 'Ziekenhuis',
  OFFICE: 'Kantoor',
  WORK: 'Werk',
  INSERT_IMAGE: 'Voeg afbeelding in',
  USER_ADMIN: 'Gebruikersbeheerder',
  MAILBOXES: 'Brievenbussen',
  AUTHORIZATION_REQUESTS: 'Autorisatieverzoeken',
  METADATA: 'Metadata',
  NOTIFICATIONS_PAGE: {
    TITLES: {
      NEW_INQUIRY: 'Nieuw onderzoek',
      ASSIGNMENT: 'Opdracht',
      FORWARD: 'Naar voren',
      FOLLOW_UP: 'Opvolgen',
      INQUIRY_APPROVAL: 'Goedkeuring',
      DUE_DATE_SETTINGS: 'Vervaldatum instellingen',
      CLOSE: 'Dichtbij',
      DELIVERY_ERROR: 'Leveringsfout',
      SCHEDULER: 'Planner',
      USER_REPORTS: 'Gebruikersrapporten',
      DOCUMENT_APPROVAL: 'Goedkeuring van documenten',
      AUTHORIZATION_REQUEST: 'Autorisatieverzoeken',
    },
    TYPES: {
      ANSWER_1: 'Een onderzoek werd door mij beantwoord',
      ANSWER_2: 'Een onderzoek waar ik bij betrokken was, werd beantwoord',
      ASSIGNMENT_1: 'Een onderzoek werd mij toegewezen',
      AUTHORIZATION_REQUEST_1: 'Een rolverzoek werd goedgekeurd/afgewezen',
      AUTHORIZATION_REQUEST_3: 'Een rol is wachten op mijn goedkeuring',
      CLOSE_1: 'Een onderzoek was gesloten',
      DOCUMENT_APPROVAL_1: 'Een document wacht op uw goedkeuring',
      DOCUMENT_APPROVAL_2: 'Een document werd goedgekeurd/afgewezen',
      DOCUMENT_APPROVAL_3: 'Een document werd gearchiveerd',
      DOCUMENT_APPROVAL_4: 'Een document werd goedgekeurd met wijzigingen',
      DOCUMENT_APPROVAL_5: 'Een document zal worden gearchiveerd',
      DOCUMENT_APPROVAL_6: 'Lijst met goedgekeurde documenten',
      DUE_DATE_1:
        'Een onderzoek waaraan ik ben toegewezen is in de buurt van de vervaldatum, informeer me',
      DUE_DATE_2: 'Herhaal na verleden',
      ERROR_1: 'Fout van de leveringsmelding (bijv. Onjuiste e -mail)',
      FOLLOW_UP_1: 'Een follow -up of herinnering werd naar de klant verzonden',
      FOLLOW_UP_4: 'Er is een follow -up ontvangen',
      FOLLOW_UP_7: 'Een onderzoek ging verloren om op te volgen',
      FORWARD_1: 'Een onderzoek werd doorgestuurd',
      IMPORT_DATA_1: 'Tijdens de import is er een nieuw object gemaakt',
      IMPORT_DATA_2: 'Tijdens de import is er een nieuwe aanvraag opgericht',
      IMPORT_DATA_3:
        'Een onderzoeker kwam overeen met een reeds bestaande, maar kwam niet uit een eerdere import',
      IMPORT_DATA_4: 'Een nieuwe gebruiker is gemaakt tijdens de import',
      IMPORT_DATA_5:
        'Een gebruiker kwam overeen met een reeds bestaande, maar kwam niet uit een eerdere import',
      IMPORT_DATA_6:
        'Er is een fout opgetreden tijdens het importeren van gegevens uit een externe bron',
      INQUIRY_APPROVAL_1: 'Een aanvraag wacht op uw goedkeuring',
      INQUIRY_APPROVAL_4:
        'Uw voorgestelde antwoord is goedgekeurd / goedgekeurd met wijzigingen / afgewezen',
      INTERNAL_1: 'Er is een notitie toegevoegd aan mijn toegewezen aanvraag',
      METADATA_MANAGEMENT_1: 'Er is een nieuwe metadata gemaakt',
      NEW_DOCUMENT_1: 'Nieuw goedgekeurd document is beschikbaar in mijn land',
      NEW_DOCUMENT_2: 'Nieuw document is beschikbaar voor lokale goedkeuring',
      NEW_DOCUMENT_3:
        'Nieuw lokaal document is beschikbaar voor Europese / wereldwijde goedkeuring',
      NEW_DOCUMENT_4: 'Nieuw wereldwijde document is beschikbaar',
      NEW_DOCUMENT_5: 'Nieuw Europees document is beschikbaar',
      NEW_DOCUMENT_6: 'Nieuw Europees document is beschikbaar voor wereldwijde goedkeuring',
      NEW_INQUIRY_1: 'Er is nieuw onderzoek ontvangen in mijn land',
      SCHEDULER_TASK_1: 'Er is een fout gebeurd tijdens de uitvoering van een taak',
      SPAM_1: 'Nieuw onderzoek ontvangen in spam',
      USER_REPORTS_1: 'Lijst met actieve gebruikers',
      TEXT_GUIDES: {
        ANSWER_2:
          'Ontvang meldingen voor vragen die werden beantwoord dat ik als medewerker werkte, maar niet direct aan het onderzoek ben toegewezen',
        AUTHORIZATION_REQUEST_3:
          'Ontvang meldingen die u informeren wanneer een gebruikersrolverzoek moet worden goedgekeurd/afgewezen',
        FOLLOW_UP_4:
          'Ontvang meldingen voor vragen waaraan ik ben toegewezen die een vervolgreactie hebben ontvangen van de onderzoeker',
        NEW_INQUIRY_1:
          'Ontvang meldingen voor nieuwe vragen die in uw land zijn ontvangen, ingevoegd door een onderzoeker/gebruiker of doorgestuurd van een ander team',
        AUTHORIZATION_REQUEST_2:
          'Ontvang meldingen wanneer een nieuwe gebruiker is gemaakt of wanneer een rol is gevraagd door een gebruiker in uw team',
        AUTHORIZATION_REQUEST_4:
          'Ontvang meldingen wanneer een nieuw onderwerp en/of categorie werd voorgesteld',
      },
      ASSIGNMENT_5: 'Een onderzoek werd toegewezen aan mijn plaatsvervanger',
      FREQUENCIES: {
        INSTANTLY: 'Direct',
        DAILY: 'Dagelijks',
        WEEKLY: 'Wekelijks',
        QUARTERLY: 'Per kwartaal',
        MONTHLY: 'Maandelijks',
        SECOND_DAY: 'Om de andere dag',
        THIRD_DAY: 'Elke derde dag',
        ONE_WEEK: 'Een week',
        ONE_MONTH: 'Een maand',
        TWO_MONTHS: 'Twee maanden',
        THREE_MONTHS: 'Drie maanden',
        SIX_MONTHS: 'Zes maanden',
        NINE_MONTHS: 'Negen maanden',
        EVERY_TWO_WEEKS: 'Elke twee weken',
        EVERY_THREE_WEEKS: 'Om de drie weken',
        EVERY_FOUR_WEEKS: 'Om de vier weken',
      },
      OPTIONS: {
        ALMOST_DUE: 'Bijna verschuldigd',
        DUE_NOW: 'Nu verschuldigd',
        DUE_SOON: 'Binnenkort verwacht',
        EARLY_ON: 'Vroegtijdig',
        TEXT_GUIDES: {
          EARLY_ON:
            'Ontvang meldingen in het begin voor vragen die verschuldigd zijn: normale 4 dagen - hoge 2 dagen - kritische 24 uur',
          DUE_SOON:
            'Ontvang meldingen voor vragen die binnenkort verschuldigd zijn: normale 48 uur - hoge 12 uur - kritische 6 uur',
          ALMOST_DUE:
            'Ontvang meldingen voor vragen die bijna verschuldigd zijn: normale 24 uur - hoge 6 uur - kritische 3 uur',
          DUE_NOW: 'Ontvang meldingen wanneer het onderzoek verschuldigd is',
        },
      },
      AUTHORIZATION_REQUEST_4: 'Een categorie/ onderwerp werd gevraagd in mijn team',
      AUTHORIZATION_REQUEST_2: 'Gebruikersverzoeken',
    },
  },
  NEW_TEAM: 'Nieuw team',
  SmPc: 'SMPC',
  USERS: 'Gebruikers',
  PAGINATOR_NEXT_PAGE_LABEL: 'Volgende pagina',
  PAGINATOR_PREV_PAGE_LABEL: 'Vorige pagina',
  PAGINATOR_FIRST_PAGE_LABEL: 'Eerste pagina',
  PAGINATOR_LAST_PAGE_LABEL: 'Laatste pagina',
  PAGINATOR_ITEMS_PER_PAGE_LABEL: 'Artikelen per pagina',
  Date: 'Datum',
  Action: 'Actie',
  'Action by user': 'Actie per gebruiker',
  'Changed user': 'Gewijzigde gebruiker',
  'Original value': 'Originele waarde',
  'New value': 'Nieuwe waarde',
  REGION: 'Regio',
  TEAM_REGION_LABEL: 'Regio - Selecteer eerst het bedrijf',
  TEAM_THERAPEUTIC_AREA_LABEL: 'Therapeutische gebieden - Selecteer eerst het bedrijf',
  TEAM_PRODUCT_LABEL: 'Producten - Selecteer eerst therapeutisch gebied',
  TEAM_COUNTRIES_LABEL: 'Landen - Selecteer eerst de regio',
  DEFINE_PRODUCTS_FOR_EACH_COUNTRY: 'Definieer producten voor elk land',
  PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_FOR: 'Selecteer ten minste één product voor',
  VIEW_CHANGES: 'Bekijk wijzigingen',
  'Changed inquirer': 'Veranderde aanvraag',
  Sales_Representative: 'Verkoop vertegenwoordiger',
  Other_Commercial: 'Andere commercial',
  REGIONS: "Regio's",
  MAIN_CLIENT: 'Hoofdklant',
  LICENSE_PARTNER: 'Licentiepartner',
  TEAM_SET_UP: 'Team opgezet',
  LIST_TEAMS: 'Maak een lijst van teams',
  COMPANIES: 'Bedrijven',
  DOMAINS: 'Domeinen',
  TOKEN_REFRESH_NOTICE:
    'U hebt een nieuwe bevestigingsmail gevraagd voor uw EnqMed Account -registratie.',
  TOKEN_REFRESH_SUCCESS: 'Er is een nieuwe bevestigingslink naar uw e -mail verzonden.',
  TOKEN_REFRESH_ERROR:
    'Er is een fout opgetreden. Neem contact op met ondersteuning als u verdere hulp nodig hebt.',
  PLEASE_ENTER_MAX: 'Voer maximaal in',
  COMPANY_ACTION_EDIT_CONFIRM_TITLE:
    'Bekijk de onderstaande wijzigingen, indien van toepassing en bevestig of het bedrijf moet worden gewijzigd.',
  COMPANY_ACTION_EDIT_WARNING_MESSAGE:
    'Ga met voorzichtigheid door, omdat de wijzigingen de teams zullen beïnvloeden',
  THERE_ARE_NO_CHANGES: 'Er zijn geen wijzigingen.',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Controleer de bestaande bedrijven in de tabel achter het dialoogvenster, omdat we al een bedrijf hebben met dezelfde waarden.',
  SIMPLE_TEXT:
    'Voer alleen letters in (A-Z, A-Z), nummers (0-9) en speciale symbolen zoals (dashboard, periode, ruimte en dikke darm).',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_REGIONS: 'Selecteer ten minste één land voor de regio',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_THERAPEUTIC_AREAS:
    'Selecteer ten minste één product voor therapeutisch gebied',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_PRODUCTS:
    'U hebt geselecteerde product/s sense die tot meer dan één therapeutisch gebied behoort, selecteer alle benodigde therapeutische gebieden.',
  HTML_CHANGES: 'HTML -wijzigingen',
  SIDE_BY_SIDE_VIEW: 'Naast elkaar zicht',
  DOMAIN_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Controleer de bestaande domeinen in de tabel achter het dialoogvenster, omdat we al met dezelfde waarden hebben domein.',
  OUT_CHANNEL_ERROR_MSG: "Uitgaande kanaalbrief Enquirer's postadres",
  OUT_CHANNEL_ERROR_MSG_LONG:
    'Uitgaande kanaal geselecteerd als brief. Voer het postadres van Enquirer in het enquirer -gebied in',
  LABEL_FOR: 'Labelen voor',
  male: 'Mannelijk',
  female: 'Vrouw',
  ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE:
    'U hebt geen rechten om deze rol te archiveren. Alleen <b> MIMS </b> voor het team kan rollen archiveren voor hun teamleden.',
  SEND_EMAIL_UPON_APPROVAL_OR_REJECTION:
    'Stuur een e -mail naar de gebruiker op verzoek goedkeuring/afwijzing',
  APPROVE_REQUEST_QUESTION: 'Wilt u het verzoek goedkeuren?',
  NEW_USER_REQUEST: 'Nieuw gebruikersverzoek',
  OPEN_REQUESTS: 'Open verzoeken',
  APPROVED_REQUESTS: 'Goedgekeurde verzoeken',
  REJECTED_REQUESTS: 'Afgewezen verzoeken',
  USER_REQUESTS: 'Gebruikersverzoeken',
  REQUEST_FOR: 'Verzoek tot',
  CATEGORY_TOPIC_REQUESTS: 'Categorie/onderwerpverzoeken',
  WITH_THE_FOLLOWING_PAIRS: 'met de volgende paren:',
  MY_TEAM_OPEN_REQUESTS: 'Open verzoeken',
  MY_TEAM_APPROVED_REQUESTS: 'Goedgekeurde verzoeken',
  MY_TEAM_REJECTED_REQUESTS: 'Afgewezen verzoeken',
  DOCUMENT_APPROVAL_WITHIN_ENQMED: 'Documentgoedkeuring binnen EnqMed',
  DOCUMENTS_APPROVAL: 'Documenten goedkeuring',
  DOCUMENTS_APPROVAL_TITLE:
    'Instellingen voor goedkeuringsproces van documenten voor het geselecteerde team',
  OUT_CHANNEL_FAX_ERROR_MSG_LONG:
    'Uitgaande kanaal geselecteerd als fax. Voer het faxnummer van Enquirer in binnen Enquirer Area in',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG_LONG:
    'Uitgaande kanaal geselecteerd als telefoon. Voer het telefoonnummer van Enquirer in in Enquirer Area',
  OUT_CHANNEL_FAX_ERROR_MSG: "Uitgaande kanaalfax enquirer's waarde",
  OUT_CHANNEL_TELEPHONE_ERROR_MSG: "Uitgaande kanaaltelefoon Enquirer's waarde",
  COPYRIGHT_PART_ONE: 'auteursrechten',
  COPYRIGHT_PART_TWO: 'Madjenta Ltd. Alle rechten voorbehouden.',
  DELETE_INQUIRER_PII: 'Verwijder Inquirer Persoonlijke informatie',
  REVIEWED_DATE: 'Beoordeelde datum',
  OUT_CHANNEL_EMAIL_ERROR_MSG_LONG:
    'Uitgaande kanaal geselecteerd als e -mail. Voer de e -mail van Enquirer in het enquirer -gebied in',
  OUT_CHANNEL_EMAIL_ERROR_MSG: "Uitgaande kanaal e -mail enquirer's waarde",
  ENTER_USER_COMMENT_OPTIONAL: 'Voer commentaar in voor gebruiker (optioneel)',
  SELECT_ANOTHER_CATEGORY: 'Selecteer een andere categorie',
  SELECT_ANOTHER_PRODUCT: 'Selecteer een ander product',
  SUGGESTED_TOPIC: 'Voorgesteld onderwerp',
  EDIT_USER_REQUEST: 'Gebruikersverzoek bewerken',
  NEW_TOPIC_REQUEST: 'Nieuw onderwerp voorgesteld',
  EDIT_TOPIC_REQUEST: 'Bewerk het voorgestelde onderwerp',
  NEW_CATEGORY_REQUEST: 'Nieuwe categorie voorgesteld',
  EDIT_CATEGORY_REQUEST: 'Bewerk voorgestelde categorie',
  REVIEW_COMMENT: 'Bekijk commentaar',
  DOCUMENTS_APPROVAL_WORKFLOW: {
    PAGE_TITLE: 'Workflows voor documentgoedkeuring',
    CREATE_NEW: 'Maak een nieuwe workflow',
    CUSTOMIZE: 'Workflow aanpassen',
    NAME: 'Workflownaam',
    ERROR_MSG_DUPLICATE_NAME: 'Workflow met deze naam bestaat al.',
    SAVE: 'Workflow opslaan',
    DELETE: 'Workflow verwijderen',
    STEP: 'Stap',
    ALL_USERS_WITH_DOC_AUTHOR_ROLE: 'Alle gebruikers met de rol van documentauteur.',
    APPROVAL_DEADLINE: 'Goedkeuring deadline',
    SELECT_APPROVAL_TYPE: 'Selecteer goedkeuringstype',
    SELECT_APPROVAL_TYPE_FIRST: 'Selecteer eerst goedkeuringstype',
    SELECT_REVIEWERS: 'Selecteer Reviewers',
    ACTION_ON_APPROVE_WITH_CHANGES: 'Actie op goedkeuren met wijzigingen',
    ACTION_ON_REJECT: 'Actie op afwijzing',
    FILL_ALL_REQUIRED_FIELDS: 'Vul alle vereiste velden in om een nieuwe stap toe te voegen.',
    ALL_APPROVAL_TYPES_USED:
      'U kunt niet meer goedkeuringssubstappen toevoegen omdat u alle beschikbare typen gebruikt voor de huidige stap.',
    CONFIRMATION_ROW_1:
      'U probeert de workflow voor de goedkeuring van de document voor team te verwijderen {{ NAME }}.',
    CONFIRMATION_ROW_2: 'Wilt u echt de goedkeuringsworkflow verwijderen?',
    CONFIRMATION_TITLE: 'Documentgoedkeuring Workflow -verwijdering',
    TEAM_VIEW_SELECT_EXISTING:
      'Selecteer een bestaande workflow voor documentgoedkeuring uit de onderstaande lijst of ',
    DOC_VIEW_SELECT_EXISTING: 'Selecteer een bestaande workflow voor documentgoedkeuring',
    ONLY_SINGLE_APPROVAL_STEP_CAN_BE_LISTED_AS_FINAL:
      'Alleen een enkele goedkeuringsstap kan als definitief worden vermeld.',
    APPROVAL_TYPE_TOOLTIP:
      'Er zijn geen gebruikers met documentgoedkeuring voor dit team. Voeg eerst goedkeuringsrollen toe.',
    INFO_DIALOG_TITLE: 'Er is geen workflow van documentgoedkeuring voor dit team.',
    INFO_DIALOG_MSG:
      'Neem contact op met uw lokale MIM om workflow voor documentgoedkeuring voor het team te maken.',
    STEP_LIMIT_REACHED: 'U hebt de limiet van {{ MAX_STEPS }} goedkeuringsstappen bereikt.',
  },
  DOCUMENTS_WITHOUT_APPROVAL: 'Documenttypen die geen goedkeuring vereisen',
  PLEASE_SELECT_TYPE: 'Selecteer het type',
  ABORT_FILE_UPLOAD: 'Afscheiding van bestandsupload',
  USERS_REPORT: 'Gebruikers melden',
  DOCUMENT_CREATE_CONFIRM_CLOSE_MSG:
    'Door dit venster te sluiten, verlies u de al ingevulde informatie.',
  DO_YOU_WANT_TO_PROCEED: 'Wil je doorgaan?',
  USERS_REPORT_DESCRIPTION:
    'Lijst van gebruikers van alle extra teams waar MIM voor Daiichi Sankyo Europa Oncology en Cardiovascular een MIM -rol spelen.',
  TEMPLATE_CONFIRM_MSG: 'Zodra de sjabloon is verwijderd, kan deze niet worden hersteld!',
  EVENT_CONFIRM_MSG: 'Zodra het evenement is gearchiveerd, kan het niet worden hersteld!',
  CREATE_DRAFT: 'Maak een concept',
  CREATE_USER: 'Gebruiker maken',
  ADD_ROLE: 'Een rol toevoegen',
  DOMAIN_ADDRESS_INVALID_FOR: 'Domeinadres ongeldig voor',
  AVAILABLE_ADDRESSES: 'Beschikbare adressen',
  IN_TEAM: 'in Team',
  TO_THE_SELECTED_USER: 'aan de geselecteerde gebruiker',
  USER_INFORMATION: 'Gebruikers informatie',
  LOGIN_VERIFY_EMAIL_ADDRESS:
    'Het e -mailadres dat u gebruikt, moet worden bijgewerkt, aangezien het domeinadres niet overeenkomt met het team/s waar uw profiel mee is gekoppeld. <br> Raadpleeg uw systeembeheerder voor hulp of neem contact op met het ondersteuningsteam.',
  MESSAGE: 'Bericht',
  IN_APPROVAL: 'In goedkeuring',
  IN_REVIEW: 'In beoordeling',
  DOCUMENT_APPROVAL: 'Documentgoedkeuring',
  DOCUMENT_TITLE: 'Document titel',
  SEARCH_DOCUMENTS: 'Zoekdocumenten',
  APPROVAL_INBOX: 'Goedkeuring inbox',
  FILTER_DOCUMENTS_BY: 'Filter documenten door',
  submit_to_approval: 'Onderwerpen aan goedkeuring',
  create_document_metadata: 'Maak document metagegevens',
  archive: 'Archief',
  I_ACKNOWLEDGE_THAT_IT_IS_:
    "Ik erken dat het mijn verplichting is om alle nadelige geneesmiddelenreacties (ADR's) van alle medische producten te melden volgens de lokale vereisten. Enqmed is niet bedoeld om rapporten vast te leggen over bijwerkingen - ADR's moeten worden gemeld aan farmacovigilantie direct volgens de lokale vereisten",
  VERSION_DIALOG: {
    TITLE: 'Nieuwe versie beschikbaar',
    MESSAGE:
      'Er is een nieuwe versie van de software beschikbaar!\n U moet de nieuwe versie aanschaffen, om te profiteren van verbeteringen daarin en problemen met het gebruik van de oude versie te voorkomen, moet u deze aanschaffen.',
    SAVE_BUTTON: 'Download de nieuwe versie',
  },
  SELECT_PREFERRED_CONTACT_ERROR: 'U moet een voorkeurscontact voor de aanvrager selecteren.',
  ACTIVE_INACTIVE: 'Actief inactief',
  CATEGORY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Controleer de bestaande categorieën in de tabel achter het dialoogvenster, aangezien we al een categorie hebben met dezelfde waarden.',
  ERROR_EMAIL_COULD_NOT_BE_DELIVERED: 'Fout! Mislukte levering',
  VIA: 'via',
  INSERTED_MANUALLY: 'Handmatig ingevoerd',
  AS_A: 'als een',
  FOLLOW_UP_INITIATED_BY_INQUIRER: 'Follow-up geïnitieerd door vragensteller',
  PRODUCT_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Controleer de bestaande producten in de tabel achter het dialoogvenster, aangezien we al producten met dezelfde waarden hebben.',
  NOT_PRODUCT_SPECIFIC: 'Niet productspecifiek',
  TOPIC_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Controleer de bestaande onderwerpen in de tabel achter het dialoogvenster, aangezien we al onderwerpen hebben met dezelfde waarden.',
  PLEASE_ENTER_MAX_CHARACTERS: 'Voer maximaal {{ MAX_CHARS }} tekens in.',
  THANK_YOU_MESSAGE: 'Bedankt bericht',
  AS: 'als',
  THERAPEUTIC_AREA_PARENT: 'Oudergebied',
  MAIN_THERAPEUTIC_AREA: 'Hoofdtherapeutisch gebied',
  CHOOSE_AN_IMAGE: 'Kies een afbeelding',
  ALLOWED_FORMATS: 'Alleen {{ FORMATS }} formaten toegestaan',
  REMOVE_IMAGE: 'Afbeelding verwijderen',
  THERAPEUTIC_AREA_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Controleer de bestaande therapeutische gebieden in de tabel achter het dialoogvenster, aangezien we al gebieden met dezelfde waarden hebben.',
  PLAIN_TEXT_VIEW: 'Platte tekstweergave',
  RICH_TEXT_VIEW: 'Rich Text-weergave',
  EDIT_ITEM: 'Bewerk {{ ITEM }}',
  CREATE_NEW_ITEM: 'Maak een nieuwe {{ ITEM }}',
  ADD_NEW_ITEM: 'Voeg nieuwe {{ ITEM }} toe',
  ADD_ITEM: 'Voeg {{ ITEM }} toe',
  AND_HENCE_STATUS_CHANGED_FROM: 'en vandaar veranderde de status van',
  FOLLOW_UP_WITH_ENQUIRER: 'Opvolging met de vraagsteller',
  _MUST_BE_INSERTED_TO_SAVE_REVIEW:
    'Om de wijzigingen op te slaan, moet het volgende worden ingevoegd:',
  FOLLOW_UP_IS_NOT_POSSIBLE_FOR_CLOSED_INTERACTION:
    'Bij een gesloten interactie is geen vervolg mogelijk.',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_REVIEW:
    'Interactie in beoordelingsstatus. Bewaar de beoordeling om dit menu in te schakelen.',
  METADATA_ACTION_EDIT_CONFIRM_TITLE:
    'Door de geselecteerde {{METADATA_TYPE}} te deactiveren, zal deze actie de deactivering van de volgende gerelateerde metadata-items veroorzaken:',
  QUESTION_TEXT_MIN_SIX_CHARS: 'Vraagtekst (minimaal 6 tekens)',
  PLEASE_PROVIDE_AN_ANSWER_: 'Geef een antwoord (minimaal 6 tekens of een bijlage).',
  USER_MUST_RECOVER_PASSWORD_MESSAGE:
    "Houd er rekening mee dat de gebruiker het wachtwoord moet herstellen via 'Wachtwoord vergeten'.",
  ENABLE_USER_LOGIN_MESSAGE: 'Hierdoor wordt het inloggen van de gebruiker mogelijk gemaakt.',
  DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE:
    'Hierdoor worden gebruikersaanmeldingen en meldingen uitgeschakeld.',
  SET_NOTIFICATIONS_PREFERENCES_MESSAGE:
    'Stel uw meldingsvoorkeuren in vanuit uw gebruikersprofiel!',
  SET_PASSWORD: 'Stel uw wachtwoord in',
  ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION:
    'Na reactivering van uw account is uw wachtwoord verwijderd.',
  SPECIALIZATION_DEACTIVATION_CONFIRMATION: 'Bevestig de deactivering van de specialisatie!',
  REMOVE_DEACTIVATED_SPECIALIZATION_FROM_ACTIVE_LIST_MESSAGE:
    'Hierdoor wordt het verwijderd uit de lijst met actieve specialisaties.',
  GO_TO_ROLES: 'Ga naar Rollen',
  CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE:
    'Uw wijzigingen in de contactgegevens van de gebruiker hebben geleid tot een wijziging van het e-mailadres voor inloggen van {{ OLD_USERNAME }} in {{ NEW_USERNAME }}',
  ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE: 'Wijs rollen en meldingen toe.',
  DO_YOU_WANT_TO_CONTINUE: 'Wil je doorgaan?',
  COPY_ANSWER_TO_NEW_INTERACTION: 'Kopieer het antwoord naar de nieuwe interactie',
  DELETE_INQUIRY_MESSAGE:
    'U staat op het punt deze aanvraag te verwijderen. Deze actie is niet omkeerbaar.',
  NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE:
    'Er zijn nieuwe aanvragen geïmporteerd. Zorg ervoor dat u alle vragen karakteriseert en beantwoordt.',
  LEAVE_WITHOUT_SAVING: 'Vertrekken zonder op te slaan',
  KEEP_INQUIRER_DETAILS_OPEN: 'Houd de gegevens van de aanvrager open',
  LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE:
    'Er zijn niet-opgeslagen wijzigingen die verloren gaan als u de gegevens van de aanvrager sluit.',
  PENDING_CHANGES_MESSAGE: 'Er zijn nog wijzigingen in behandeling: {{ CHANGES }}?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION:
    'Weet u zeker dat u de geselecteerde tekst in een nieuwe interactie wilt splitsen?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE:
    'Hierdoor wordt de geselecteerde tekst uit de interactie verwijderd en wordt er een nieuwe tekst van gemaakt.',
  DOWNLOAD_ERROR: 'Download fout!',
  DOWNLOAD_INQUIRIES_ERROR_MESSAGE:
    'U probeert meer dan 1000 onderzoeken te downloaden. Het maximaal ondersteunde aantal is 1000. Verfijn uw selectie.',
  TRYING_TO_CHANGE_QUESTION_CHARACTERIZATION: 'Je probeert de vraagkarakterisering te veranderen!',
  CHANGING_QUESTION_CHARACTERIZATION_NOT_ALLOW_CHANGES_IN_ANSWER_MESSAGE:
    'Houd er rekening mee dat het wijzigen van de vraagkarakterisering geen wijzigingen in het eerder gegeven antwoord mogelijk maakt, noch het verzenden van een nieuw antwoord mogelijk maakt!',
  RE_OPEN_CLOSED_INTERACTION_MESSAGE:
    'U staat op het punt een gesloten interactie opnieuw te openen om een \u200b\u200bnieuw antwoord te verzenden.',
  QUESTION_REQUIRE_ANSWER_MESSAGE:
    'De nieuwe vraag vereist een antwoord om het onderzoek te kunnen sluiten.',
  CONVERT_FOLLOWUP_TO_QUESTION_MESSAGE:
    'Weet u zeker dat u dit vervolg wilt omzetten in een vraag?',
  ARE_YOU_SURE: 'Weet je het zeker?',
  USER_DEACTIVATION_CONFIRMATION_QUESTION: 'Bevestig de deactivering van {{ USER_INFO }}',
  USER_REACTIVATION_CONFIRMATION_QUESTION: 'Bevestig de heractivering van {{ USER_INFO }}',
  USER_REACTIVATED_MESSAGE: 'Gebruiker {{ USER_INFO }} is succesvol opnieuw geactiveerd!',
  DELETE_NOT_POSSIBLE: 'Verwijderen niet mogelijk!',
  SEARCH_NO_LONGER_EXIST:
    'Deze zoekopdracht bestaat niet meer omdat deze door de afzender is verwijderd.',
  LOGIN_CREDENTIALS_CHANGED: 'Inloggegevens gewijzigd!',
  EDIT_TEXT: 'Tekst bewerken',
  FOLLOW_UP_ATTEMPTS: 'Vervolgpogingen',
  FIRST_FOLLOW_UP_INFO_TEXT: 'Eerste follow-up verzonden binnen individuele interactie',
  SECOND_AND_THIRD_FOLLOW_UP_INFO_TEXT:
    'Als een van de onderstaande zaken na het eerste verzoek wordt gedeactiveerd, gaat het proces over naar de volgende stap',
  SECOND_ATTEMPT: 'Tweede poging (1e herinnering)',
  THIRD_ATTEMPT: 'Derde poging (1e herinnering)',
  FINAL_ATTEMPT: 'Verloren voor follow-up (sluiten)',
  REMINDER_FREQUENCY: 'Herinneringsfrequentie',
  AUTO_TRANSLATE_FOLLOW_UP_TEMPLATE:
    'Pas automatische vertaling toe in de taal die wordt gebruikt om contact op te nemen met de aanvrager',
  ON_OFF_SWITCH: 'Aan uit',
  DAY: 'Dag',
  DAYS: 'Dagen',
  MONTH: 'Maand',
  MONTHS: 'Maanden',
  RESET_TEMPLATES_TO_DEFAULTS: 'Sjablonen terugzetten naar de standaardwaarden',
  SAVE_FREQUENCY: 'Frequentie opslaan',
  SELECT_FREQUENCY: 'Selecteer frequentie',
  TEMPLATE_MODIFICATIONS_HINT:
    'De tekst is handmatig aangepast en wordt niet meer automatisch gewijzigd. Klik hier om de wijzigingen te annuleren en automatische updates opnieuw in te schakelen.',
  LOGIN_INCORRECT_EMAIL_ADDRESS: 'Incorrect E-mailadres!',
  AUDIT_TRAIL_FOR: 'Audittrail voor {{ ITEM }}',
  AUDIT_TRAIL_FOR_ALL: 'Audittrail voor alle {{ ITEM }}',
  HISTORY_CHANGES_FOR: 'Geschiedenis verandert voor',
  HOLIDAY_GDPR_DISCLAIMER: 'AVG-disclaimer voor feestdagen',
  MANAGE_HOLIDAY_GDPR_DISCLAIMER: 'Beheer vakantie-AVG-disclaimer',
  MISSING_HOLIDAY_GDPR_TEMPLATE_FOR_TEAMS:
    'Ontbrekende AVG-sjabloon voor de feestdagen voor de volgende teams',
  BULK_CREATE: 'Bulk-creatie',
  LAST_SUCCESS: 'Laatste succes',
  LAST_FAILURE: 'Laatste mislukking',
  FAILURE: 'Mislukking',
  TASK: 'Taak',
  Weeks: 'Weken',
  REPEAT_EVERY: 'Herhaal op elke',
  DEACTIVATE_ITEM: 'Deactiveer {{ ITEM }}',
  RESET_FAILURE_COUNT: 'Aantal fouten opnieuw instellen',
  TASK_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Controleer de bestaande taken in de tabel achter het dialoogvenster, aangezien we al taken met dezelfde waarden hebben.',
  ACTIVATE_ITEM: 'Activeer {{ ITEM }}',
  PLEASE_ENTER_MIN_NUMBER: 'Voer een getal in dat groter is dan {{ MIN_NUMBER }}.',
  MERGE_AS_NEW_INQUIRY: {
    DESCRIPTION_FOR_CLOSED_INTERACTION:
      'De geselecteerde doelinteractie voor samenvoeging is al gesloten. Dit onderzoek is een nieuwe interactie en hoeft niet te worden samengevoegd.',
    DESCRIPTION_FOR_OPEN_INTERACTION:
      'Er worden nieuwe vragen toegevoegd aan de bestaande aanvraag, alle vragen moeten worden beantwoord. Selecteer vragen om samen te voegen.',
    VALIDATION_MSG:
      'Selecteer ten minste één vraag uit de samengevoegde interactie om door te gaan met het samenvoegen.',
  },
  MERGED_INTERACTION: 'Samengevoegde interactie',
  TARGET_INTERACTION: 'Doelinteractie',
  MERGE_SUGGESTION: 'Suggestie samenvoegen',
  PLEASE_REVIEW_MERGE_SUGGESTION_BELOW: 'Bekijk de onderstaande samenvoegsuggestie',
  INQUIRERS_COMPARISON_TITLE:
    'Houd er rekening mee dat er verschillende aanvragers zijn voor de samengevoegde interactie en de doelinteractie',
  INQUIRERS_COMPARISON_QUESTION: 'Bevestig of u ondanks dit verschil door wilt gaan.',
  NO_INQUIRER: 'Geen onderzoeker',
  TERRITORY: 'Gebied',
  DOMAIN: 'Domein',
  IN: 'in',
  MARKED_INQUIRER_PII_DELETE:
    'gemarkeerde persoonlijke gegevens van de aanvrager voor verwijdering',
  UNMARKED_INQUIRER_PII_DELETE:
    'ongemarkeerde persoonlijke gegevens van de aanvrager voor verwijdering',
  TEAM_GROUP_FILTER: {
    TITLE: 'Definieer machtigingen om extra teams te bekijken',
    SAVE_BTN_TOOLTIP: 'Vul alle verplichte velden voor team in en sla team eerst op.',
  },
  ADR_REF_ID: 'ADR-referentie-ID',
  PQC_REF_ID: 'PQC-referentie-ID',
  ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS: 'Deze bijlage is aanwezig in meerdere interacties',
  SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS:
    'Selecteer het selectievakje als u de bijlage uit alle interacties wilt verwijderen.',
  CONFIRM_DELETE_ATTACHMENT: 'Bevestig of u deze bijlage wilt verwijderen.',
  DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE: 'Verwijder uit alle hierboven genoemde interacties',
  ATTACHMENT_DELETED: 'Bijlage verwijderd',
  ROLES: 'Rollen',
  SET_TO_ALL_INQ_TOOLTIP:
    'Stel voor alle vragen dezelfde ADR-, PQC- en off-label-karakterisering in.',
  TASKS_LIST: 'Takenlijst',
  TASK_LOGS: 'Taaklogboeken',
  SELECTED: 'Geselecteerd',
  LICENSE_COUNTER: 'Licentie teller',
  LICENSES_LIMIT: 'Licentienummerlimiet',
  USERS_TO_EXCLUDE: 'Gebruikers die moeten worden uitgesloten',
  ENTER_MAX_NUMBER_OF_LICENSES: 'Gecontracteerde licentiekosten (gebruikersrollen).',
  SELECT_USER_ROLES_TO_COUNT:
    'Selecteer gebruikersrollen die van toepassing zijn op het tellen van licentiekosten.',
  SELECT_USERS_TO_EXCLUDE_FROM_COUNT: 'Sluit gebruikers uit van de licentiekosten.',
  RECEIPT_AND_SALES_NOTIFICATIONS: 'Ontvangst- en verkoopmeldingen',
  REFRESH_MIGRATION_ERRORS: 'Vernieuw de lijst met migratiefouten',
  MARKED_AS_RESOLVED_BY: 'Gemarkeerd als opgelost door',
  MARKED_AS_RESOLVED_ON: 'Gemarkeerd als opgelost op',
  MARK_AS_RESOLVED: 'Markeer als opgelost',
  ERROR_ON: 'Fout ingeschakeld',
  TEXT_WITH_SPECIAL_CHARS:
    'Voer alleen letters (a-z, A-Z), cijfers (0-9) en speciale symbolen in, zoals streepje (-), punt (.), spatie, dubbele punt (:), komma (,), haakjes (()), vooruit schuine streep (/) en plus (+).',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_APPROVAL:
    'Interactie in de status ingediend voor goedkeuring. Gelieve goed te keuren/weigeren om dit menu in te schakelen.',
  REFRESH_TASK_LOGS: 'Vernieuw de lijst met takenlogboeken',
  LICENSE_COUNTER_ACTION_EDIT_CONFIRM_TITLE:
    'Bevestig a.u.b. als u door wilt gaan met de wijzigingen!',
  DEPUTY_ROLE_ARCHIVE_NOT_ALLOWED:
    'Handmatige archivering is niet toegestaan voor vervangende rollen.',
  RUN_COUNTER: 'Teller uitvoeren',
  EXPORT_LICENSES: 'Exportvergunningen',
  LICENSE_COUNT_MESSAGE: 'Het aantal gebruikte licenties is:',
  QUESTIONS: 'Vragen',
  CLARIFICATION: 'Verduidelijking',
  MERGE_AS_INQUIRY: {
    VALIDATION_MSG:
      'Selecteer ten minste één vraag in de doelinteractie om door te gaan met het samenvoegen.',
    DESCRIPTION:
      'De verduidelijkingsvraag wordt toegevoegd onder de geselecteerde vraag(en) in de doelinteractie.',
  },
  MERGE_AS_DUPLICATE: {
    DESCRIPTION:
      'Selecteer deze optie om de interactie te markeren als een duplicaat van de bestaande interactie.',
  },
  DETECTED_CLARIFICATION_QUESTION_WITHOUT_ANSWER:
    'Vorige samenvoeging omdat verduidelijkingsvraag is gedetecteerd. Alle geselecteerde antwoordvelden, naast de eerder geselecteerde antwoordvelden in de doelinteractie, worden opnieuw geopend voor antwoord.',
  DISABLED_ANSWER_FIELD_TOOLTIP:
    'Interactie heropend vanwege antwoord op een andere vraag. Het antwoord op deze vraag werd al eerder gegeven.',
  TIP: 'Tip',
  MERGE_TIP_TEXT:
    'Als u in plaats daarvan interactie {{ MERGED_INTO_ID }} opnieuw wilt openen, drukt u op Nee en start u een nieuw samenvoegproces met de doelinteractie {{ MERGED_INTO_ID }}.',
  YOU_ARE_SELECTING_MERGE_WITHOUT_REOPEN:
    'U selecteert een samenvoegbewerking zonder de doelinteractie te openen.',
  YOU_ARE_SELECTING_MERGE_INTO_INVALID_INTERACTION:
    'U selecteert een samenvoegbewerking voor een ongeldige doelinteractie {{ TARGET_INTERACTION_ID }}.<br>Interactie {{ TARGET_INTERACTION_ID }} is gesloten met status {{ STATUS }}{{ LAST_MERGE_INFO }}.',
  CLOSED_INTERACTION_AND_INABILITY_TO_PROVIDE_ANSWER:
    'Dit zal resulteren in een gesloten interactie en het onvermogen om een antwoord te geven.',
  INTO_INTERACTION: 'in interactie',
  PLEASE_SELECT_INTERACTION_FOR_MERGE: 'Selecteer interactie voor samenvoegen.',
  PLEASE_SELECT_MERGE_REASON: 'Selecteer de reden voor het samenvoegen.',
  QUESTION_CANNOT_BE_DELETED:
    'Deze vraag kan niet worden verwijderd omdat er een bijbehorende onbeantwoorde verduidelijkingsvraag is.',
  MERGE_REASON: 'Reden samenvoegen',
  WITH_REOPEN: 'met heropening',
  MERGED_INTO_QUESTION_IDS: "samengevoegd in vraag-ID('s)",
  MERGED_INTO_QUESTIONS: 'samengevoegd in vraag(en)',
  MERGED_PREVIOUSLY_FROM: 'eerder samengevoegd uit',
  FROM_INTERACTION_ID: 'van interactie-ID',
  IN_INTERACTION_ID: 'in interactie-ID',
  YOU_ARE_ABOUT_TO_CANCEL_MERGE_SUGGESTION:
    'U staat op het punt de samenvoegsuggestie te annuleren.',
  DO_NOT_SHOW_MERGE_SUGGESTION_AGAIN: 'Samenvoegsuggestie niet meer weergeven?',
  PRODUCT_RESOLVED_VIA_AI: 'Product (opgelost via AI)',
  REQUIRED_FIELDS_HINT: 'Vul alstublieft alle verplichte velden in',
  AT_LEAST_ONE_CONTACT_IS_REQUIRED: 'Er is minimaal één contactpersoon vereist!',
  DISCLAIMER_INFO: 'Disclaimer-informatie',
  QUESTION_ID: 'Vraag-ID',
  QUESTION_IDS: "Vraag-ID's",
  SHOW_TEAMS: 'Teams weergeven',
  LAST_POLLING: 'Laatste opiniepeiling',
  HOST: 'Gastheer',
  PROTOCOL: 'Protocol',
  FROM_EMAIL: 'Van email',
  REASON_FOR_REMOVAL: 'Reden voor verwijdering',
  A_CLOSED_INTERACTION_IS_NOT_EDITABLE: 'Een gesloten interactie kan niet worden bewerkt.',
  METADATA_DROPDOWNS_TOOLTIP_TEXT:
    'Interactie heeft meer dan één vraag. Bewerken alleen mogelijk vanuit open interactie.',
  BULK_EDIT: 'Bulkbewerking',
  HOW_TO_ENABLE_BULK_EDIT:
    'Er moeten ten minste twee sjablonen in dezelfde taal worden geselecteerd om een bulkupdate uit te voeren.',
  REASON_FOR_UPDATE: 'Reden voor update',
  FOLLOW_UP_RESEND: 'Vervolg opnieuw verzonden',
  SENT_FOLLOW_UP_REMINDER: 'vervolgherinnering verzonden',
  CLOSED_INTERACTION_AND_SENT_LOST_TO_FOLLOW_UP_NOTIFICATION:
    'gesloten interactie en verloren verzonden naar vervolgmelding',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS: 'Voer een tekst in met een minimale lengte van 15 tekens.',
  FOLLOW_UP_DISABLED_INSERT_ENQUIRER_FIRST: 'Follow-up uitgeschakeld! Voer eerst de aanvrager in.',
  FOLLOW_UP_REQUEST_DISABLED_INSERT_ENQUIRER_EMAIL_FIRST:
    'Vervolgverzoek uitgeschakeld! Voer eerst het geldige e-mailadres van de aanvrager in.',
  FOLLOW_UP_FREQUENCY_VALIDATION_MSG:
    'Het getal voor het geselecteerde frequentietype moet tussen 1 en {{ MAX_ALLOWED_FREQUENCY }} liggen.',
  FOLLOW_UP_PLEASE_SELECT_FREQUENCY_TYPE: 'Selecteer het frequentietype.',
  FOLLOW_UP_FREQUENCY_TOOLTIP:
    'De telling begint vanaf de datum en het tijdstip van het eerste vervolgverzoek. Voor het omrekenen van dagen naar weken vermenigvuldigt u met 7, b.v. 2 weken = 14 dagen, voor dagen tot maanden vermenigvuldigen met 30, b.v. 1 maand = 30 dagen.',
  VALID_PHONE_FAX: 'Voer een geldig telefoon-/faxnummer in!',
  SELECT_LANG_TO_VIEW_AND_MANAGE_TRANSLATED_TEMPLATES:
    'Selecteer een taal om de vertaalde sjablonen te bekijken en te beheren',
  RETRANSLATE: 'Opnieuw vertalen',
  FOLLOW_UP_REMINDERS_INFO_TEXT:
    'De onderstaande sjablonen zijn alleen in het Engels, vertalingen worden hieronder beheerd',
  FOLLOW_UP_RESET_BTN_TOOLTIP:
    'Alle bovenstaande sjablonen worden teruggezet naar de standaardinstellingen (handmatige wijzigingen worden verwijderd, de frequentie wordt teruggezet naar 14 dagen en het selectievakje voor vertaling wordt uitgeschakeld). Ze worden opnieuw vertaald naar alle talen en is_manually_modified wordt gewist.',
  FOLLOW_UP_EDIT_BTN_TOOLTIP:
    'Bewerk de sjabloontekst en vertaal alle vertalingen die niet handmatig zijn gewijzigd opnieuw.',
  FOLLOW_UP_RETRANSLATE_BTN_TOOLTIP:
    'Hervertaal originele sjabloontekst, inclusief handmatig gewijzigde tekst.',
  FOLLOW_UP_REMINDER_TEMPLATES: 'Sjablonen voor vervolgherinneringen',
  MANUALLY_MODIFIED: 'Handmatig gewijzigd',
  SYNC: 'Synchroniseren',
  DIFFERENT: 'Verschillend',
  DEFAULT_CONFIRMATION_QUESTION: 'Bevestig a.u.b. of u door wilt gaan.',
  SEND_WELCOME_MAIL_: 'Verzend een welkomstmail met wachtwoordreset',
  CONFIRM_FU_RESPONSE_WITH_REOPEN:
    'Ik bevestig dat de vrager nieuwe vervolginformatie heeft ontvangen en dat ik de interactie opnieuw wil openen om een antwoord te geven.',
  ONLY_LETTERS_SPACE_AND_DASH: 'Voer alleen letters, spaties en het - symbool in!',
  INFO: 'Info',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG:
    'Wijziging van het land van de aanvrager zorgt ervoor dat de toewijzing van de interactie verandert van team {{ CURRENT_TEAM }} naar team {{ FUTURE_TEAM }}.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_REMOVE_ASSIGNMENT:
    'Wijziging in het land van de aanvrager verandert de toewijzing van de interactie van team {{ CURRENT_TEAM }} naar team {{ FUTURE_TEAM }}. Gebruiker {{ ASSIGNED_TO }} heeft geen machtigingen voor het team {{ FUTURE_TEAM }} en zijn persoonlijke toewijzing wordt verwijderd.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'De wijziging heeft invloed op teamtoewijzingen in de volgende aanvullende interacties, waarbij persoonlijke toewijzingen worden verwijderd: {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_HCP_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'Wijziging van het land van de aanvrager zal de teamtoewijzingen in de volgende aanvullende interacties wijzigen, waarbij persoonlijke toewijzingen worden verwijderd. {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_RELATED_INTERACTIONS_DATA:
    'Interactie-ID: {{ INTERACTION_ID }} van {{ CURRENT_TEAM }} naar {{ FUTURE_TEAM }}, persoonlijke toewijzing van gebruiker {{ ASSIGNED_TO }} verwijderd',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Gebruiker {{ EDITING_USER }} heeft het land van aanvrager {{ ENQUIRER }} gewijzigd in interactie-ID: {{ UPDATED_INTERACTION_ID }}, waardoor de toewijzing van de huidige interactie is gewijzigd van team {{ OLD_TEAM }} naar team {{ NEW_TEAM }}.',
  INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Gebruiker {{ EDITING_USER }} heeft het land van aanvrager {{ ENQUIRER }} gewijzigd in het HCP-beheer, waardoor de toewijzing van de huidige interactie is gewijzigd van team {{ OLD_TEAM }} naar team {{ NEW_TEAM }}.',
  INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Gebruiker {{ INTERACTION_ASSIGN_TO }} heeft geen rechten voor het team {{ NEW_TEAM }} en zijn persoonlijke toewijzing is verwijderd.',
  PERSONAL_ASSIGNMENT: 'Persoonlijke opdracht',
  DUE_TO_FU_REQUEST: 'vanwege vervolgverzoek',
  DUE_TO_FU_RESPONSE: 'vanwege vervolgreactie',
  DUE_TO_FU_REMINDERS_FREQ_UPDATE:
    'vanwege de update van de frequentie van de follow-up herinneringen van de beheerderspagina',
  UPDATED_FROM_INBOX: 'bijgewerkt vanuit inbox',
  ARCHIVED_ROLE: 'Gearchiveerde rol',
  USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA:
    'Als u de gebruikersrol archiveert, worden de toewijzingen in de volgende aanvullende interacties gewijzigd, waarbij persoonlijke toewijzingen worden verwijderd: {{ USER_INTERACTIONS }}',
  USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Gebruiker {{ EDITING_USER }} heeft de gebruiker {{ INTERACTION_ASSIGN_TO }} bewerkt en zijn rol gearchiveerd als {{ USER_ROLE }} in team {{ TEAM }}. Gebruiker {{ INTERACTION_ASSIGN_TO }} heeft geen machtigingen voor team {{ TEAM }} en zijn persoonlijke toewijzing is verwijderd.',
  ASSIGNMENT: 'Opdracht',
  PERIOD_TO_PERIOD_OVERLAP: 'Periode en To Period overlappen elkaar',
  PERIOD_TO_PERIOD_MISMATCH:
    'Periode en To-periode hebben een verschillende duur - {{ PERIOD }} {{ INTERVAL }} en {{ TO_PERIOD }} {{ TO_INTERVAL }}',
  CREATED_QUESTION_FROM_FU_RESPONSE: 'vraag gemaakt op basis van vervolgreactie',
  CREATE_QUESTION_FROM_FU_RESPONSE_TOOLTIP:
    'Met deze optie kunt u een nieuwe vraag maken op basis van een vervolgvraag.',
  ADD_AS_A_NEW_QUESTION: 'Toevoegen als nieuwe vraag',
  ROLE_ARCHIVE_WITH_USER_DEACTIVATION:
    'Houd er rekening mee dat u probeert de laatste goedgekeurde rol voor deze gebruiker te archiveren. Hierdoor wordt de gebruiker gedeactiveerd en heeft hij/zij geen toegang meer tot EnqMed.',
  ATTEMPTED_FOLLOW_UP: 'een vervolgpoging gedaan',
  FAILED_FU_ATTEMPT: 'Mislukte vervolgpoging',
  UNABLE_TO_REACH_ENQUIRER: 'De aanvrager kan niet worden bereikt.',
  FAILED_FU_ATTEMPT_TOOLTIP:
    'Selecteer deze optie als er een poging is gedaan om de aanvrager te bereiken, maar dit niet is gelukt, bijvoorbeeld omdat het niet lukte om de aanvrager te bereiken via de telefoon, of omdat F2F of een andere afspraak mislukt was.\nDe poging wordt vastgelegd in de interactiegeschiedenis.',
};
