import { Component, Input, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { FilterModalConfig } from '../configs/filter-configs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DebounceSubject } from '../../../../../common/util/rx';
import { MessageHandlerService } from '../../../../../common/common/message-handler/message-handler.service';
import { InquiriesService as InquiryService } from '../../../../data-model/inquiry/inquiries.service';
import { environment } from '../../../../../../environments/environment';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './inquiries-filter-modal-save.html',
  styleUrls: ['./inquiries-filter-modal.scss'],
})
export class FilterSaveContent {
  @Input()
  saveValue: string;
  @Input()
  users: any[];
  shareValue: string;
  saveError: boolean;
  @Input() edit: boolean;
  @Input() allSavedSearches: string[];
  filterConfig = FilterModalConfig;
  constructor(public dialogRef: MatDialogRef<FilterSaveContent>) {}

  openShare() {
    if (this.canSaveOrShare()) {
      this.dialogRef.close({ type: FilterModalConfig.SAVEANDSHARE, value: this.saveValue });
    }
  }

  onSave() {
    if (this.canSaveOrShare()) {
      this.dialogRef.close({ type: FilterModalConfig.SAVE, value: this.saveValue });
    } else {
      this.saveError = true;
    }
  }

  canSaveOrShare(): boolean {
    return !(
      !this.saveValue ||
      (this.saveValue && this.saveValue.length < 3) ||
      (this.saveValue && this.allSavedSearches.includes(this.saveValue))
    );
  }
}

@Component({
  templateUrl: './inquiries-filter-modal-share.html',
  styleUrls: ['./inquiries-filter-modal.scss'],
})
export class FilterShareContent {
  @Input()
  users: any[];
  @Input()
  searchId: number;
  EMAIL_REGEX = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  @ViewChild('pop', { static: false }) popover: NgbPopover;

  selectedUsers: any[] = [];
  listUsers: any[] = [];
  shareValue = new DebounceSubject<string>();
  shareError: boolean;
  filterConfig = FilterModalConfig;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  constructor(
    public dialogRef: MatDialogRef<FilterShareContent>,
    private messageService: MessageHandlerService,
    private inquiryService: InquiryService
  ) {
    this.shareValue.subscribe((val) => {
      this.emailChange(val);
    });
  }

  onShare() {
    if (this.selectedUsers.length) {
      this.inquiryService
        .shareSearch(
          `${environment.localBaseUrl}inq/search/${this.searchId}`,
          this.selectedUsers.map((user) => user.email)
        )
        .subscribe({
          next: () => {
            this.messageService.info('SHARE_SUCCESS');
          },
          error: () => {
            this.messageService.error('SHARE_FAILURE');
          },
          complete: () => {
            this.dialogRef.close({ type: FilterModalConfig.SHARE, value: null });
          },
        });
    } else {
      this.shareError = true;
    }
  }

  setCurrentUser(user: any) {
    this.selectedUsers.push(user);
    this.handlePopover(this.popover, false);
    if (this.shareValue.val) {
      this.shareValue.val = '';
    }
  }

  handlePopover(popover: NgbPopover, open: boolean) {
    if (popover.isOpen() && !open) {
      popover.close();
    } else if (!popover.isOpen()) {
      popover.open();
    }
  }

  emailChange(query: string) {
    this.shareError = false;
    const popover = this.popover;
    if (query && query.length >= 1) {
      if (this.users) {
        this.listUsers = this.users
          .map((user) => {
            return { ...user };
          })
          .map((user) => {
            const userEmailFromEmails = user.emails.find(
              (email) => !email.is_deleted && this.EMAIL_REGEX.test(email.val)
            );
            user.email =
              user.email == '' && userEmailFromEmails ? userEmailFromEmails.val : user.email;
            return user;
          })
          .filter((user) => {
            if (user.first_name || user.last_name || user.email) {
              const email = user.email;
              let emailStart = email;
              if (email) {
                const splittedEmail = email.split('@');
                emailStart = splittedEmail[0];
              }
              if (!emailStart) emailStart = '';
              let firstName = user.first_name;
              let lastName = user.last_name;
              if (!firstName) firstName = '';
              if (!lastName) lastName = '';
              let lowerQuery = query.toLowerCase();
              return (
                (firstName.toLowerCase().includes(lowerQuery) ||
                  lastName.toLowerCase().includes(lowerQuery) ||
                  emailStart.toLowerCase().includes(lowerQuery)) &&
                this.EMAIL_REGEX.test(email)
              );
            }
            return false;
          });
        if (this.listUsers.length > 0) {
          this.handlePopover(popover, true);
        } else {
          this.popover.close();
        }
      }
    } else {
      this.listUsers = [];
      this.popover.close();
    }

    if (!this.listUsers.length && query) {
      this.shareError = true;
    }

    return true;
  }

  remove(index: number): void {
    this.selectedUsers.splice(index, 1);
  }
}

@Component({
  templateUrl: './inquiries-filter-modal-delete.html',
  styleUrls: ['./inquiries-filter-modal.scss'],
})
export class FilterDeleteContent {
  inquiryTitle: string;
  filterConfig = FilterModalConfig;
  constructor(public dialogRef: MatDialogRef<FilterDeleteContent>) {}

  onDelete() {
    this.dialogRef.close({ type: FilterModalConfig.DELETE, value: true });
  }
}
