import { gql } from 'apollo-angular';

import { base64ToPK, BaseModel, parseAttr, rProperty } from '../data-model';
import { BUser } from '../user/user';

export class BAttachment extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() file: string;
  @rProperty(Date) createdTs: string;
  @rProperty(Date) editedTs: string;
  @rProperty() checksum: string;
  @rProperty() historyObjectId: string;
  @rProperty() isCustomField: boolean = false;
  isDeleted: boolean = false;

  //relations
  createdBy: BUser;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  static fromRest(json: any): BAttachment {
    return Object.assign(new BAttachment({}), json);
  }

  static fromRestArray(json: any[]): BAttachment[] {
    return json && json.map((v) => BAttachment.fromRest(v));
  }

  init(json: any) {
    this.createdBy = parseAttr<BUser>(json, BUser, 'createdBy');
  }

  get historyObjectPk(): number {
    return base64ToPK(this.historyObjectId);
  }
}

export const fragment = gql`
  fragment attachmentFragment on AttachmentNode {
    id
    name
    file
    createdTs
    editedTs
    checksum
    historyObjectId
    isDeleted
  }
`;

export const fragmentConnection = gql`
  fragment attachmentConnectionFragment on AttachmentNodeConnection {
    edges {
      node {
        ...attachmentFragment
      }
    }
  }
  ${fragment}
`;
