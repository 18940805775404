import { Apollo, gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { parseAttr, pkToBase64 } from '../modules/data-model/data-model';
import { UrlResolverService } from '../common/url-resolver.service';
import { BUser, MTeam, MUser } from '../modules/data-model/user/user';
import * as MEmail from '../modules/data-model/email/email';
import { MProduct } from '../modules/data-model/region/region';
import * as MCountry from '../modules/data-model/country/country';
import { StorageService } from 'app/common/storage.service';
import * as jwsHelper from 'jws';
import { RollbarHandler } from 'app/common/rollbar-handler';
import { Router } from '@angular/router';
import { MessageHandlerService } from '../common/common/message-handler/message-handler.service';

// https://auth0.com/blog/introducing-angular2-jwt-a-library-for-angular2-authentication/

@Injectable({ providedIn: 'root' })
export class AuthService {
  public readonly USER_ACCOUNT_IS_DISABLED = 'User account is disabled.';
  public userAuthenticated = new BehaviorSubject<boolean>(this.hasToken());
  public user = new BehaviorSubject<BUser>(undefined);
  private loginUrl: string;

  constructor(
    private http: HttpClient,
    private urlResolverService: UrlResolverService,
    private apollo: Apollo,
    private storage: StorageService,
    private router: Router,
    private messageService: MessageHandlerService
  ) {
    this.loginUrl = this.urlResolverService.apiUrlForPath(['api-token-auth/login/']);
  }

  initUserAuthenticated(): void {
    this.userAuthenticated.subscribe((isAuth) => {
      if (isAuth) {
        this.fetchLoggedInUser();
      }
    });
  }

  login(username: string, password: string): Observable<boolean> {
    return this.http
      .post(this.loginUrl, JSON.stringify({ username: username, password: password }), {
        withCredentials: true,
      })
      .pipe(
        map((response) => {
          // login successful if there's a jwt token in the response
          if (response) {
            let token = response['token'];
            if (token) {
              // store jwt token in local storage to keep user logged in between page refreshes
              this.storage.setToken(token);
              // cookies are already stored by the browser with Set-Cookie header
              // return true to indicate successful login
              this.userAuthenticated.next(true);
              return true;
            }
          }
          this.triggerUnauthenticated();
          // return false to indicate failed login
          return false;
        })
      );
  }

  getCurrentUserRoles(): string[] {
    const user = this.user.getValue();
    if (!user) {
      return [];
    }
    return Array.from(
      new Set(
        user.affiliations
          .filter((affiliation) => affiliation.isApproved())
          .map((affiliation) => affiliation.role.name)
      )
    );
  }

  logout() {
    // clear token remove user from local storage to log user out
    this.postAuditLog().subscribe();
    this.triggerUnauthenticated();
    this.storage.removeToken();
  }

  logoutAndRedirect(userIsDeactivated: boolean): void {
    this.triggerUnauthenticated();
    this.storage.removeToken();
    this.router.navigate(['/auth/login']).then(() => {
      if (userIsDeactivated) {
        this.messageService.info('Your account has been deactivated!');
      }
    });
  }

  hasToken(): boolean {
    const token = this.storage.getToken();
    return token != null && token != '';
  }

  async tokenIsValid(): Promise<boolean> {
    if (!this.hasToken()) {
      this.triggerUnauthenticated();
      return false;
    }
    const refreshUrl = this.urlResolverService.apiUrlForPath(['api-token-auth/refresh/']);
    const oldToken = this.storage.getToken();
    try {
      const res = <Observable<Object>>(
        await firstValueFrom(this.http.post(refreshUrl, { token: oldToken }))
      );
      const token = res['token'];
      if (res && token) {
        this.storage.setToken(token);
        return true;
      } else {
        this.triggerUnauthenticated();
        return false;
      }
    } catch (e) {
      this.triggerUnauthenticated();
      return false;
    }
  }

  fetchLoggedInUser() {
    let token = this.storage.getToken();
    let decoded = jwsHelper.decode(token);
    let userId = decoded && decoded.payload.user_id;
    if (!userId) {
      console.error('No userId stored: error.');
      //return;
    }
    let query = gql`
      query loggedInUser($id: ID!) {
        user(id: $id) {
          ...userFragment
          canCreateWorkflowTemplate
          emails {
            ...emailConnectionFragment
          }
          defaultListInteractionTeams {
            ...teamConnectionFragment
          }
          defaultListProducts {
            ...productConnectionFragment
          }
          canViewProducts {
            ...productConnectionFragment
          }
          documentManagerTeams {
            edges {
              node {
                ...teamFragment
                countries {
                  ...countryConnectionFragment
                }
              }
            }
          }
          documentTeams {
            ...teamConnectionFragment
          }
          affiliations {
            edges {
              node {
                id
                role {
                  id
                  name
                }
                team {
                  id
                  code
                  name
                  documentApprovalWithinEnqmed
                }
                statusName
              }
            }
          }
        }
      }
      ${MUser.fragment}
      ${MEmail.fragmentConnection}
      ${MTeam.fragment}
      ${MTeam.fragmentConnection}
      ${MProduct.fragmentConnection}
      ${MCountry.fragmentConnection}
    `;
    // avoid cache to retrieve always the update version of the authentication info
    firstValueFrom(
      this.apollo.query({
        query: query,
        variables: { id: pkToBase64('UserNode', userId) },
        fetchPolicy: 'network-only',
      })
    )
      .then((res) => {
        if (res.data) {
          const parsed = parseAttr<BUser>(res.data, BUser, 'user');
          RollbarHandler.setCurrentUserId(parsed.pk());
          this.user.next(parsed);
        } else {
          this.triggerUnauthenticated();
        }
      })
      .catch((error) => {
        this.triggerUnauthenticated();
        console.error(error);
      });
  }

  postAuditLog(): Observable<void> {
    const auditUrl = this.urlResolverService.apiUrlForPath(['api/audit/write-log']);
    const body = {
      action: 'Logout',
      entity: 'user',
      values: { original_value: null, new_value: 'Successful log out' },
    };
    return this.http.post<void>(auditUrl, body);
  }

  private triggerUnauthenticated(): void {
    this.user.next(undefined);
    this.userAuthenticated.next(false);
  }
}
