import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { BEntity, BRelatedContent } from './entity';
import { getEntities, getRelatedContent } from './queries';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'zen-observable';

export class EntitySearchParams {
  terms: string;
  inquirerId: number;
  // For page repository only
  documentId: string;
  documentTitle: string;
  orderBy: string;
  pageSize: number;
  pageNumber: number;
  teamIds: number[];
  countryIds: number[];
  therapeuticAreaIds: number[];
  languageIds: number[];
  targetAudienceIds: number[];
  productIds: number[];
  categoryIds: number[];
  topicIds: number[];
  // real id
  interactionId: number;
  // graphql pk of the interaction
  inquiryPk: string;
  entityType: string;
  entityTypes: string;
  entityStatus: string;
  entityStatuses: string;
  // for inquiry search, consider or not only closed inquiries (default true)
  searchOnlyClosed: boolean = true;
  // exclude interaction from the results (to avoid return the same interaction, like for duplicate or merge)
  excludeInteractionId: number;
  events: string; //id1,id2,id3
  limit: number = 20;
  // advanced filters
  categoryId: number;
  topicId: number;
  productId: number;
  languageId: number;
  therapeuticAreaId: number;
  teamId: number;
  roleId: number;
  roleIds: string; // to pass multiple roles instead of just one
  countryId: number;
  targetAudienceId: number;
  adminMode: boolean = false;
  isActiveUsersSearch: boolean = false;
  isPersonIdentitySearch: boolean = false;
}

@Injectable({ providedIn: 'root' })
export class EntityService {
  values: Subject<BEntity[]>;

  constructor(private apollo: Apollo, private messageService: MessageHandlerService) {
    this.values = new Subject<BEntity[]>();
  }

  search(params: EntitySearchParams): void {
    this.apollo
      .query({
        query: getEntities,
        variables: { params: JSON.stringify(params), 'X-NO-LOADING': true },
      })
      .subscribe(
        (result) => {
          if (result.data) {
            let objs: Object[] = JSON.parse(result.data['entitySearch']);
            let entities = objs.map((e) => new BEntity(e));
            this.values.next(entities);
          }
        },
        (error) => {
          this.messageService.error('(Search)' + error);
        }
      );
  }

  simpleSearch(params: EntitySearchParams): Observable<BEntity[]> {
    return <Observable<BEntity[]>>this.apollo
      .query({
        query: getEntities,
        variables: { params: JSON.stringify(params), 'X-NO-LOADING': true },
      })
      .pipe(
        map((res) => {
          if (res.data) {
            let objs: Object[] = JSON.parse(res.data['entitySearch']);
            let entities = objs.map((e) => new BEntity(e));
            return entities;
          } else {
            return [];
          }
        }),
        catchError((error) => {
          this.messageService.error('(Search)' + error);
          return of([]);
        })
      );
  }
}

@Injectable({
  providedIn: 'root',
})
export class RelatedContentService {
  values: Subject<BRelatedContent[]>;

  constructor(private apollo: Apollo, private messageService: MessageHandlerService) {
    this.values = new Subject<BRelatedContent[]>();
  }

  search(params: EntitySearchParams): Subject<void> {
    const status = new Subject<void>();
    this.apollo
      .query({
        query: getRelatedContent,
        variables: { params: JSON.stringify(params), 'X-NO-LOADING': true },
        fetchPolicy: 'network-only',
      })
      .subscribe({
        next: (result) => {
          status.next();
          if (result.data) {
            const objs = JSON.parse(result.data['relatedContentSearch']);
            this.values.next(objs);
          }
        },
        error: (error) => {
          status.next();
          this.messageService.error('(Search)' + error);
        },
      });
    return status;
  }
}
