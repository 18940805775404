import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export class DebounceSubject<T> {
  constructor(private subject = new Subject<T>(), private lastVal?: T) {}

  set val(val: T) {
    this.next(val);
  }

  get val(): T {
    return this.lastVal;
  }

  subscribe(
    next?: (val: T) => void,
    error?: (error: any) => void,
    complete?: () => void
  ): Subscription {
    return this.subject
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(next, error, complete);
  }

  next(val: T): void {
    this.subject.next((this.lastVal = val));
  }
}
