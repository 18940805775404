import { gql } from 'apollo-angular';

import { MHistory, MInquiry, MInteraction, MInteractionAnswer } from '../interaction/interaction';
import * as MInquirer from '../inquirer/inquirer';
import * as MLanguage from '../language/language';
import { MAlignment, MAnswerTemplate, MTeam, MUser } from '../user/user';
import { MProduct } from '../region/region';
import * as MTopic from '../topic/topic';
import * as MCategory from '../category/category';
import * as MSpecialization from '../specialization/specialization';
import * as MEmail from '../email/email';
import * as MPhone from '../phone/phone';
import * as MAddress from '../address/address';
import * as MCountry from '../country/country';
import * as MAttachment from '../attachment/attachment';
import { MMedicalDocument } from '../medical-document/medical-document';
import * as MMedicalDocumentVersionFile from '../medical-document-version-file/medical-document-version-file';
import * as MEvent from '../event/event';
import * as MGeography from '../geography/geography';

export const singleInteractionQuery = gql`
  query interactionQuery($id: ID!) {
    interaction(id: $id) {
      ...interactionFragment
      suggestions
      initialText
      initialPlainText
      mailSubject
      mailTo
      mailFrom
      statusList
      statusLabel
      title
      event {
        ...eventFragment
      }
      inquirer {
        ...inquirerFragment
        suggestions
        language {
          ...languageFragment
        }
        specialization {
          ...specializationFragment
        }
        defaultCountry {
          ...countryFragment
        }
        emails {
          ...emailConnectionFragment
        }
        phones {
          ...phoneConnectionFragment
        }
        addresses {
          edges {
            node {
              ...addressFragment
              country {
                ...countryFragment
              }
            }
          }
        }
        alignments {
          edges {
            node {
              ...alignmentFragment
              geographies {
                edges {
                  node {
                    ...geographyFragment
                  }
                }
              }
              users {
                edges {
                  node {
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
      insertedBy {
        ...userFragment
      }
      inquirerNotificationSentBy {
        id
        firstName
        lastName
      }
      editedBy {
        ...userFragment
      }
      assignedTo {
        ...userFragment
      }
      language {
        ...languageFragment
      }
      team {
        ...teamFragment
      }
      currentInteractionAnswer {
        ...interactionAnswerFragment
        historyObjects {
          edges {
            node {
              ...historyFragment
              answerOrder
              status
              attachments {
                ...attachmentConnectionFragment
              }
              medicalDocuments {
                edges {
                  node {
                    ...medicalDocumentFragment
                    type {
                      id
                      code
                    }
                    currentVersion {
                      id
                      files {
                        ...medicalDocumentVersionFileConnectionFragment
                      }
                    }
                  }
                }
              }
            }
          }
        }
        answerTemplates {
          edges {
            node {
              ...answerTemplateFragment
              answerOrder
            }
          }
        }
      }
      inquiries {
        edges {
          node {
            ...inquiryFragment
            suggestions
            assignedTo {
              ...userFragment
            }
            question {
              ...historyFragment
              plainText
            }
            answer {
              ...historyFragment
              plainText
            }
            history {
              edges {
                node {
                  ...historyFragment
                  answerOrder
                  plainText
                  attachments {
                    ...attachmentConnectionFragment
                  }
                  medicalDocuments {
                    edges {
                      node {
                        ...medicalDocumentFragment
                        type {
                          id
                          code
                        }
                        currentVersion {
                          id
                          files {
                            ...medicalDocumentVersionFileConnectionFragment
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            category {
              ...categoryFragment
            }
            topic {
              ...topicFragment
            }
            product {
              ...productFragment
            }
          }
        }
      }
      interactionAnswers {
        edges {
          node {
            ...interactionAnswerFragment
            historyObjects {
              edges {
                node {
                  ...historyFragment
                  answerOrder
                  status
                  attachments {
                    ...attachmentConnectionFragment
                  }
                  medicalDocuments {
                    edges {
                      node {
                        ...medicalDocumentFragment
                        type {
                          id
                          code
                        }
                        currentVersion {
                          id
                          files {
                            ...medicalDocumentVersionFileConnectionFragment
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            answerTemplates {
              edges {
                node {
                  ...answerTemplateFragment
                  answerOrder
                }
              }
            }
          }
        }
      }
    }
  }
  ${MInteraction.fragment}
  ${MEvent.fragment}
  ${MLanguage.fragment}
  ${MInquirer.fragment}
  ${MLanguage.fragment}
  ${MSpecialization.fragment}
  ${MEmail.fragmentConnection}
  ${MPhone.fragmentConnection}
  ${MAddress.fragment}
  ${MCountry.fragment}
  ${MUser.fragment}
  ${MAnswerTemplate.fragment}
  ${MInquiry.fragment}
  ${MUser.fragment}
  ${MHistory.fragment}
  ${MAttachment.fragmentConnection}
  ${MMedicalDocument.fragment}
  ${MMedicalDocumentVersionFile.fragmentConnection}
  ${MCategory.fragment}
  ${MTopic.fragment}
  ${MTeam.fragment}
  ${MProduct.fragment}
  ${MInteractionAnswer.fragment}
  ${MAlignment.fragment}
  ${MGeography.fragment}
`;

export const singleNewInteractionQuery = gql`
  query interactionQuery($id: ID!) {
    newInteraction(id: $id) {
      ...newInteractionFragment
      suggestions
      initialText
      initialPlainText
      title
      event {
        ...eventFragment
        defaultValues {
          ...eventDefaultValueFragment
        }
      }
      inquirer {
        ...newInquirerFragment
        suggestions
        language {
          ...languageFragment
        }
        specialization {
          ...specializationFragment
        }
        defaultCountry {
          ...countryFragment
        }
        emails {
          ...newEmailConnectionFragment
        }
        phones {
          ...newPhoneConnectionFragment
        }
        addresses {
          edges {
            node {
              ...newAddressFragment
              country {
                ...countryFragment
              }
            }
          }
        }
      }
      assignedTo {
        ...userFragment
      }
      insertedBy {
        ...userFragment
      }
      language {
        ...languageFragment
      }
      team {
        ...teamFragment
      }
      currentInteractionAnswer {
        ...interactionAnswerFragment
        newHistoryObjects {
          edges {
            node {
              ...newHistoryFragment
              answerOrder
              attachments {
                ...attachmentConnectionFragment
              }
              medicalDocuments {
                edges {
                  node {
                    ...medicalDocumentFragment
                    type {
                      id
                      code
                    }
                    currentVersion {
                      id
                      files {
                        ...medicalDocumentVersionFileConnectionFragment
                      }
                    }
                  }
                }
              }
            }
          }
        }
        answerTemplates {
          edges {
            node {
              ...answerTemplateFragment
              answerOrder
            }
          }
        }
      }
      inquiries {
        edges {
          node {
            ...newInquiryFragment
            suggestions
            question {
              ...newHistoryFragment
              plainText
            }
            history {
              edges {
                node {
                  ...newHistoryFragment
                  attachments {
                    ...attachmentConnectionFragment
                  }
                  medicalDocuments {
                    edges {
                      node {
                        ...medicalDocumentFragment
                        type {
                          id
                          code
                        }
                        currentVersion {
                          id
                          files {
                            ...medicalDocumentVersionFileConnectionFragment
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            category {
              ...categoryFragment
            }
            topic {
              ...topicFragment
            }
            product {
              ...productFragment
            }
          }
        }
      }
    }
  }
  ${MInteraction.fragmentNew}
  ${MEvent.fragment}
  ${MEvent.eventDefaultValueFragment}
  ${MLanguage.fragment}
  ${MInquirer.fragmentNew}
  ${MLanguage.fragment}
  ${MSpecialization.fragment}
  ${MEmail.fragmentNewConnection}
  ${MPhone.fragmentNewConnection}
  ${MAddress.fragmentNew}
  ${MCountry.fragment}
  ${MUser.fragment}
  ${MAnswerTemplate.fragment}
  ${MInquiry.fragmentNew}
  ${MUser.fragment}
  ${MHistory.fragmentNew}
  ${MAttachment.fragmentConnection}
  ${MMedicalDocument.fragment}
  ${MMedicalDocumentVersionFile.fragmentConnection}
  ${MCategory.fragment}
  ${MTopic.fragment}
  ${MTeam.fragment}
  ${MProduct.fragment}
  ${MInteractionAnswer.fragment}
`;

export const updateInteraction = gql`
  mutation updateInteraction($params: UpdateInteractionInput!) {
    updateInteraction(input: $params) {
      interaction {
        ...interactionFragment
        initialText
        initialPlainText
        title
        mailSubject
        mailFrom
        mailTo
        statusList
        event {
          ...eventFragment
        }
        inquirer {
          ...inquirerFragment
          language {
            ...languageFragment
          }
          specialization {
            ...specializationFragment
          }
          defaultCountry {
            ...countryFragment
          }
          emails {
            ...emailConnectionFragment
          }
          phones {
            ...phoneConnectionFragment
          }
          addresses {
            edges {
              node {
                ...addressFragment
                country {
                  ...countryFragment
                }
              }
            }
          }
        }
        insertedBy {
          ...userFragment
        }
        inquirerNotificationSentBy {
          id
          firstName
          lastName
        }
        editedBy {
          ...userFragment
        }
        assignedTo {
          ...userFragment
        }
        language {
          ...languageFragment
        }
        team {
          ...teamFragment
        }
        currentInteractionAnswer {
          ...interactionAnswerFragment
          historyObjects {
            edges {
              node {
                ...historyFragment
                answerOrder
                attachments {
                  ...attachmentConnectionFragment
                }
                medicalDocuments {
                  edges {
                    node {
                      ...medicalDocumentFragment
                      type {
                        id
                        code
                      }
                      currentVersion {
                        id
                        files {
                          ...medicalDocumentVersionFileConnectionFragment
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          answerTemplates {
            edges {
              node {
                ...answerTemplateFragment
                answerOrder
              }
            }
          }
        }
        inquiries {
          edges {
            node {
              ...inquiryFragment
              assignedTo {
                ...userFragment
              }
              question {
                ...historyFragment
                plainText
              }
              answer {
                ...historyFragment
                plainText
              }
              history {
                edges {
                  node {
                    ...historyFragment
                    answerOrder
                    attachments {
                      ...attachmentConnectionFragment
                    }
                    medicalDocuments {
                      edges {
                        node {
                          ...medicalDocumentFragment
                          type {
                            id
                            code
                          }
                          currentVersion {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
              category {
                ...categoryFragment
              }
              topic {
                ...topicFragment
              }
              product {
                ...productFragment
              }
            }
          }
        }
      }

      newInteraction {
        ...newInteractionFragment
        initialText
        initialPlainText
        title
        event {
          ...eventFragment
          defaultValues {
            ...eventDefaultValueFragment
          }
        }
        inquirer {
          ...newInquirerFragment
          language {
            ...languageFragment
          }
          specialization {
            ...specializationFragment
          }
          defaultCountry {
            ...countryFragment
          }
          emails {
            ...newEmailConnectionFragment
          }
          phones {
            ...newPhoneConnectionFragment
          }
          addresses {
            edges {
              node {
                ...newAddressFragment
                country {
                  ...countryFragment
                }
              }
            }
          }
        }
        insertedBy {
          ...userFragment
        }
        language {
          ...languageFragment
        }
        team {
          ...teamFragment
        }
        currentInteractionAnswer {
          ...interactionAnswerFragment
          newHistoryObjects {
            edges {
              node {
                ...newHistoryFragment
                answerOrder
                attachments {
                  ...attachmentConnectionFragment
                }
                medicalDocuments {
                  edges {
                    node {
                      ...medicalDocumentFragment
                      type {
                        id
                        code
                      }
                      currentVersion {
                        id
                        files {
                          ...medicalDocumentVersionFileConnectionFragment
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          answerTemplates {
            edges {
              node {
                ...answerTemplateFragment
                answerOrder
              }
            }
          }
        }
        inquiries {
          edges {
            node {
              ...newInquiryFragment
              question {
                ...newHistoryFragment
                plainText
              }
              history {
                edges {
                  node {
                    ...newHistoryFragment
                    attachments {
                      ...attachmentConnectionFragment
                    }
                    medicalDocuments {
                      edges {
                        node {
                          ...medicalDocumentFragment
                          type {
                            id
                            code
                          }
                          currentVersion {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
              category {
                ...categoryFragment
              }
              topic {
                ...topicFragment
              }
              product {
                ...productFragment
              }
            }
          }
        }
      }
    }
  }
  ${MInteraction.fragment}
  ${MEvent.fragment}
  ${MEvent.eventDefaultValueFragment}
  ${MLanguage.fragment}
  ${MInquirer.fragment}
  ${MLanguage.fragment}
  ${MSpecialization.fragment}
  ${MEmail.fragmentConnection}
  ${MPhone.fragmentConnection}
  ${MAddress.fragment}
  ${MCountry.fragment}
  ${MUser.fragment}
  ${MAnswerTemplate.fragment}
  ${MInquiry.fragment}
  ${MHistory.fragment}
  ${MAttachment.fragmentConnection}
  ${MMedicalDocument.fragment}
  ${MCategory.fragment}
  ${MTopic.fragment}
  ${MTeam.fragment}
  ${MProduct.fragment}
  ${MInteraction.fragmentNew}
  ${MInquirer.fragmentNew}
  ${MEmail.fragmentNewConnection}
  ${MPhone.fragmentNewConnection}
  ${MAddress.fragmentNew}
  ${MInquiry.fragmentNew}
  ${MHistory.fragmentNew}
  ${MInteractionAnswer.fragment}
  ${MMedicalDocumentVersionFile.fragmentConnection}
`;

export const updateInteractionForAnswer = gql`
  mutation updateInteraction($params: UpdateInteractionInput!) {
    updateInteraction(input: $params) {
      interaction {
        ...interactionFragment
      }

      newInteraction {
        ...newInteractionFragment
      }
    }
  }
  ${MInteraction.fragment}
  ${MInteraction.fragmentNew}
`;

export const interactionAnswerPreview = gql`
  query interactionAnswerPreview($id: ID!) {
    interaction(id: $id) {
      id
      answerPreview
    }
  }
`;

export const interactionNewAnswerPreview = gql`
  query interactionNewAnswerPreview($id: ID!) {
    newInteraction(id: $id) {
      id
      answerPreview
    }
  }
`;
